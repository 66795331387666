import { useState } from "react";
import { useQuery, useMutation } from "@apollo/client";
import STOCK_BEATS from "graphql/beat/queries/stockBeats";
import UPDATE_ORDER from "graphql/folder/mutations/updateOrder";
import STOCK_FOLDERS from "graphql/folder/queries/stockFolders";
import { searchBeatsFolders } from "utils/search";
import Beat from "screens/Main/BeatLibrary/Beat";
import Folder from "screens/Main/BeatLibrary/Folder";
import Header from "screens/Main/BeatLibrary/Header";

interface Props {
  closeDeleteBeat: Function;
  closeDeleteFolder: () => void;
  deleteBeatId: number;
  deleteFolderId: number;
  isAdmin: boolean;
  moveBeat: Function;
  openDeleteBeat: Function;
  openDeleteFolder: (id: number) => void;
}

interface beatType {
  folderId: number;
  id: number;
  name: string;
  shared: boolean;
}

interface folderType {
  id: number;
  name: string;
  order: number;
}

const BuiltInBeats = ({
  closeDeleteBeat,
  closeDeleteFolder,
  deleteBeatId,
  deleteFolderId,
  isAdmin,
  moveBeat,
  openDeleteBeat,
  openDeleteFolder,
}: Props) => {
  const { loading: loadingFolders, data: dataFolders } = useQuery(STOCK_FOLDERS, {
    fetchPolicy: "cache-and-network",
  });
  const { loading: loadingBeats, data: dataBeats } = useQuery(STOCK_BEATS);
  const [moveFolderMutation] = useMutation(UPDATE_ORDER);
  const [search, setSearch] = useState("");

  const [shown, setShown] = useState(true);

  const moveFolder = async (dragged: number, droppedOn: number) => {
    await moveFolderMutation({
      variables: {
        dragged,
        droppedOn,
      },
      update: (client, { data: { updateFolderOrder } }) => {
        const { ok, folders } = updateFolderOrder;

        if (!ok) {
          return;
        }

        const newData = {
          stockFolders: folders,
        };

        client.writeQuery({
          query: STOCK_FOLDERS,
          data: newData,
        });
      },
    });
  };

  if (loadingFolders && !dataFolders) {
    return null;
  }

  if (loadingBeats && !dataBeats) {
    return null;
  }

  let foldersToRender = dataFolders.stockFolders;
  let beatsToRender = dataBeats.stockBeats;

  [beatsToRender, foldersToRender] = searchBeatsFolders(search, beatsToRender, foldersToRender);

  return (
    <div>
      <Header
        search={search}
        setSearch={setSearch}
        setShown={setShown}
        showCreateFolder={isAdmin}
        shown={shown}
        stock
        title="Built-in Beats"
      />

      {shown && (
        <div>
          <div>
            {foldersToRender
              && foldersToRender.map((folder: folderType) => (
                <div key={folder.name}>
                  <Folder
                    exercise={false}
                    stock={!isAdmin}
                    isAdmin={isAdmin}
                    beats={
                      beatsToRender
                      && beatsToRender
                        .filter((beat: beatType) => {
                          if (beat.folderId === folder.id) {
                            return true;
                          }
                          return false;
                        })

                        .map((beat: beatType) => (
                          <div key={beat.name}>
                            <Beat
                              beat={beat}
                              stock
                              isAdmin={isAdmin}
                              confirmOpen={deleteBeatId === beat.id}
                              openDelete={openDeleteBeat}
                              closeDelete={closeDeleteBeat}
                              handleDrop={(beatId: number, folderId: number | null) => moveBeat(beatId, folderId, beat)}
                            />
                          </div>
                        ))
                    }
                    folder={folder}
                    folders={foldersToRender}
                    isConfirmOpen={deleteFolderId === folder.id}
                    openDelete={openDeleteFolder}
                    closeDelete={closeDeleteFolder}
                    moveFolder={moveFolder}
                  />
                </div>
              ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default BuiltInBeats;
