import gql from "graphql-tag";

const UPDATE = gql`
  mutation($email: String, $firstName: String, $id: Int!, $lastName: String) {
    update(email: $email, firstName: $firstName, id: $id, lastName: $lastName) {
      ok
      user {
        email
        firstName
        lastName
        id
      }
      errors {
        path
        message
      }
    }
  }
`;

export default UPDATE;
