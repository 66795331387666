import { useState, ChangeEvent } from "react";
import {
  Accordion, Icon, Dropdown, Button, Confirm,
} from "semantic-ui-react";
import { useMutation } from "@apollo/client";
import _ from "lodash";
import { DragSource, ConnectDragSource } from "react-dnd";
import { useAppSelector, useAppDispatch } from "store/hooks";
import UPDATE_NAME from "graphql/folder/mutations/updateName";
import DELETE from "graphql/folder/mutations/delete";
import ALL_FOLDERS from "graphql/folder/queries/allFolders";
import STOCK_FOLDERS from "graphql/folder/queries/stockFolders";
import ALL_BEATS from "graphql/beat/queries/allBeats";
import { toggleFolder } from "store/folder/slice";
import { sendAlert } from "store/alert/slice";
import Target from "screens/Main/BeatLibrary/Target";
import StudentFolderAccess from "screens/Main/BeatLibrary/StudentFolderAccess";
import { folderType } from "types/folder";
import { beatType } from "types/beat";
import styles from "./styles.module.scss";

const folderSource = {
  beginDrag(props: any) {
    return props.folder;
  },
  endDrag(props: any, monitor: any) {
    if (!monitor.didDrop()) return;

    const dropResult = monitor.getDropResult();
    props.moveFolder(props.folder.id, dropResult.payload); // what happens when folder is let go of dragging?
  },
};

function collect(connectDrag: any, monitor: any) {
  return {
    connectDragSource: connectDrag.dragSource(),
    connectDragPreview: connectDrag.dragPreview(),
    isDragging: monitor.isDragging(),
  };
}

interface Props {
  folder: folderType;
  openDelete: (id: number) => void;
  closeDelete: () => void;
  moveFolder: (dragged: number, droppedOn: number) => void
  isConfirmOpen: boolean;
  isAdmin?: boolean;
  beats: beatType[];
  exercise?: boolean;
  stock?: boolean;
  folders: folderType[];
  connectDragSource: ConnectDragSource;
}

const Folder = ({
  folder, openDelete, closeDelete, isConfirmOpen, isAdmin, beats, exercise, stock, folders, connectDragSource,
}: Props) => {
  const dispatch = useAppDispatch();
  const [state, setState] = useState({
    active: false,
    folderName: folder.name,
    renaming: folder.name === "untitled folder",
    updatedName: "",
    dropdownOpen: false,
  });

  const [open, setOpen] = useState(false);
  const foldersOpen = useAppSelector((store) => store.folder);
  const type = useAppSelector((store) => store.user.type);

  const [deleteFolder] = useMutation(DELETE);
  const [renameFolder] = useMutation(UPDATE_NAME);

  const handleNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();

    setState({
      ...state,
      updatedName: e.target.value,
    });
  };

  const updateRenaming = () => {
    setState({
      ...state,
      renaming: !state.renaming,
    });
  };

  const handleUpdateName = async () => {
    updateRenaming();

    if (state.renaming === true && !(state.updatedName === state.folderName)) {
      await renameFolder({
        variables: {
          id: folder.id,
          name: state.updatedName,
        },
      }).then(() => {
        if (state.updatedName !== "") {
          setState({
            ...state,
            folderName: state.updatedName,
            renaming: false,
          });
        }

        dispatch(sendAlert({
          message: "Folder renamed successfully.",
          duration: 10000,
          open: true,
        }));
      });
    }
  };

  const handleClick = () => {
    setState({
      ...state,
      active: !state.active,
    });

    dispatch(toggleFolder(folder.id));
  };

  const handleDelete = async () => {
    await deleteFolder({
      variables: {
        id: folder.id,
      },

      update: (client, { data: { deleteFolder: deleteFolderResponse } }) => {
        const { ok, beats: updatedBeats, beatsIncludeStock } = deleteFolderResponse;

        if (!ok) {
          return;
        }

        const dataFolders = _.cloneDeep<{stockFolders: folderType[], allFolders: folderType[]}>(
          client.readQuery({
            query: isAdmin ? STOCK_FOLDERS : ALL_FOLDERS,
          }) || { stockFolders: [], allFolders: [] },
        );

        const filteredDataFolders = isAdmin
          ? dataFolders.stockFolders.filter((f: folderType) => folder.id !== f.id)
          : dataFolders.allFolders.filter((f: folderType) => folder.id !== f.id);

        client.writeQuery({
          query: isAdmin ? STOCK_FOLDERS : ALL_FOLDERS,
          data: isAdmin ? { stockFolders: filteredDataFolders } : { allFolders: filteredDataFolders },
        });

        client.writeQuery({
          query: ALL_BEATS,
          variables: {
            includeStock: true,
          },
          data: {
            allBeats: beatsIncludeStock,
          },
        });

        client.writeQuery({
          query: ALL_BEATS,
          variables: {
            includeStock: false,
          },
          data: {
            allBeats: updatedBeats,
          },
        });
      },
    });

    closeDelete();
    dispatch(sendAlert({
      message: "Folder deleted successfully.",
      duration: 10000,
      open: true,
    }));
  };

  const beatContents = beats.sort((a, b) => {
    // @ts-ignore
    const beat1 = a.props.children.props.beat.name;
    // @ts-ignore
    const beat2 = b.props.children.props.beat.name;
    if (beat1 < beat2) return -1;
    if (beat1 > beat2) return 1;
    return 0;
  });

  let displayedFolder = state.folderName;

  if (displayedFolder.length > 20) {
    displayedFolder = `${displayedFolder.substring(0, 20)}...`;
  }

  foldersOpen.filter((entry) => entry.folderId === folder.id);

  const folderTitle = (
    <button type="button" className={styles.titleContainer} onClick={handleClick}>
      <Icon color="blue" name="folder" className={styles.titleIcon} />
      <p className={styles.titleText}>{`${folder.name} (${beats.length})`}</p>
    </button>
  );

  const folderPanel = [
    {
      key: folder.id,
      active: state.active,
      title: {
        content: folderTitle,
      },
      content: {
        content: state.active ? <div className={styles.scrollable}>{beatContents}</div> : beatContents,
      },
    },
  ];

  const NestedAccordion = () => (
    <div className={styles.container}>
      <div className="folderDivMask">
        {!stock && (!exercise || type === 1) && (
          <div style={{ position: "relative" }}>
            <Target
              folderId={folder.id}
              folders={folders}
              numberOfBeats={beatContents.length}
              active={!!foldersOpen[0]?.open}
            />

            <div className={styles.dropdown}>
              <Dropdown
                right
                icon={null}
                trigger={(
                  <div className={styles.dropdownTrigger}>
                    <Icon name="angle down" className={styles.dropdownIcon} />
                  </div>
                )}
              >
                <Dropdown.Menu style={{ left: "auto", right: 0, top: 0 }}>
                  <Dropdown.Item
                    icon="pencil"
                    text="Rename"
                    onClick={handleUpdateName}
                    name={folder.name}
                    id={folder.id}
                  />
                  <Dropdown.Item
                    icon="trash"
                    text="Delete Folder"
                    onClick={() => openDelete(folder.id)}
                  />
                  {!!type && <Dropdown.Item icon="group" text="Add Student" onClick={() => setOpen(true)} />}
                </Dropdown.Menu>
              </Dropdown>
            </div>

            <Confirm
              open={isConfirmOpen}
              content={(
                <div style={{ padding: "15px" }}>
                  <p>
                    Are you sure you want to delete this folder and its contents? All enclosed beats will be lost. This
                    action cannot be undone.
                    <br />
                  </p>
                </div>
              )}
              confirmButton="Delete"
              onCancel={closeDelete}
              onConfirm={handleDelete}
              style={{ width: "700px" }}
            />
          </div>
        )}

        {open && (
          <StudentFolderAccess setOpen={setOpen} open={open} folderId={folder.id} name={folder.name} />
        )}
        <Accordion panels={folderPanel} styled />
      </div>
    </div>
  );

  return connectDragSource(
    <div className={styles.source}>
      <NestedAccordion />
      {state.renaming && (
        <>
          <input
            type="text"
            placeholder={state.folderName}
            value={state.updatedName}
            onChange={handleNameChange}
            className={styles.input}
            key={`RenameFolder-${folder.id}`}
            // eslint-disable-next-line
            autoFocus
            onBlur={handleUpdateName}
          />

          <Button className={`ui opaque compact button ${styles.renameButton}`} size="mini" onClick={handleUpdateName}>
            {state.renaming && "confirm"}
            {!state.renaming && "rename"}
          </Button>
        </>
      )}
    </div>,
  );
};

export default DragSource("folder", folderSource, collect)(Folder);

Folder.defaultProps = {
  exercise: false,
  stock: false,
  isAdmin: false,
};
