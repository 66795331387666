export type AllNotes = Instruments | "sticking" | "isolate";
export type Instruments = "hiHat" | "hiTom" | "midTom" | "snare" | "loTom" | "bass"
export type Toms = "hiTom" | "midTom" | "loTom"

export interface ObjectKeys {
  [key: string]: AllNotes;
}

interface NotesIndexToKey extends ObjectKeys {
  readonly 0: "hiHat";
  readonly 1: "hiTom";
  readonly 2: "midTom";
  readonly 3: "snare";
  readonly 4: "loTom";
  readonly 5: "bass";
  readonly 6: "sticking";
  readonly 7: "isolate";
}

const NOTES_INDEX_TO_KEY: NotesIndexToKey = {
  0: "hiHat",
  1: "hiTom",
  2: "midTom",
  3: "snare",
  4: "loTom",
  5: "bass",
  6: "sticking",
  7: "isolate",
};

export default NOTES_INDEX_TO_KEY;
