import { useSpring, animated } from "react-spring";
import { Icon } from "semantic-ui-react";

interface Props {
  message: string;
  duration?: number;
}

const AnimatedConfirmMsgFadeOut = ({ message, duration }: Props) => {
  const springProps = useSpring({
    opacity: 0,
    from: { opacity: 1 },
    config: { duration },
  });

  return (
    <div style={{
      position: "relative", width: "100%", top: "0px", left: "0px",
    }}
    >
      <animated.div style={springProps}>
        <Icon style={{ color: "#2E8B57" }} name="check" />
        {message}
      </animated.div>
    </div>
  );
};

AnimatedConfirmMsgFadeOut.defaultProps = {
  duration: 3000,
};

export default AnimatedConfirmMsgFadeOut;
