import gql from "graphql-tag";

const GET_STRUCTURED = gql`
  query($studentId: Int!) {
    getStudentBeatsAssignments(studentId: $studentId) {
      ok
      student {
        firstName
        lastName
        studentId
        beats {
          id
          name
          folderId
          shared
        }
        assignments {
          id
          content
          beats {
            id
            name
            folderId
            shared
          }
          title
          createdAt
        }
      }
    }
  }
`;

export default GET_STRUCTURED;
