import gql from "graphql-tag";

const GET_USER_FROM_RESET_TOKEN = gql`
  query($token: String!) {
    getUserFromResetToken(token: $token) {
      email
      isAccountCreatedFromAPI
      ok
    }
  }
`;

export default GET_USER_FROM_RESET_TOKEN;
