import gql from "graphql-tag";

const REMOVE = gql`
  mutation($studentId: Int!) {
    removeStudent(studentId: $studentId) {
      ok
      student {
        id
        email
        firstName
        lastName
        signupEmail
        studentId
        userId
        teacherId
      }
    }
  }
`;

export default REMOVE;
