import { Popup, Icon } from "semantic-ui-react";
import { useQuery } from "@apollo/client";
import { useAppSelector } from "store/hooks";
import ALL from "graphql/notification/queries/all";

import Notification from "./Notification";
import styles from "./styles.module.scss";

interface notificationType {
  id: number;
  messageType: string;
  senderName: string;
  senderId: number;
  payloadName: string;
  payloadType: string;
  payloadId: number;
  read: boolean;
  type: string;
  createdAt: string;
}

const style = {
  borderRadius: 0,
  opacity: 0.9,
  padding: "1.5em",
  maxHeight: "90vh",
};

const Notifications = () => {
  const userId = useAppSelector((state) => state.user.id); // redux hook equivalent to connect HOC
  const { loading, data, error } = useQuery(ALL, {
    variables: { userId },
  });
  let totalNotifs = 0;

  if (loading) {
    return (
      <div className={styles.container}>
        <Icon name="bell" size="large" color="grey" inverted link />
      </div>
    );
  }

  if (error) return <span>error</span>;

  // only run if notifications found
  if (data?.allNotifications?.notifications?.length) {
    // use reduce function to find sum of array. maps through and finds total unread notifs
    totalNotifs = data.allNotifications.notifications
      .map((notification: notificationType) => (notification.read === false ? 1 : 0))
      .reduce((a: number, b: number) => a + b);
  }

  const minFlag = totalNotifs >= 10; // controls size of circle generated

  return (
    <div className={styles.container}>
      {data && data.allNotifications && data.allNotifications.notifications && (
        <div>
          <Popup
            on="click"
            trigger={(
              <div className={styles.icon}>
                <Icon name="bell" size="large" color="grey" inverted link />
              </div>
            )}
            position="bottom right"
            content={(
              <div className={styles.popupContent}>
                {data.allNotifications.notifications.map((notification: notificationType) => (
                  <Notification key={notification.id} notification={notification} />
                ))}
                {!data.allNotifications.notifications[0] && (
                  <div className={styles.container}>
                    <p>No new notifications</p>
                  </div>
                )}
              </div>
            )}
            style={style}
          />
          {totalNotifs && (
            <div
              className={styles.badge}
              style={{
                width: 20 + Math.floor(Math.log10(totalNotifs) - (minFlag ? 1 : 0)) * 10,
              }}
            >
              {totalNotifs}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Notifications;
