import { ReactElement, RefObject, MouseEvent } from "react";
import { Sidebar as SemanticSidebar } from "semantic-ui-react";

interface Props {
  children: ReactElement;
  open: boolean;
  handleHide: () => void;
  canToggleOpen: boolean;
  ref: RefObject<HTMLElement>;
}

const isCreateAssignmentTarget = (element: HTMLElement) => element?.className === "CreateAssignment";

const Sidebar = ({
  children, open, handleHide, canToggleOpen, ref,
}: Props) => {
  const onHide = (event: MouseEvent) => {
    // @ts-ignore
    const CreateAssignmentElement = event.composedPath()[event.composedPath().length - 9];
    if (canToggleOpen || !isCreateAssignmentTarget(CreateAssignmentElement)) handleHide();
  };

  return (
    <SemanticSidebar
      animation="uncover"
      icon="labeled"
      inverted
      onHide={onHide}
      target={ref}
      vertical
      visible={open}
      width="wide"
    >
      {children}
    </SemanticSidebar>
  );
};

export default Sidebar;
