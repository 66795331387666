import { EIGHTH_BAR_X, EIGHTH_BAR_Y, SIXTEENTH_BAR_Y } from ".";

interface Props {
  n: number;
  noteDecorationFill: string[];
  opacity: number[];
  topOpacity: number[];
}

const OneAndA = ({
  n, noteDecorationFill, opacity, topOpacity,
}: Props) => (
  <>
    <path
      transform={`translate(${30 * n}, 0)`}
      d={`m${EIGHTH_BAR_X}, ${EIGHTH_BAR_Y}l60.5,0l0,3.875l-60.5,0l0,-3.875z`}
      fill={noteDecorationFill[n]}
      fillOpacity={topOpacity[n + 2]}
    />
    <path
      transform={`translate(${30 * n + 60.5}, 0)`}
      d={`m${EIGHTH_BAR_X}, ${EIGHTH_BAR_Y}l30,0l0,3.875l-30,0l0,-3.875z`}
      fill={noteDecorationFill[n + 2]}
      fillOpacity={topOpacity[n + 3]}
    />
    <path
      transform={`translate(${30 * n + 60}, 0)`}
      d={`m${EIGHTH_BAR_X}, ${SIXTEENTH_BAR_Y}l30.6,0l0,3.875l-30.6,0l0,-3.875z`}
      fill={noteDecorationFill[n + 2]}
      fillOpacity={opacity[n + 3]}
    />
  </>
);

export default OneAndA;
