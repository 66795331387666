import { Icon, Popup } from "semantic-ui-react";
import { setCreateAssignmentOpen, setBeatLibraryOpen } from "store/drumroom/slice";
import { useAppDispatch } from "store/hooks";

const Assignment = () => {
  const dispatch = useAppDispatch();

  const createNewAssignment = () => {
    dispatch(setBeatLibraryOpen(true));
    dispatch(setCreateAssignmentOpen(true));
  };

  return (
    <Popup
      content="Create Assignment"
      position="bottom left"
      on="hover"
      trigger={
        <Icon link circular name="send" onClick={createNewAssignment} />
      }
    />

  );
};

export default Assignment;
