import { useAppSelector } from "store/hooks";
import GlobalAlert from "./GlobalAlert";
import styles from "./styles.module.scss";

const Alerts = () => {
  const alerts = useAppSelector((state) => state.alert);
  const openTabs = useAppSelector((state) => state.drumroom.openTabs);

  let offsetY = 30;
  if (openTabs.createAssignmentOpen) {
    offsetY = 45;
    if (openTabs.createAssignmentShown) {
      offsetY = 304;
    }
  } else if (openTabs.saveBeatOpen) {
    offsetY = 45;
    if (openTabs.saveBeatShown) {
      offsetY = 285;
    }
  } else if (openTabs.saveStockBeatOpen) {
    offsetY = 45;
    if (openTabs.saveStockBeatShown) {
      offsetY = 285;
    }
  }

  return (
    <div className={styles.container} style={{ bottom: offsetY }}>
      {alerts
        && alerts.map((alert) => <GlobalAlert key={alert.message} message={alert.message} duration={alert.duration} />)}
    </div>
  );
};

export default Alerts;
