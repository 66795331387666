import gql from "graphql-tag";

const SEND_RESET_EMAIL = gql`
  query($email: String!) {
    sendResetEmail(email: $email) {
      ok
      error
    }
  }
`;

export default SEND_RESET_EMAIL;
