import { useEffect, useState } from "react";
import { useAppSelector, useAppDispatch } from "store/hooks";
import {
  setMetronome,
  toggleColor,
  toggleCounting,
  toggleGrid,
  toggleSticking,
  toggleSubdivision,
  toggleToms as toggleTomsEnabled,
} from "store/beat/slice";
import {
  setAlternate, setCountIn, toggleLoop,
} from "store/drumroom/slice";
import {
  getCountIn,
  getCountingIn,
  getAlternate,
  getLoop,
} from "store/drumroom/selectors";
import {
  getColor,
  getCounting,
  getGrid,
  getMetronome,
  getNotes,
  getSticking,
  getSubdivision,
  getToms,
} from "store/beat/selectors";
import Toggle from "components/Toggle";
import { Popup } from "semantic-ui-react";
import styles from "./styles.module.scss";

const EditorToggles = () => {
  const [popupOpen, setPopupOpen] = useState(false);
  const dispatch = useAppDispatch();

  const alternate = useAppSelector(getAlternate);
  const countIn = useAppSelector(getCountIn);
  const countingIn = useAppSelector(getCountingIn);
  const loop = useAppSelector(getLoop);
  const toms = useAppSelector(getToms);

  const color = useAppSelector(getColor);
  const counting = useAppSelector(getCounting);
  const grid = useAppSelector(getGrid);
  const metronome = useAppSelector(getMetronome);
  const sticking = useAppSelector(getSticking);
  const subdivision = useAppSelector(getSubdivision);
  const notes = useAppSelector(getNotes);

  const handleMetronome = () => dispatch(setMetronome(!metronome));
  const handleAlternate = () => dispatch(setAlternate(!alternate));
  const handleCountIn = () => {
    if (!countIn) dispatch(setMetronome(true));
    if (!countingIn) dispatch(setCountIn(!countIn));
  };
  const handleToms = () => dispatch(toggleTomsEnabled());

  const isMultiMeasure = notes.length > 1;
  const handleAlternateHover = () => {
    if (isMultiMeasure) setPopupOpen(!popupOpen);
  };

  useEffect(() => {
    if (isMultiMeasure && alternate) handleAlternate();
  }, [isMultiMeasure]);

  return (
    <div className={styles.container}>
      <Toggle
        label="Counting"
        checked={counting}
        handleClick={() => dispatch(toggleCounting())}
      />
      <Toggle
        label="Metronome"
        checked={metronome}
        handleClick={handleMetronome}
      />
      <Toggle
        label="Subdivision"
        checked={subdivision}
        handleClick={() => dispatch(toggleSubdivision())}
      />
      <Toggle
        label="Sticking"
        checked={sticking}
        handleClick={() => dispatch(toggleSticking())}
      />
      <Toggle
        label="Color"
        checked={color}
        handleClick={() => dispatch(toggleColor())}
      />
      <Toggle
        label="Grid"
        checked={grid}
        handleClick={() => dispatch(toggleGrid())}
      />
      <Toggle label="Count-In" checked={countIn} handleClick={handleCountIn} />
      <Toggle
        label="Loop"
        checked={loop}
        handleClick={() => dispatch(toggleLoop())}
      />
      <Popup
        content="Alternate is not available for multi-measure beats."
        basic
        on="hover"
        open={popupOpen}
        onClose={() => setPopupOpen(false)}
        onOpen={handleAlternateHover}
        position="bottom right"
        trigger={(
          <div>
            <Toggle
              label="Alternate"
              checked={alternate}
              disabled={isMultiMeasure}
              handleClick={handleAlternate}
            />
          </div>
        )}
      />
      <Toggle
        label="Toms"
        checked={toms}
        handleClick={handleToms}
      />
    </div>
  );
};

export default EditorToggles;
