import { useState, ChangeEvent, CSSProperties } from "react";
import {
  Form, Message, Button, Input, Header as SemanticHeader,
} from "semantic-ui-react";
import { useLazyQuery } from "@apollo/client";
import SEND_RESET_EMAIL from "graphql/user/queries/sendResetEmail";
import Header from "components/Header";
import background from "images/loginBg.png";
import ConfirmResetMessage from "./ConfirmResetMessage";

const ResetPassword = () => {
  const backgroundImgStyle: CSSProperties = {
    position: "absolute",
    width: "100%",
    height: "100%",
    objectFit: "cover",
  };

  const [email, setEmail] = useState("");
  const [sentEmail, setSentEmail] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  const [sendResetEmail, { data }] = useLazyQuery(SEND_RESET_EMAIL);

  if (data && data.sendResetEmail.ok && !sentEmail) {
    setSentEmail(true);
  } else if (data && data.sendResetEmail.error && !errorMsg) {
    setErrorMsg(data.sendResetEmail.error);
  }

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setEmail(value);
  };

  const onSubmit = () => {
    sendResetEmail({ variables: { email } });
  };

  return (
    <div>
      <img src={background} style={backgroundImgStyle} alt="LoginBg" />
      <Header marketing />

      <div className="ResetPasswordPanel" style={{ height: errorMsg ? "270px" : "210px" }}>
        {!sentEmail && (
          <Form>
            <SemanticHeader as="h2">Reset Password</SemanticHeader>
            <Form.Field error={!!errorMsg}>
              <Input name="email" onChange={onChange} value={email} placeholder="Email" fluid />
            </Form.Field>
            <Form.Field>
              <Button onClick={onSubmit}>Send Link</Button>
            </Form.Field>

            {errorMsg ? (
              <Message
                className="ui warning visible message"
                compact
                error
                content={errorMsg}
                style={{
                  position: "relative",
                  top: "0px",
                  left: "0px",
                  height: 45,
                  width: "240px",
                }}
              />
            ) : null}
            <a href="https://drumroom.io/pricing/" style={{ position: "relative" }}>
              Need an account? Click to register.
            </a>
          </Form>
        )}

        {sentEmail && (
          <div
            style={{
              position: "relative",
              justifyContent: "center",
              margin: "auto",
              width: "75%",
              top: "0px",
            }}
          >
            <ConfirmResetMessage />
          </div>
        )}
      </div>
    </div>
  );
};

export default ResetPassword;
