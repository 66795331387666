import { useState } from "react";
import {
  Confirm, Icon, Menu, Dropdown,
} from "semantic-ui-react";
import { useHistory, useLocation } from "react-router-dom";
import { useAppSelector } from "store/hooks";
import Notifications from "components/Notifications";

import useClearAppState from "hooks/useClearAppState";

import styles from "./styles.module.scss";

const NavBar = () => {
  const location = useLocation();
  const history = useHistory();
  const clearAppState = useClearAppState();
  const [isLogoutOpen, setIsLogoutOpen] = useState(false);
  const { type, firstName, lastName } = useAppSelector((store) => store.user);

  const onOpenLogout = () => setIsLogoutOpen(true);
  const onCancelLogout = () => setIsLogoutOpen(false);
  const handleLogout = () => {
    clearAppState();

    history.push("/login");
  };

  return (
    <div className={styles.container}>
      <Menu secondary inverted pointing>
        <Menu.Item
          active={location.pathname === "/"}
          onClick={() => history.push("/")}
        >
          <Icon name="pencil" />
          Editor
        </Menu.Item>
        {type === 1 && (
          <Menu.Item
            active={location.pathname === "/my-students"}
            onClick={() => history.push("/my-students")}
          >
            <Icon name="group" />
            My Students
          </Menu.Item>
        )}
        <Dropdown item text={`${firstName} ${lastName}`}>
          <Dropdown.Menu>
            <Dropdown.Item
              icon="settings"
              text="Settings"
              onClick={() => history.push("/settings")}
            />
            {type === 1 && (
              <Dropdown.Item
                icon="dollar"
                text="Billing"
                onClick={() => window.open(process.env.REACT_APP_STRIPE_BILLING_URL)}
              />
            )}
            <Dropdown.Item icon="logout" text="Logout" onClick={onOpenLogout} />
          </Dropdown.Menu>
        </Dropdown>
        <Notifications />
      </Menu>
      <Confirm
        open={isLogoutOpen}
        content="Are you sure you want to log out? Any unsaved beats will be lost."
        confirmButton="Logout"
        onCancel={onCancelLogout}
        onConfirm={handleLogout}
        style={{ width: "450px" }}
      />
    </div>
  );
};

export default NavBar;
