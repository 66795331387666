/* eslint-disable max-len */

interface Props {
  transform: string;
  opacity: number;
}

const Sixteenth = ({
  transform, opacity,
}: Props) => (
  <path
    transform={transform}
    strokeWidth="0.01"
    fillOpacity={opacity}
    d="m68.84454,62.847c-0.519,0.098 -0.918,0.457 -1.093,0.953c-0.043,0.16 -0.043,0.199 -0.043,0.418c0,0.301 0.019,0.461 0.16,0.699c0.199,0.399 0.617,0.719 1.098,0.836c0.496,0.141 1.292,0.039 2.25,-0.277c0.14,-0.059 0.257,-0.102 0.257,-0.082c0,0.023 -0.894,2.93 -0.933,3.031c-0.102,0.258 -0.442,0.735 -0.739,1.035c-0.277,0.278 -0.418,0.34 -0.636,0.239c-0.18,-0.098 -0.239,-0.2 -0.36,-0.739c-0.101,-0.398 -0.179,-0.617 -0.339,-0.773c-0.418,-0.461 -1.137,-0.52 -1.692,-0.16c-0.262,0.179 -0.461,0.457 -0.578,0.758c-0.043,0.156 -0.043,0.199 -0.043,0.417c0,0.297 0.023,0.458 0.16,0.696c0.199,0.398 0.617,0.719 1.098,0.836c0.219,0.062 0.777,0.062 1.156,0c0.316,-0.059 0.695,-0.157 1.074,-0.278c0.16,-0.058 0.301,-0.097 0.301,-0.078c0,0 -1.953,6.356 -1.992,6.453c0,0.02 0.156,0.141 0.316,0.18c0.16,0.063 0.321,0.063 0.481,0c0.156,-0.039 0.316,-0.137 0.316,-0.199c0.02,-0.02 0.817,-3.027 1.793,-6.676l1.774,-6.633l-0.039,-0.058c-0.079,-0.121 -0.239,-0.141 -0.379,-0.082c-0.079,0.039 -0.079,0.039 -0.317,0.398c-0.199,0.32 -0.48,0.656 -0.64,0.816c-0.219,0.18 -0.336,0.219 -0.536,0.141c-0.179,-0.098 -0.242,-0.199 -0.359,-0.738c-0.121,-0.535 -0.262,-0.778 -0.559,-0.977c-0.277,-0.179 -0.636,-0.238 -0.957,-0.156z"
    id="path90"
    stroke="#000000"
  />
);

export default Sixteenth;
