import decode from "jwt-decode";

const isValidated = () => {
  let result = false;
  const needsValidateToken = localStorage.getItem("validateToken") || "";

  try {
    decode(needsValidateToken);
    result = false;
  } catch {
    result = true;
  }

  return result;
};

export default isValidated;
