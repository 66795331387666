import gql from "graphql-tag";

const STOCK_FOLDERS = gql`
  {
    stockFolders {
      id
      name
      order
      exercise
      song
    }
  }
`;

export default STOCK_FOLDERS;
