import { useState, ChangeEvent } from "react";
import { useAppSelector, useAppDispatch } from "store/hooks";
import Slider from "react-input-slider";
import { setBpm } from "store/beat/slice";
import styles from "./styles.module.scss";

const BpmPicker = () => {
  const dispatch = useAppDispatch();
  const bpm = useAppSelector((store) => store.beat.present.bpm);

  const [showInput, setShowInput] = useState(false);
  const [inputBpm, setInputBpm] = useState(bpm);

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;

    const re = /^[0-9\b]+$/;
    let currentBpm = bpm;
    if (value === "" || re.test(value)) {
      // if string is blank or passes regex, set max bpm 244 and min 0
      currentBpm = parseInt(value, 10);
      if (parseInt(value, 10) > 244) {
        currentBpm = 244;
      } else if (value === "" || null) {
        currentBpm = 0;
      }

      setInputBpm(currentBpm);
    }
  };

  const handleBPMInput = () => {
    if (inputBpm === 0 || inputBpm < 20) {
      dispatch(setBpm(20));
    } else {
      dispatch(setBpm(inputBpm));
    }

    setShowInput(false);
  };

  const handleShowBPMInput = () => {
    setInputBpm(bpm);
    setShowInput(!showInput);
  };

  const updateBpm = (x: number) => {
    dispatch(setBpm(x));
  };

  return (
    <div>
      <div>
        <div className={styles.text}>
          <p className={styles.label}>BPM</p>
          <button
            type="button"
            className={styles.number}
            onClick={handleShowBPMInput}
          >
            {bpm}
          </button>

          {showInput && (
            <input
              className={styles.input}
              // eslint-disable-next-line
              autoFocus
              placeholder="BPM"
              max="244"
              value={inputBpm}
              onChange={onChange}
              onBlur={handleBPMInput}
            />
          )}
        </div>
        <Slider
          axis="x"
          x={bpm}
          xstep={1}
          xmin={20}
          xmax={244}
          onChange={({ x }) => updateBpm(x)}
          styles={{
            track: {
              width: 80,
            },
          }}
        />
      </div>
    </div>
  );
};

export default BpmPicker;
