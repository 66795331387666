import gql from "graphql-tag";

const LOGIN = gql`
  mutation($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      email
      errors {
        path
        message
      }
      id
      ok
      refreshToken
      subscriptionActive
      token
      validated
    }
  }
`;

export default LOGIN;
