import { useSpring, animated } from "react-spring";
import { Icon, Segment } from "semantic-ui-react";
import styles from "./styles.module.scss";

interface Props {
  message: string;
  duration: number;
}

const GlobalAlert = ({ message = "", duration = 3000 }: Props) => {
  const springProps = useSpring({
    zIndex: 10000,
    opacity: 0,
    from: { opacity: 1 },
    config: { duration },
  });

  return (
    <div className={styles.container}>
      <animated.div style={springProps}>
        <Segment inverted>
          <Icon style={{ color: "#2E8B57" }} name="check" />
          {message}
        </Segment>
      </animated.div>
    </div>
  );
};

export default GlobalAlert;
