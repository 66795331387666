import Header from "components/Header";
import Box from "@material-ui/core/Box";

const UserAgreement = () => (
  <>
    <Header />

    <Box mx={6} my={4}>
      <h1>
        <strong>End User License Agreement</strong>
      </h1>

      <p>
        <strong>Effective Date: 1/1/2022</strong>
      </p>

      <p>
        <em>
          *PLEASE READ THIS AGREEMENT CAREFULLY BEFORE YOU OR A CHILD AUTHORIZED BY YOU (FOR WHOM YOU ARE A PARENT OR
          LEGAL GUARDIAN) (“YOU” OR “YOUR”) ACCESS OR USE THE DRUMROOM SOFTWARE (“DRUMROOM”), AND/OR
          {" "}
          <a href="HTTPS://WWW.DRUMROOM.NET">HTTPS://WWW.DRUMROOM.NET</a>
          {" "}
          OR
          {" "}
          <a href="HTTPS://WWW.DRUMROOM.IO">HTTPS://WWW.DRUMROOM.IO</a>
          {" "}
          (TOGETHER “THE
          WEBSITE”). IT CONTAINS IMPORTANT INFORMATION ABOUT YOUR RIGHTS AND OBLIGATIONS. BY CLICKING “I ACCEPT” YOU
          ACKNOWLEDGE THAT YOU ARE 18 YEARS OF AGE OR OLDER, HAVE THE RIGHT, AUTHORITY AND CAPACITY TO ENTER INTO THIS
          AGREEMENT, AND THAT YOU HAVE READ, UNDERSTAND AND AGREE TO BE BOUND BY THIS AGREEMENT. *
        </em>
      </p>

      <p>
        Welcome to Drumroom, a musical instruction and transcription software provided by Drumroom, Inc. (hereinafter
        “us” or “we”). The website (including all content under the “drumroom.net” and “drumrooom.io” domain names) and
        Drumroom are owned by us. We provide the website, Drumroom, and the associated products, services, data,
        information, updates, and materials (altogether, the “services”), subject to your agreement to and compliance
        with the terms and conditions set out in this document and its incorporated terms (the “Terms”). Please
        carefully read these Terms that govern your access to and use of the services, and that apply to all users.
        {" "}
        <strong>If you do not agree and consent to these Terms, please do not use the services.</strong>
        {" "}
        If you are
        accepting this Agreement on behalf of a legal entity other than yourself as an individual, including a business
        or government, you represent and warrant that you have full legal authority to bind such entity to this
        Agreement. You cannot purchase Drumroom, Inc. services until you have indicated your acceptance of these Terms.
      </p>

      <h1>Important Notices</h1>

      <ol>
        <li>
          <p>
            By using the services, you represent that you have read, understand, and agree to all the terms and
            conditions of these Terms, including our Privacy Policy (“Privacy Policy”) which is fully incorporated
            herein by reference. These Terms and the Privacy Policy are subject to the provisions of the European Union
            (“EU”) General Data Protection Regulation (“GDPR”), the California Consumer Privacy Act (“CCPA”), and the
            Children&apos;s Online Privacy Protection Act (“COPPA”) and other applicable privacy laws. Under the GDPR,
            we are a data “Controller” and you are a “Data Subject” with certain protected privacy rights concerning
            your “Personal Data.” Personal Data may also be referred to as Personally Identifiable Information (“PII”).
          </p>
        </li>
        <li>
          <p>
            We reserve the right to change, modify, add to, or otherwise alter these Terms at any time, or to change or
            discontinue any aspect or feature of the services without notice to you. Such changes, modifications,
            additions, or deletions will be effective immediately upon posting to the website, or update of the
            application. You agree to review these Terms periodically to be aware of such revisions. If you have an
            account with the application, we may inform you of any material changes by notification to the email address
            you provided as part of your initial registration. Otherwise, you may view the most recent Terms on the
            website. Your use of the services after we post such changes, modifications, additions, or deletions
            constitutes your acceptance of said changes, modifications, additions, or deletions constitutes your
            acceptance of said changes, modifications, additions, or deletions.
            <em>
              *Notwithstanding the foregoing, we will notify you via email regarding any changes in the Privacy Policy,
              if you provided us with your email address. *
            </em>
          </p>
        </li>
      </ol>

      <h1>Registration</h1>

      <p>
        The account creator must be 13 years of age or older to create an account on Drumroom, or accept a pairing
        request between users. If the account is intended for use by an individual under 13 years of age, the account
        creator, and acceptor of pairing requests, must be the parent or legal guardian of the child. The services are
        intended to comply with COPPA. If you reside in the United States, we encourage you to visit
        <a href="http://www.ftc.gov/bcp/edu/pubs/consumer/tech/tec08.shtm">
          http://www.ftc.gov/bcp/edu/pubs/consumer/tech/tec08.shtm
        </a>
        {" "}
        for more information about COPPA and children&apos;s privacy. For questions about how we handle children&apos;s
        data, please see our Privacy Policy, which is posted on the Website.
      </p>

      <p>
        You agree to provide accurate, current, and complete information during the registration process (“Registration
        Information”). You are solely responsible for maintaining the security of your Registration Information, and any
        and all use of your account.
      </p>

      <p>
        You agree to indemnify, defend, and hold us harmless for damages or losses incurred by you or another party due
        to any access to or use of your account, or any information contained therein, by a third party, whether
        authorized or unauthorized. We will not be liable for any access to your account caused by unauthorized
        disclosure of your account information to any third party. You agree to notify us in writing immediately if you
        suspect any unauthorized use of or access to your account.
      </p>

      <h1>License and Conditions of Use</h1>

      <p>
        Conditioned on your compliance with these Terms and fulfillment of any applicable payment obligations, we grant
        to you a personal, revocable, limited, non-exclusive, non-transferable license to use Drumroom. We reserve all
        rights of ownership in and to Drumroom not expressly granted to you.
      </p>

      <p>
        You may not use Drumroom in any manner that violates any applicable law or regulation or that is inconsistent
        with these Terms. Specifically, you agree that you shall not (i) access or attempt to access any account that
        you are not authorized to access, (ii) modify or attempt to modify Drumroom in any manner or form, (iii) copy,
        distribute, or create derivative works based on the Services Content (as defined below), (iv) exploit the
        Services Content in any unauthorized way whatsoever, including, but not limited to, by trespass or burdening
        network capacity, or (v) sublicense, sell, resell, or otherwise convey Drumroom or any elements thereof.
      </p>

      <p>
        Your violation of any of these Terms may result in automatic termination of your account and the licenses
        granted hereunder.
      </p>

      <h1>Billing Policies</h1>

      <p>
        You may access portions of Drumroom&apos;s content free of charge, while other services and products require
        payment of fees and/or subscriptions. Current pricing for services and subscriptions is available on Drumroom.
        We reserve the right to modify prices and to add, or remove, services or subscription options at any time
        without prior notice to you. The content available on the services is determined by the trial period or chosen
        subscription level and are subject to change without prior notice to you.
      </p>

      <p>
        Payment of service or subscription fees must be made with a current and valid credit card, debit card, or other
        payment account (“Payment Account”). If your Payment Account is insufficient, or not accurate, current, and
        complete, we may refuse your use of Drumroom and, where applicable, suspend or terminate your subscription. All
        fees will be billed to the Payment Account you provide to us.
      </p>

      <p>
        Service fees will be immediately billed to your Payment Account on a one-time basis at the time of your Order.
        For subscriptions, fees will be automatically billed to your Payment Account on a recurring monthly or yearly
        basis unless and until you cancel (see below for more details). You acknowledge and agree that we will not
        obtain additional authorization from you for each recurring fee charged to your Payment Account.
      </p>

      <p>
        We may offer you full access to Drumroom&apos;s content through free and/or paid trials. If you choose a paid
        trial, the trial fee will be immediately billed to your Payment Account. Your subscription will begin on the
        “Next Billing Date” (as identified on your Order Confirmation, and on the Account Details page of your account).
        The monthly, or yearly, subscription fee - as authorized by you during the payment process - will be billed to
        your Payment Account on that date and thereafter on a recurring monthly, or yearly, basis unless and until you
        cancel.
      </p>

      <p>
        <strong>
          RECURRING PAYMENT SUBSCRIPTION PLANS AUTOMATIC RENEWAL FEES WILL BE AUTOMTICALLY CHARGED TO YOUR PAYMENT
          ACCOUNT.
        </strong>
      </p>

      <p>
        WE WILL AUTOMATICALLY RENEW YOUR SUBSCRIPTION ON EITHER A MONTHLY OR YEARLY BASIS BEGINNING ON THE “NEXT BILLING
        DATE” IDENTIFIED IN YOUR ORIGINAL ORDER CONFIRMATION AND EITHER EVERY 30 DAYS OR YEAR THEREAFTER (THE “AUTOMATIC
        RENEWAL DATE”), AS AUTHORIZED BY YOU DURING THE PAYMENT PROCESS BY CHECKING THE BOX DEMONSTRATING YOUR CONSENT
        TO SUBSCRIPTOIN BILLING. YOUR PAYMENT ACCOUNT WILL BE CHARGED THE THEN CURRENT RATE FOR THE SAME SUBSCRIPTION
        SELECTED BY YOU IN CONNECTION WITH YOUR ORIGINAL SERVICE ORDER ON EACH AUTOMATIC RENEWAL DATE. UPON PAYMENT OF
        YOUR ORIGINAL SERVICE ORDER, WE WILL SEND YOU AN ACKNOWLEDGEMENT VIA EMAIL OF THE PARTICULAR TERMS OF YOUR
        RECURRING PAYMENT PLAN, INCLUDING THE AUTOMATIC RENEWAL DATE, AND INSTRUCTIONS FOR CANCELLING YOUR SUBSCRIPTION
        TO AVOID AUTOMATIC RENEWAL.
      </p>

      <p>
        THEREAFTER, WE WILL ALSO SEND YOU AN AUTOMATIC RENEWAL DATE REMINDER, AND PROVIDE YOU WITH NOTICE OF ANY CHANGE
        IN THE SUBSCRIPTION RATE OR MATERIAL CHANGES TO OTHER TERMS OF YOUR RECURING PAYMENT PLAN, VIA EMAIL, AT LEAST 5
        DAYS BEFORE THE AUTOMATIC RENEWAL DATE.
      </p>

      <p>
        YOU MAY CANCEL YOUR ACCOUNT AND SUBSCRIPTION AT ANY TIME. TO DO SO USE THE “CANCEL” OPTION ON YOUR “MY ACCOUNT”
        PAGE.
      </p>

      <p>
        <strong>
          PLEASE NOTE THAT SUBSCRIPTION FEES ARE BILLED IN ADVANCE, AND WE WILL NOT REFUND THE UNUSED PORTION OF YOUR
          SUBSCRIPTION. THEREFORE, YOU MUST CANCEL BEFORE THE AUTOMATIC RENEWAL DATE IN ORDER TO AVOID AUTOMATIC RENEWAL
          OF YOUR TRIAL / FULL PAID SUBSCRIPTION, AND THE ASSOCIATED MONTHLY OR YEARLY SUBSCRIPTION FEES.
        </strong>
      </p>

      <h1>Copyrights and Trademarks</h1>

      <p>
        Except with respect to images and text that are in the public domain or otherwise publicly available, the
        services and its content, including but not limited to any and all text, graphics, images, photographs, and
        computer code (collectively “Services Content”) is owned by Drumroom, Inc.and/or its related companies or
        licensors, and may be protected by copyright, trademark, patent, trade secret, and/or other laws. Drumroom, Inc.
        and/or its related companies or licensors own and retain all rights, including the worldwide copyright, in the
        Services Content solely and exclusively, for the duration of the rights in each country, in all languages, and
        throughout the universe. You may not use the Services Content in any way whatsoever except as in compliance with
        these Terms. You may not modify, rent, lease, loan, sell, distribute, redistribute, or create derivative works
        based on the Services Content. You may not alter or delete any proprietary notices from Services Content
        downloaded, screen shotted, or printed from the services.
      </p>

      <p>
        DRUMROOM is a trademark of The Musical Suite, Inc. and/or its related companies, and any use of the mark without
        prior express written permission is hereby strictly prohibited.
      </p>

      <h1>Disclaimers</h1>

      <p>
        You expressly agree that use of the services is at your sole risk. Drumroom, Inc. shall not be liable for any
        loss, liability, cost, or damage arising either directly or indirectly from your access to or use of (or
        inability to access or use) the services, including any content contributing to, but not limited to, any loss of
        use, system damage, impairment or interruption, lost data, personal injury or any other pecuniary or financial
        loss, whether in an action of contract, negligence, equity or other legal theory.
      </p>

      <h1>No Warranties</h1>

      <p>
        TO THE MAXIMUM EXTENT ALLOWED BY APPLICABLE LAW, DRUMROOM, INC. PROVIDES THE SERVICES “AS IS” AND “AS AVAILABLE”
        WITHOUT WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED. DRUMROOM, INC. MAKES ABSOLUTELY NO WARRANTIES
        WHATSOEVER, EXPRESS OR IMPLIED. TO THE FULLEST EXTENT POSSIBLE UNDER APPLICABLE LAWS, DRUMROOM, INC. DISCLAIMS
        ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, IMPLIED WARRANTIES OF MERCHANTABILITY, FITNES
        FOR A PARTICULAR PURPOSE, NON-INFIRNGEMENT, OR OTHER VIOLATIONS OF RIGHTS.
      </p>

      <p>
        DRUMROOM, INC. DOES NOT WARRANT OR MAKE ANY REPRESENTATIONS REGARDING THE USE, VALIDITY, ACCURACY, RELIABILITY,
        OR THE RESULTS OF THE USE OF, OR OTHERWISE RESPECTING DRUMROOM. YOUR USE OF DRUMROOM IS SOLELY AT YOUR OWN RISK.
      </p>

      <p>
        Drumroom, Inc. does not warrant that Drumroom will be uninterrupted or error free; nor does Drumroom, Inc. make
        any warranty as to the results that may be obtained from use of Drumroom, or as to the reliability of any
        information, service, or materials provided through the services.
      </p>

      <h1>Limitation of Liability</h1>

      <p>
        DRUMROOM, INC. IS NOT RESPONSIBLE FOR ANY PROBLEMS OR TECHNICAL MALFUNCTION OF ANY WEBSITE, NETWORK, COMPUTER
        SYSTEMS, SERVERS, PROVIDERS, COMPUTER EQUIPMENT, OR SOFTWARE, OR FOR ANY FAILURE DUE TO TECHNICAL PROBLEMS OR
        TRAFFIC CONGESTION ON THE INTERNET OR THE SERVICES, OR COMBINATION THEREOF, INCLUDING ANY INJURY OR DAMAGE OT
        USERS OR TO ANY COMPUTER OR OTHER DEVICE ON OR THROUGH WHICH THE SERVICES ARE PROVIDED. UNDER NO CIRCUMSTANCES
        WILL WE BE LIABLE FOR ANY LOSS OR DAMAGE INCLUDING PERSONAL INJURY OR DEATH, RESULTING FROM USE OF THE SERVICES,
        OR FROM THE CONDUCT OF ANY USERS OF THE SERVICES, WHETHER ONLINE OR OFFLINE.
      </p>

      <p>
        DRUMROOM, INC., ITS PARENTS, SUBSIDIARIES, OFFICERS, DIRECTORS, MANAGERS, SHAREHOLDERS, EMPLOYEES, AGENTS,
        CONTRACTORS, OR LICENSORS, WILL NOT BE LAIBLE TO YOU FOR (I) LOST INCOME, LOST PROFITS, LOSS OF DATA, ANY
        DIRECT, INDIRECT, INCIDENTAL, PUNITIVE, SPECIAL OR CONSEQUENTIAL DAMAGES, OR CLAIMS OF THIRD PARTIES, (II) ANY
        LOSS OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF THE USE OF OR RELIANCE UPON THE COMPLETENESSS OR ACCURACY OF
        ANY INFORMATION, SERVICE, OR MATERIALS MADE AVAILABLE VIA THE SERVICES. THESE LIMITATIONS OF LIABILITY FOR SUCH
        LOSSES WILL APPLY EVEN IF WE HAVE BENE ADVISED OF OR SHOULD HAVE BEEN AWARE OF THEIR POSSIBILITY.
      </p>

      <p>
        OUR AGGREGATE LIABILITY UNDER OR WITH RESPECT TO YOUR USE OF THE SERVICES WILL IN NO EVENT EXCEED THE TOTAL FEES
        PAID BY YOU.
      </p>

      <h1>Indemnification</h1>

      <p>
        You agree to indemnify, defend, and hold harmless Drumroom, Inc. and its officers, directors, agents, employees,
        partners, affiliates, licensors, and data providers, and each of their respective members, officers, directors,
        employees, agents, shareholders, co-branders, content licensors, suppliers, contractors, attorneys, and other
        partners, from and against any and all liabilities, claims, expenses (including reasonable attorneys’ fees),
        damages, suits, costs, demands, and judgments made from any third party arising from or related to (i) your use
        of the services or any Information you obtain through the services and (ii) your violation of these Terms, (iii)
        your violation or infringement of any laws or any rights of another individual or entity, or (iv) any actual,
        prospective or terminated sale or other transaction between you and a third party.
      </p>

      <h1>Termination</h1>

      <p>
        We reserve the right to terminate or restrict your account and/or access to the services for any reason, or for
        no reason whatsoever; including, without limitation, if we in our sole discretion consider your use to be
        unacceptable, or in the event of any violation by you of any of these Terms. We may, but shall be under no
        obligation to, provide you with a warning prior to termination or restriction of your use of the services. We
        will not be liable to your or any third party for such termination, or restriction of your account and/or
        access.
      </p>

      <h1>Arbitration</h1>

      <p>
        Any dispute concerning Drumroom, Inc., any of the services, or these Terms will be settled by binding
        arbitration in Boston, Massachusetts, in accordance with the Commercial Arbitration Rules of the American
        Arbitration Association. Judgment upon the award made by the arbitrator(s) may be entered in any court having
        jurisdiction thereof. You acknowledge that agreeing to such binding arbitration constitutes a waiver of your
        right to a jury trial, and that this agreement to arbitrate is voluntary and not legally required. If any
        arbitration or legal action or similar proceeding is instituted by either party concerning the payment of our
        fees or costs advanced hereunder, the prevailing party will be entitled to collect its reasonable attorney’s
        fees, in addition to any other relief the party may obtain.
      </p>

      <h1>General Conditions</h1>

      <p>
        These Terms constitute the entire agreement between you and us, and governs your use of the services,
        superseding any prior agreements (including, but not limited to, any prior versions of these Terms).
      </p>

      <p>
        No agency, partnership, joint venture, employer-employee, or franchiser-franchisee relationship is intended or
        created by these terms.
      </p>

      <p>
        If any part of these Terms is held invalid or unenforceable, that portion will be construed in a manner
        consistent with applicable law to reflect, as nearly as possible, the original intentions of the parties, and
        the remaining portions will remain in full force and effect. Our failure to enforce any right or provisions in
        these Terms will not constitute a waiver of that provision or any other provision of these Terms.
      </p>

      <h1>Contact Us</h1>

      <p>
        If you have any questions or concerns about these Terms, the website, or Drumroom, please
        {" "}
        <a href="https://drumroom.io/">click here</a>
        {" "}
        to contact us or call us at (978) 462 - 1100 ext. 301.
      </p>
    </Box>
  </>
);

export default UserAgreement;
