import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface State {
  beatIds: number[];
}

const initialState: State = {
  beatIds: [],
};

export const assignmentSlice = createSlice({
  name: "assignment",
  initialState,
  reducers: {
    setBeats: (state, action: PayloadAction<State["beatIds"]>) => ({ ...state, beatIds: action.payload }),
  },
});

export const { setBeats } = assignmentSlice.actions;

export default assignmentSlice.reducer;
