import gql from "graphql-tag";

const DELETE = gql`
  mutation($id: Int!) {
    deleteFolder(id: $id) {
      ok
      folder {
        id
      }
      beats {
        id
        name
        shared
        folderId
      }
      beatsIncludeStock {
        id
        name
        shared
        folderId
      }
    }
  }
`;

export default DELETE;
