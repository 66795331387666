interface Props {
  index: number;
  fill: string;
  opacity: number;
  topNoteIndex: number;
}

const Dot = ({
  index, fill, opacity, topNoteIndex,
}: Props) => {
  // notes aren't equally spaced now, so index doesn't have linear relationship with position
  const offset = topNoteIndex * 5;

  return (
    <circle
      transform={`translate(${30 * index}, ${offset})`}
      className="fill"
      cx="75"
      cy="50"
      r="1.2"
      fill={fill}
      fillOpacity={opacity}
    />
  );
};

export default Dot;
