import Header from "components/Header";
import styles from "./styles.module.scss";

interface Props {
  message: string;
}

const ErrorFallback = ({ message }: Props) => (
  <div>
    <Header />
    <div className={styles.content}>
      <p>That&apos;s an error! Drumroom has encountered and issue. A log of the problem was sent to our team.</p>
      <p>Click on the link below to return to Drumroom.</p>
      <button type="button" className={styles.link} onClick={() => window.location.reload()}>
        Return to Drumroom
      </button>

      <h3 className={styles.message}>
        Error:
        {message}
      </h3>
    </div>
  </div>
);

export default ErrorFallback;
