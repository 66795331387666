import { Icon, Popup } from "semantic-ui-react";
import { useAppSelector, useAppDispatch } from "store/hooks";
import { setSaveBeatOpen, setSaveStockBeatOpen } from "store/drumroom/slice";

interface Props {
  isStock: boolean;
}

const Save = ({ isStock }: Props) => {
  const dispatch = useAppDispatch();
  const {
    saveBeatOpen: isOpen,
    saveStockBeatOpen: isStockOpen,
  } = useAppSelector((store) => store.drumroom.openTabs);

  const toggleForm = () => {
    if (isStock) dispatch(setSaveStockBeatOpen(!isStockOpen));
    else dispatch(setSaveBeatOpen(!isOpen));
  };

  return (
    <Popup
      content={`Save ${isStock ? "Stock" : ""} Beat`}
      position="bottom left"
      on="hover"
      trigger={(
        <Icon
          link
          circular
          name={isStock ? "book" : "save"}
          onClick={toggleForm}
        />
      )}
    />
  );
};

export default Save;
