import gql from "graphql-tag";

const CREATE = gql`
  mutation($beatIds: [Int]!, $assignment: String!, $studentId: Int!, $title: String, $teacherId: Int!) {
    createAssignment(
      beatIds: $beatIds
      assignment: $assignment
      studentId: $studentId
      title: $title
      teacherId: $teacherId
    ) {
      ok
      assignment {
        id
        content
        beats {
          id
          name
        }
        title
        createdAt
      }
    }
  }
`;

export default CREATE;
