import gql from "graphql-tag";

const UPDATE_READ = gql`
  mutation($id: Int!) {
    readNotification(id: $id) {
      ok
      notification {
        id
        messageType
        read
        senderName
        senderId
        payloadName
        payloadType
        payloadId
        type
        createdAt
      }
    }
  }
`;

export default UPDATE_READ;
