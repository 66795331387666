import React, { useState } from "react";
import { useMutation, useApolloClient } from "@apollo/client";
import {
  Icon, Button, Confirm, Dropdown,
} from "semantic-ui-react";
import { useDrag } from "react-dnd";
import { useAppSelector, useAppDispatch } from "store/hooks";
import _ from "lodash";
import GET from "graphql/beat/queries/get";
import REMOVE_SHARE from "graphql/beat/mutations/unshare";
import SHARE from "graphql/beat/mutations/share";
import STOCK_BEATS from "graphql/beat/queries/stockBeats";
import ALL_BEATS from "graphql/beat/queries/allBeats";
import UPDATE_NAME from "graphql/beat/mutations/updateName";
import DELETE from "graphql/beat/mutations/delete";
import CREATE_NOTIFICATION from "graphql/notification/mutations/create";
import { setBeats } from "store/assignment/slice";
import { sendAlert } from "store/alert/slice";
import { beatType } from "types/beat";
import { Target, ASSIGNMENT } from "types/target";
import { loadBeat } from "store/beat/slice";
import styles from "./styles.module.scss";

export interface Props {
  beat: beatType;
  closeDelete?: Function;
  confirmOpen?: boolean;
  handleDrop?: (beatId: number, folderId: number | null) => void;
  hideDropdown?: boolean;
  isAdmin?: boolean;
  openDelete?: Function;
  stock?: boolean;
}

const Beat = ({
  beat,
  closeDelete,
  confirmOpen,
  handleDrop,
  hideDropdown,
  isAdmin,
  openDelete,
  stock,
}: Props) => {
  const [state, setState] = useState({
    beatName: beat.name,
    updatedName: "",
    renaming: false,
    shared: beat.shared,
    alert: true,
    rendered: true,
  });
  const client = useApolloClient();
  const dispatch = useAppDispatch();

  const {
    type, firstName, lastName, id: userId,
  } = useAppSelector((store) => store.user);
  const { beatIds: assignmentBeatIds } = useAppSelector((store) => store.assignment);

  const [, drag] = useDrag(() => ({
    type: "beat",
    item: beat,
    end: (item, monitor) => {
      if (!monitor.didDrop()) {
        return;
      }

      const dropResult: Target = monitor.getDropResult();

      if (dropResult?.target === ASSIGNMENT) {
        const draggedBeats = [...assignmentBeatIds];

        if (draggedBeats.includes(beat.id) === false) draggedBeats.push(beat.id);
        dispatch(setBeats(draggedBeats));

        return;
      }

      if (handleDrop) handleDrop(beat.id, dropResult?.payload || null);
    },
  }), [assignmentBeatIds]);

  const [renameBeat] = useMutation(UPDATE_NAME);
  const [deleteBeat] = useMutation(DELETE);
  const [shareBeat] = useMutation(SHARE);
  const [removeSharedBeat] = useMutation(REMOVE_SHARE);
  const [createNotification] = useMutation(CREATE_NOTIFICATION);

  const openConfirm = async () => {
    if (openDelete) openDelete(beat.id);
  };

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState({
      ...state,
      updatedName: (event.target as HTMLInputElement).value,
    });
  };

  const handleCloseDelete = (): void | undefined => {
    if (closeDelete) closeDelete();
  };

  const handleUpdateName = async (event: React.MouseEvent<HTMLButtonElement | HTMLDivElement, MouseEvent>) => {
    const { id } = (event.target as HTMLInputElement);

    setState({
      ...state,
      renaming: !state.renaming,
    });

    if (state.renaming && state.updatedName !== "") {
      await renameBeat({
        variables: {
          id: parseInt(id, 10),
          name: state.updatedName,
          folderId: beat.folderId,
        },
      }).then(() => {
        setState({ ...state, beatName: state.updatedName });
        dispatch(
          sendAlert({
            message: "Beat renamed successfully.",
            duration: 10000,
            open: true,
          }),
        );
      });
    }
  };

  const handleShareBeat = async () => {
    const response = await shareBeat({
      variables: {
        beatId: beat.id,
      },
    });

    if (response?.data?.shareBeat.ok) {
      setState({ ...state, shared: true, alert: true });
      dispatch(
        sendAlert({
          message: "Beat shared succesfully with your teacher.",
          duration: 10000,
          open: true,
        }),
      );
    }

    await createNotification({
      variables: {
        messageType: "SHARED_BEAT",
        type: "teacher", // indicates the type of target account
        senderName: `${firstName} ${lastName}`,
        senderId: userId, // userId of sender
        payloadName: beat.name,
        payloadType: "beat",
        payloadId: beat.id,
      },
    });
  };

  const handleRemoveSharedBeat = async () => {
    const response = await removeSharedBeat({
      variables: {
        beatId: beat.id,
      },
    });

    if (response?.data?.removeSharedBeat.ok) {
      setState({
        ...state, shared: false, alert: true, rendered: false,
      });
      dispatch(
        sendAlert({
          message: "Beat succesfully removed from your shared beats.",
          duration: 10000,
          open: true,
        }),
      );
    }
  };

  const handleDelete = async () => {
    await deleteBeat({
      variables: {
        id: beat.id,
        folderId: beat.folderId,
        name: beat.name,
      },

      update: (cache, { data: { deleteBeat: deleteBeatResponse } }) => {
        const { ok, beat: updatedBeat } = deleteBeatResponse;

        if (!ok) {
          return;
        }

        if (stock) {
          const data = _.cloneDeep<{stockBeats: beatType[] } | null>(
            cache.readQuery({
              query: STOCK_BEATS,
            }),
          );
          cache.writeQuery({
            query: STOCK_BEATS,
            data: {
              stockBeats: data?.stockBeats.filter((b: beatType) => updatedBeat.id !== b.id),
            },
          });
        } else {
          const data = _.cloneDeep<{ allBeats: beatType[] } | null>(
            cache.readQuery({
              query: ALL_BEATS,
              variables: { includeStock: false },
            }),
          );
          cache.writeQuery({
            query: ALL_BEATS,
            variables: { includeStock: false },
            data: {
              allBeats: data?.allBeats.filter((b: beatType) => updatedBeat.id !== b.id),
            },
          });
        }
      },
    });

    if (closeDelete) closeDelete();
    dispatch(
      sendAlert({
        message: "Beat deleted succesfully.",
        duration: 10000,
        open: true,
      }),
    );
  };

  let displayedBeat = state.beatName;
  if (displayedBeat.length > 25) {
    displayedBeat = `${displayedBeat.substring(0, 25)}...`;
  }

  const beatToRender = state.rendered ? (
    <div className={styles.container}>
      <button
        aria-label="loadBeatButton"
        type="button"
        className={styles.background}
        onClick={async () => {
          const { data } = await client.query({
            query: GET,
            variables: { id: beat.id },
          });

          dispatch(loadBeat({ ...data.getBeat.beat, notes: JSON.parse(data.getBeat.beat.notes) }));
        }}
      />
      {(hideDropdown || (!isAdmin && stock)) || (
        <div className={styles.dropdown}>
          <Dropdown icon="angle down" right>
            <Dropdown.Menu style={{ left: "auto", right: 0, top: 0 }}>
              {(type === 0 || (type === 1 && !beat.shared)) && (
                <Dropdown.Item icon="pencil" text="Rename" onClick={handleUpdateName} name={beat.name} id={beat.id} />
              )}
              {(type === 0 || (type === 1 && !beat.shared)) && (
                <Dropdown.Item icon="trash" error text="Delete beat" onClick={openConfirm} />
              )}
              {type === 0 && !state.shared && !stock && (
                <Dropdown.Item name="share" text="Send to Teacher" onClick={handleShareBeat} />
              )}
              {type === 1 && beat.shared && (
                <Dropdown.Item
                  icon="remove user"
                  name="remove"
                  text="Remove from Shared"
                  onClick={handleRemoveSharedBeat}
                />
              )}
            </Dropdown.Menu>
          </Dropdown>
        </div>
      )}

      <Icon name="music" color="olive" className={styles.icon} />

      {state.renaming && (
        <div className={styles.renameInputContainer}>
          <input
            type="text"
            placeholder={beat.name}
            value={state.updatedName}
            onChange={handleNameChange}
            className={styles.renameInput}
            // eslint-disable-next-line
            autoFocus
          />
        </div>
      )}

      {state.renaming && (
        <div className={styles.renameButton}>
          <Button
            className="ui opaque compact button"
            size="mini"
            name={beat.name}
            id={beat.id}
            onClick={handleUpdateName}
          >
            {state.renaming && "confirm"}
          </Button>
        </div>
      )}

      {!hideDropdown && (
        <Confirm
          open={confirmOpen}
          content="Are you sure you want to delete this beat? This cannot be undone."
          confirmButton="Delete"
          onCancel={handleCloseDelete}
          onConfirm={handleDelete}
          style={{ width: "450px" }}
        />
      )}

      <div className={styles.title}>
        <p>{displayedBeat}</p>
      </div>
    </div>
  ) : null;

  // make stock beats non-draggable, user beats draggable, unless you are admin
  if (stock && !isAdmin) {
    return beatToRender;
  }

  return (
    <div ref={drag}>
      {beatToRender}
    </div>
  );
};

Beat.defaultProps = {
  closeDelete: () => {},
  confirmOpen: false,
  handleDrop: () => {},
  hideDropdown: false,
  isAdmin: false,
  openDelete: () => {},
  stock: false,
};

export default Beat;
