import gql from "graphql-tag";

const VALIDATE_SIGNUP_TOKEN = gql`
  query($signupToken: String!) {
    validateSignupToken(signupToken: $signupToken) {
      ok
      studentId
    }
  }
`;

export default VALIDATE_SIGNUP_TOKEN;
