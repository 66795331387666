import gql from "graphql-tag";

const ALL_FOR_STUDENT = gql`
  query($studentId: Int!) {
    allStudentAssignments(studentId: $studentId) {
      ok
      assignments {
        id
        content
        beats {
          id
          name
          folderId
          shared
        }
        title
        createdAt
      }
    }
  }
`;

export default ALL_FOR_STUDENT;
