import { BrowserRouter, Switch, useLocation } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { useAppDispatch } from "store/hooks";
import { ErrorBoundary } from "react-error-boundary";
import { useRollbar } from "@rollbar/react";
import { HTML5Backend } from "react-dnd-html5-backend";
import { DndProvider } from "react-dnd";

import Login from "screens/Login";
import MyStudents from "screens/MyStudents";
import Register from "screens/Register";
import Reset from "screens/Reset";
import ResetForm from "screens/ResetPassword";
import UserAgreement from "screens/UserAgreement";
import Validate from "screens/Validate";
import PrivacyPolicy from "screens/PrivacyPolicy";
import Settings from "screens/Settings";

import GET_USER from "graphql/user/queries/getUser";
import { setUser } from "store/user/slice";
import isValidated from "utils/isValidated";
import isAuthenticated from "utils/isAuthenticated";
import ErrorFallback from "components/ErrorFallback";
import { RootState } from "store";
import withReadOnly from "components/withReadOnly";
import Main from "screens/Main";
import usePageTracking from "hooks/usePageTracking";
import InactiveSubscription from "screens/Login/InactiveSubscription";
import Route from "./PrivateRoute";

const onError = ({ error }: any) => {
  const rollbar = useRollbar();

  rollbar.error(error);

  return <ErrorFallback message={error.message} />;
};

const TrackedRoutes = () => {
  const location = useLocation();

  usePageTracking();

  return (
    <>
      <Route hasAccess={() => !isValidated()} fallbackPath="/login" path="/validate" component={Validate} />
      <Route hasAccess={() => true} fallbackPath="/login" path="/beat" exact component={withReadOnly(Main)} />
      <Route hasAccess={() => isAuthenticated()} fallbackPath="/login" path="/" exact component={Main} />
      <Route hasAccess={() => isAuthenticated()} fallbackPath="/login" path="/settings" exact component={Settings} />
      <Route hasAccess={() => isAuthenticated()} fallbackPath="/login" path="/my-students" component={MyStudents} />
      <Route hasAccess={isValidated} fallbackPath="/validate" path="/login" component={Login} />
      <Route
        hasAccess={() => true}
        fallbackPath="/login"
        path="/inactive-subscription"
        component={InactiveSubscription}
      />
      <Route
        component={Register}
        fallbackPath="/validate"
        hasAccess={() => !!location.search.slice(1)}
        path="/register"
      />
      <Route hasAccess={isValidated} fallbackPath="/validate" path="/reset-password" component={ResetForm} />
      <Route hasAccess={isValidated} fallbackPath="/validate" path="/reset" component={Reset} />
      <Route path="/privacy-policy" component={PrivacyPolicy} />
      <Route path="/user-agreement" component={UserAgreement} />
    </>
  );
};

const Routes = () => {
  interface GetUserData {
    getUser: RootState["user"];
  }

  const dispatch = useAppDispatch();
  const { data, loading } = useQuery<GetUserData>(GET_USER, { fetchPolicy: "network-only" });
  const { getUser: user } = data || {};

  if (user?.id && !loading) dispatch(setUser(user));

  return (
    <DndProvider backend={HTML5Backend}>
      <BrowserRouter>
        <ErrorBoundary FallbackComponent={onError}>
          <Switch>
            <TrackedRoutes />
          </Switch>
        </ErrorBoundary>
      </BrowserRouter>
    </DndProvider>
  );
};

export default Routes;
