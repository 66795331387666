import { useAppSelector } from "store/hooks";
import getUser from "store/user/selectors";
import Assignment from "./Assignment";
import PrintBeat from "./PrintBeat";
import Save from "./Save";
import Share from "./Share";

import styles from "./styles.module.scss";

const CurrentBeatSharingActions = () => {
  const { type, isAdmin } = useAppSelector(getUser);

  return (
    <div className={styles.container}>
      <Save isStock={false} />
      {isAdmin && <Save isStock />}
      {type === 1 && <Assignment />}
      <Share />
      <PrintBeat />
    </div>
  );
};

export default CurrentBeatSharingActions;
