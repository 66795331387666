import gql from "graphql-tag";

const CREATE = gql`
  mutation(
    $name: String!
    $notes: String!
    $folderId: Int
    $bpm: Int
    $subdivision: Boolean
    $sticking: Boolean
    $counting: Boolean
    $metronome: Boolean
    $grid: Boolean
    $color: Boolean
    $stock: Boolean
    $comments: String
    $swing: Float
  ) {
    createBeat(
      name: $name
      notes: $notes
      folderId: $folderId
      bpm: $bpm
      subdivision: $subdivision
      sticking: $sticking
      counting: $counting
      metronome: $metronome
      grid: $grid
      color: $color
      stock: $stock
      comments: $comments
      swing: $swing
    ) {
      ok
      beat {
        bpm
        color
        comments
        counting
        folderId
        grid
        id
        metronome
        name
        notes
        shared
        sticking
        subdivision
        swing
        uuid
      }
      errors {
        path
        message
      }
    }
  }
`;

export default CREATE;
