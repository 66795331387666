import gql from "graphql-tag";

const RESEND_VALIDATE_EMAIL = gql`
  mutation($validateToken: String, $email: String) {
    resendValidateEmail(validateToken: $validateToken, email: $email) {
      ok
      validateToken
    }
  }
`;

export default RESEND_VALIDATE_EMAIL;
