import Header from "components/Header";
import Box from "@material-ui/core/Box";
import styles from "./styles.module.scss";

const PrivacyPolicy = () => (
  <>
    <Header />

    <Box mx={6} my={4}>
      <h1>Privacy Policy</h1>

      <p>
        <strong>Effective Date: 1/1/2022</strong>
      </p>

      <p>
        The materials on the Drumroom software (“Drumroom”) and the Drumroom website are provided by Drumroom, Inc.
        (hereinafter “us” or “we”). The Drumroom website at
        {" "}
        <a href="https://www.drumroom.io">https://www.drumroom.io</a>
        {" "}
        (including all content under the “drumroom.io” and
        “drumroom.net” domain names) are owned by us and is referred to herein as the “website.” The website, Drumroom,
        and the associated products, services, data, information, updates, and materials that we provide are referred
        herein and in the Terms as the “services.” This notice (“Privacy Policy”) is part of and supplements our Terms
        of Service (the “Terms”). This Privacy Policy explains our online information collection and use practices, and
        the choices you can make about the way we use such information. It is important that you take the time to read
        and understand this Privacy Policy so that you can appreciate how we use your personal information. REMEMBER -
        IF YOU ACCESS OR USE THE SERVICES, YOU AGREE TO THIS PRIVACY POLICY. AS WE UPDATE OR EXPAND OUR SERVICES, WE MAY
        CHANGE THIS PRIVACY POLICY UPON NOTICE TO YOU IF WE HAVE YOUR EMAIL ADDRESS. HOWEVER, PLEASE REVIEW IT FROM TIME
        TO TIME.
      </p>

      <h1>
        1.
        {" "}
        <strong>Important Notices</strong>
      </h1>

      <p>
        <strong>1.1</strong>
        {" "}
        <span className={styles.underlined}>EU Privacy Rights.</span>
        {" "}
        For users that are citizens
        of the European Union (“EU”), this Privacy Policy is subject to the provisions of the General Data Protection
        Regulation (“GDPR”) and other applicable privacy laws. In such case, we are a data “Controller” and you are a
        “Data Subject” with certain protected privacy rights concerning your “Personal Data.” We will take commercially
        reasonable steps to maintain compliance with GDPR requirements. Your Personal Data may identify you as a person,
        and thus is often referred to as Personally Identifiable Information (“PII””).
      </p>

      <p>
        <strong>1.2</strong>
        {" "}
        <span className={styles.underlined}>California Privacy Rights.</span>
        {" "}
        The California
        Consumer Privacy Act (“CCPA”) of 2018 enhances privacy rights and consumer protection for residents of
        California. Under the CCPA, California residents have the rights to: 1) know what Personal Data are being
        collected about them; 2) know whether their Personal Data are sold or disclosed, and to whom; 3) say
        &apos;no&apos; to the sale of their Personal Data; 4) access their Personal Data; and 5) not be discriminated
        against for exercising their privacy rights under the CCPA. California law allows California residents to
        request information regarding our disclosures in the prior calendar year, if any, of their PII to third parties.
        {" "}
        <strong>
          To make such a request, please contact us as indicated in the “Contact” section at the bottom of this Privacy
          Policy.
        </strong>
        {" "}
        Please include enough detail for us to locate your account; at a minimum, your or your child&apos;s name, email
        address and username, if any. We will attempt to verify your identity and provide you with the requested
        information within thirty (30) days of receipt. We reserve our right not to respond to requests sent more than
        once in a calendar year, or requests submitted via methods other than the ones posted in this notice. Please
        note that this law does not cover all information sharing. Our disclosure only includes information covered by
        the law.
      </p>

      <h1>
        2.
        {" "}
        <strong>Information Collection</strong>
      </h1>

      <p>
        We may collect information from you through the services, and we are responsible for the protection of your
        information that we collect from you. When you use Drumroom, we will not collect any PII about you unless you
        choose to provide such information to us.
      </p>

      <p>
        <strong>2.1</strong>
        {" "}
        <span className={styles.underlined}>Requested Information</span>
        . On various pages of the
        services, we may request specific PII about you to register you for an account, add you to our email list,
        facilitate your payments for services or subscriptions, or fulfill your requests for information. You may choose
        not to provide your PII, but then you might not be able to take advantage of some of the features of our
        services. We only collect basic Personal Data from you that does
        {" "}
        <span className={styles.underlined}>not</span>
        {" "}
        include any
        {" "}
        <em>special types of information</em>
        {" "}
        (e.g., biometric or health-related). The types of Personal
        Data we collect and save include:
      </p>

      <ul>
        <li>
          <blockquote>
            <p>
              Contact and account information such as account user&apos;s name, the account creator&apos;s email
              address, physical billing address
            </p>
          </blockquote>
        </li>
        <li>
          <blockquote>
            <p>Financial information such as name, credit card number, CVV code, date of expiration;</p>
          </blockquote>
        </li>
        <li>
          <blockquote>
            <p>Information you provide such as feedback, comments, or other messages; and</p>
          </blockquote>
        </li>
        <li>
          <blockquote>
            <p>
              Device information such as the mobile phone provider associated with the device you are using to access
              the website or services, the type of device and its operating system, calls and messages placed through
              the website or services, how pages or features of the services are used, and similar analytics about use
              of the website or services.
            </p>
          </blockquote>
        </li>
      </ul>

      <p>
        <strong>2.2</strong>
        {" "}
        <span className={styles.underlined}>Financial Information.</span>
        {" "}
        All personal financial
        information required to complete your purchase will be securely collected for purposes of processing payments
        and completing transactions. In some cases, we may securely provide this personal financial information to a
        third-party vendor who provides us with payment processing services.
      </p>

      <p>
        <strong>2.3</strong>
        {" "}
        <span className={styles.underlined}>Information Retained</span>
        . The services retain and
        store documents created or edited, and saved during use of Drumroom. We collect this solely to make it available
        to you as a benefit of your account. We do not share this information with any third parties, except in the
        circumstances described in the Disclosure and Sharing section, below.
      </p>

      <p>
        <strong>2.4</strong>
        {" "}
        <span className={styles.underlined}>Tracking and Aggregated Information</span>
        . We may
        automatically and through third-party tracking services (e.g., Google Analytics) gather certain non-PII about
        your use of our services and store it in log files. This information may include browser type, operating system,
        date/time stamps, and related data. We use this information, which does not identify individual users, solely to
        improve the quality of our products and services. Out of respect for your privacy, we do not link this
        automatically-collected data to PII.
      </p>

      <h1>
        3.
        {" "}
        <strong>Information Use</strong>
      </h1>

      <p>
        <strong>3.1</strong>
        {" "}
        <span className={styles.underlined}>Use of Personal Data.</span>
        {" "}
        We use the Personal Data
        you provide for the purposes for which you have submitted it, including:
      </p>

      <ul>
        <li>
          <blockquote>
            <p>
              <strong>Creating and Maintaining Your User Account.</strong>
              {" "}
              We use your PII to create and maintain an
              account for Drumroom for you to allow you to purchase and use the services.
            </p>
          </blockquote>
        </li>
        <li>
          <blockquote>
            <p>
              <strong>Responding To Your Inquiries and Fulfilling Your Requests.</strong>
              {" "}
              We may use your PII to respond
              to your inquiries and to fulfill your requests for information.
            </p>
          </blockquote>
        </li>
        <li>
          <blockquote>
            <p>
              <strong>Communicating With You About Our Services.</strong>
              {" "}
              We may use your PII to send you marketing
              information about new or updated services, and other items that may be of interest to you.
            </p>
          </blockquote>
        </li>
        <li>
          <blockquote>
            <p>
              <strong>Improving Our Services.</strong>
              {" "}
              We may use your information to make our services more stable and
              user-friendly, to analyze service issues, and to develop new marketing programs relating to the services.
            </p>
          </blockquote>
        </li>
        <li>
          <blockquote>
            <p>
              <strong>Sending Administrative Emails.</strong>
              {" "}
              We may use your PII to send you emails to: (a) confirm
              your account and other provided PII, (b) process transactions that you make using our services, (c)
              provide you with information regarding the services, or (d) inform you of changes to this Privacy Policy,
              our Terms, or any other policies.
            </p>
          </blockquote>
        </li>
      </ul>

      <p>
        <strong>3.2</strong>
        {" "}
        <span className={styles.underlined}>Use of Anonymous Data.</span>
        {" "}
        We may use anonymous
        information that we collect to improve the design and content of our website, and to enable us to personalize
        your Internet experience. We also may use this information in the aggregate to analyze how our website is used,
        analyze industry trends, as well as to offer you programs or services.
      </p>

      <h1>
        4.
        {" "}
        <strong>Disclosure and Sharing</strong>
      </h1>

      <p>
        <strong>4.1</strong>
        {" "}
        <span className={styles.underlined}>Sharing Policy.</span>
        {" "}
        In general, we will not share
        your Personal Data
        {" "}
        <span className={styles.underlined}>except</span>
        : (a) for the purposes for which you
        provided it; (b) with your consent; (c) as may be required by law (e.g., in response to a court order or
        subpoena, or in response to a law enforcement agency request); (d) as we think necessary to protect our
        organization or others from injury (e.g., when we believe that someone is causing, or is about to cause, injury
        or interference with the rights or property of another); or (e) on a confidential basis with persons or
        organizations with whom we contract to carry out internal site operations or as necessary to render the
        services.
      </p>

      <p>
        <strong>4.2</strong>
        {" "}
        <em>
          *Although it is not our practice to sell your Personal Data, the CCPA further restricts us from selling your
          Personal Data if you click the “Do Not Sell My Personal Information” link on the website. *
        </em>
      </p>

      <p>
        <strong>4.3</strong>
        {" "}
        <span className={styles.underlined}>Successor in Interest.</span>
        {" "}
        We may transfer your
        Personal Data to our successor-in-interest in the event of an acquisition, sale, merger, or bankruptcy. In the
        event we go through a business transition, such as a merger, acquisition by another company, or sale of all or a
        portion of its assets, any PII we have on record will likely be among the interests transferred. The transferred
        PII will remain subject to the provisions of this Privacy Policy, as it is updated from time to time.
      </p>

      <h1>
        <strong>5. Access and Control of Account Creator&apos;s Personal Data</strong>
      </h1>

      <p>
        <strong>5.1</strong>
        {" "}
        <span className={styles.underlined}>Access and Control</span>
        {" "}
        After registering for an
        account for you, or your child, you may log-in to the account and edit your Personal Data in your profile [if
        not available, should be added]. For instructions on how you can further access your Personal Data that we have
        collected, or how to correct errors in such information, please contact us as indicated in the “Contact” section
        at the bottom of this Privacy Policy. We will promptly stop using your information and remove it from our
        servers and databases at any time upon your request. To protect your privacy and security, we will take
        reasonable steps to help verify your identity prior to granting access, making corrections, or removing your
        information.
      </p>

      <p>
        <strong>5.2</strong>
        {" "}
        <span className={styles.underlined}>EU Citizens</span>
        {" "}
        If you are an EU citizen and believe
        that we have misused your Personal Data, you have the right to contact the European Data Protection Supervisor
        (“EDPS”) to ask the EDPS for advice on how to exercise your rights or to ask the EDPS to investigate a
        complaint. For more information, see
        {" "}
        <a href="https://edps.europa.eu/data-protection/our-role-supervisor/complaints_en">EDPS Complaints</a>
        .
      </p>

      <p>
        <strong>5.3</strong>
        {" "}
        <span className={styles.underlined}>Access to Login Information</span>
        . Should you forget
        your account login information, you may request that we send it to the email address associated with your
        account. To prevent unauthorized disclosure of and access to your account, you should always keep your account
        information secure.
      </p>

      <h1>
        <strong>6. Children and Young People&apos;s Personal Information</strong>
      </h1>

      <p>
        <strong>6.1</strong>
        {" "}
        <span className={styles.underlined}>Required Information</span>
        . We will not request
        additional Personal Information from an account creator or account user after the initial account creation stage
        unless reasonably necessary to respond to a service or information request by the account creator, or there is
        an issue with payment under the billing policies defined in the Terms.
      </p>

      <p>
        <strong>6.2</strong>
        {" "}
        <span className={styles.underlined}>Compliance with COPPA.</span>
        {" "}
        Drumroom, Inc.&apos;s
        services are intended to comply with COPPA. If you reside in the United States, we encourage you to visit
        {" "}
        <a href="http://www.ftc.gov/bcp/edu/pubs/consumker/tech/tec08.shtm">
          http://www.ftc.gov/bcp/edu/pubs/consumker/tech/tec08.shtm
        </a>
        {" "}
        for more information about COPPA and children&apos;s privacy. If we become aware that we have collected
        COPPA-restricted Personal Information from any child other than as permitted under law, we will dispose of the
        information, or otherwise treat it in accordance with COPPA and other applicable laws and regulations. If you,
        as the child&apos;s parent or guardian, believe that your child under thirteen years of age (13) provided us
        with Personal Information, as defined by COPPA, without COPPA&apos;s required consent, please contact us as
        indicated in the “Contact” section at the bottom of this Privacy Policy.
      </p>

      <p>
        Should a child under sixteen years of age (16) post content or information on the services, the user can request
        that the content or information be removed from public display. This removal can be done by contacting us as
        indicated in the “Contact” section at the bottom of this Privacy Policy, making such a request, and stating that
        they personally posted the content or information and where the information is posted. We will make reasonable,
        good faith efforts to either anonymize the information or remove the content.
      </p>

      <p>
        <strong>6.3</strong>
        {" "}
        <span className={styles.underlined}>Maintaining Child&apos;s Personal Information</span>
        {" "}
        You are responsible for maintaining the information that you submit to us. You may review, correct, update, or
        remove a child&apos;s Personal Information as outlined prior in section 5.1. If you edit or request deletion of
        any Personal Data, information may persist internally for Drumroom, Inc.&apos;s administrative purposes as
        permitted or required by applicable law.
      </p>

      <p>
        <strong>6.4</strong>
        {" "}
        <span className={styles.underlined}>Interaction with Other Users.</span>
        {" "}
        We have made
        efforts to design Drumroom to limit your child&apos;s interaction with other users. Users with a “teacher”
        profile (the “Teacher”) may send a pairing request to users with a “student” profile (aka your child). There is
        no interaction between users unless a pairing request has been approved. Once you have authorized a pairing
        between your child and the Teacher, the Teacher may send text and drumbeat transcriptions to your child, and
        your child may send drumbeat transcriptions to the Teacher. Please supervise your child&apos;s use of Drumroom
        and the website and teach them about protecting themselves and their Personal Information online.
      </p>

      <h1>
        7.
        {" "}
        <strong>Notification Options</strong>
      </h1>

      <p>
        <strong>7.1</strong>
        {" "}
        <span className={styles.underlined}>Notifications and Alerts.</span>
        {" "}
        If you choose to
        create a Drumroom account and/or purchase the services, we will send you a welcome email confirming the creation
        of a Drumroom account that includes information about your subscription plan and your account. We will also
        notify you via email upon any changes to this privacy policy, or changes to your account.
      </p>

      <p>
        <strong>7.2</strong>
        {" "}
        <span className={styles.underlined}>Notification Opt-In.</span>
        {" "}
        We offer you the ability to
        opt-in to receive notifications about new or upcoming products or services, and promotional materials and offers
        from us as well as from our subsidiaries, marketing partners, advertisers, and other third parties. We may offer
        these notifications via email. Out of respect for your privacy, you may opt-out of receiving these notifications
        as instructed in each email.
      </p>

      <p>
        <strong>7.3</strong>
        {" "}
        <span className={styles.underlined}>Email Notification Opt-Out</span>
        . If you no longer
        wish to receive
        {" "}
        <em>email notifications</em>
        , you may opt-out of receiving them as instructed in each email. Be
        advised that you may not opt-out of receiving administrative email notifications from us related to technical
        support or customer service requests, billing and subscription issues, or changes to the services&apos; Terms or
        Privacy Policy.
      </p>

      <p>
        <strong>7.4</strong>
        {" "}
        <span className={styles.underlined}>Browser Notification Opt-Out.</span>
        {" "}
        If you no longer
        wish to receive
        {" "}
        <em>browser notifications</em>
        , you may opt-out of receiving them by using the instructions at
        the following links:
      </p>

      <ul>
        <li>
          <blockquote>
            <p>
              Google Chrome/Android Phone users:&nbsp;
              <a href="https://support.google.com/chrome/answer/3220216?co=GENIE.Platform%3DDesktop&amp;hl=en">
                https://support.google.com/chrome/answer/3220216?co=GENIE.Platform%3DDesktop&amp;hl=en
              </a>
            </p>
          </blockquote>
        </li>
        <li>
          <blockquote>
            <p>
              Microsoft Edge users:&nbsp;
              <a href="https://windowscentral.com/how-manage-web-notifications-edge-windows-10">
                https://windowscentral.com/how-manage-web-notifications-edge-windows-10
              </a>
            </p>
          </blockquote>
        </li>
        <li>
          <blockquote>
            <p>
              Firefox users:&nbsp;
              {/* eslint-disable max-len */}
              <a href="https://sendpulse.com/knowledge-base/push-notifications/general/enable-disable-push-notifications-mozilla-firefox">
                https://sendpulse.com/knowledge-base/push-notifications/general/enable-disable-push-notifications-mozilla-firefox
              </a>
              {/* eslint-disable max-len */}
            </p>
          </blockquote>
        </li>
        <li>
          <blockquote>
            <p>
              Opera users:&nbsp;
              <a href="https://davescomputertips.com/how-to-manage-desktop-notifications-in-opera/">
                https://davescomputertips.com/how-to-manage-desktop-notifications-in-opera/
              </a>
            </p>
          </blockquote>
        </li>
        <li>
          <blockquote>
            <p>
              Safari users:&nbsp;
              <a href="https://support.apple.com/guide/safari/manage-website-notifications-sfri40734/mac">
                https://support.apple.com/guide/safari/manage-website-notifications-sfri40734/mac
              </a>
            </p>
          </blockquote>
        </li>
        <li>
          <blockquote>
            <p>
              Additional information:&nbsp;
              <a href="https://www.makeuseof.com/tag/disable-notifications-chrome-firefox-safari/">
                https://www.makeuseof.com/tag/disable-notifications-chrome-firefox-safari/
              </a>
            </p>
          </blockquote>
        </li>
      </ul>

      <h1>
        <strong>8.</strong>
        {" "}
        <strong>Cookies and Other Network Technologies</strong>
      </h1>

      <p>
        <strong>8.1</strong>
        {" "}
        <span className={styles.underlined}>Cookies.</span>
        {" "}
        To enhance your online experience with
        us, our services may presently or in the future use “cookies.” A cookie is a small text file that is stored on a
        user&apos;s computer for record-keeping purposes. We use session ID cookies to make it easier for you to use the
        services. A Session ID cookie expires when you close your browser. You are free to decline to accept cookies,
        but by doing so, you may not be able to take full advantage of all our services. We do not link the information
        we store in cookies to any PII you may submit while using our services.
      </p>

      <p>
        <strong>8.2</strong>
        {" "}
        <span className={styles.underlined}>LocalStorage.</span>
        {" "}
        To enhance your online experience with us, our services presently use “LocalStorage.” LocalStorage is a text
        file that is stored on a user&apos;s computer for record-keeping purposes that does not contain any Personal
        Information. We use LocalStorage to make it easier for you to use the services. The information collected and
        retained by LocalStorage will be deleted when the user either logs out of their Drumroom account or deletes
        their Drumroom account. Any data stored by LocalStorage is not deleted by Drumroom until the user either logs
        out of Drumroom or deletes their user account.
      </p>

      <p>
        <strong>8.3</strong>
        {" "}
        <span className={styles.underlined}>Clickstreams</span>
        . As you use the Internet, you leave
        a trail of electronic information at each website you visit. This information, which is sometimes referred to as
        “clickstream data,” can be collected and stored by a website&apos;s server. Clickstream data can reveal the type
        of computer and browsing software you use and the address of the website from which you linked to the website.
        We may use clickstream data of our website as a form of non-PII to determine how much time visitors spend on
        each page of our website, how visitors navigate through the website, and how we may tailor our website to better
        meet our visitor&apos;s needs. We will only use this information to improve our website.
      </p>

      <p>
        <strong>8.4</strong>
        {" "}
        <span className={styles.underlined}>Do Not Track</span>
        . Many modern web browsers give you
        the option to send a “Do Not Track” signal to the websites you visit, indicating that you do not wish to be
        tracked. Currently, the website and services respond to “Do Not Track” signals.
      </p>

      <p>
        <strong>8.5</strong>
        {" "}
        <span className={styles.underlined}>Advertising</span>
        . Drumroom, Inc. may use third party
        advertising servers, advertising network providers, or advertisers (collectively “Ad Providers”). We are very
        selective of the Ad Providers we work with and only intend to authorize Ad Providers that (i) do not use
        Drumroom or the website to associate tracking cookies with our users, or (ii) target online behavioral
        advertising to our users while using the website or Drumroom. Third party advertising services will not have
        access to the Personal Data we collect from you. Drumroom, Inc. makes good faith efforts to monitor its Ad
        Providers for continued compliance with the above practices and is legally entitled to terminate its
        relationship with any Ad Provider should the provider change its approach to Personal Information, and/or
        provide targeted advertising in a manner inconsistent with these restrictions. We make efforts to keep all
        advertisements appropriate, in our opinion, to our audience. Further, we may offer parents options to limit ads,
        such as ad blocking.
      </p>

      <p>
        Drumroom may use unique identifiers such as cookies, web beacons, or similar technologies to gather user data
        and may allow others to do so in connection with the Website. Specifically, Drumroom allows the use of cookies
        for ad campaign analytics and frequency capping, and for segregating Website users from profiling for online
        behavioral advertising purposes. Drumroom may also use web beacons to track the effectiveness of a particular
        advertising campaign. Third party use of cookies and web beacons are subject to their own privacy policies.
      </p>

      <p>
        <strong>8.6</strong>
        {" "}
        <span className={styles.underlined}>User Behavior Tracking</span>
        . We may track the pages,
        links, and functions our users visit within our services, to determine what services are most popular. We may
        also collect user data to track the areas of the services where users most frequently move the mouse or click.
        These tracking activities make it possible to monitor and analyze web traffic and evaluate user behavior to
        customize our services.
      </p>

      <h1>
        <strong>9. Information Security and Storage</strong>
      </h1>

      <p>
        <strong>9.1</strong>
        {" "}
        <span className={styles.underlined}>Security.</span>
        {" "}
        After receiving your Personal Data, we
        will store it on our website systems for future use. We have physical, electronic, and managerial procedures in
        place to safeguard and help prevent unauthorized access maintain data security, and correctly use the
        information we collect. Unfortunately, no data transmission or data storage solution can ever be completely
        secure. As a result, although we take industry-standard steps to protect your information (e.g., strong
        encryption), we cannot ensure or warrant the security of any information you transmit to or receive from us or
        that we store on our or our service providers&apos; systems.
      </p>

      <p>
        <strong>9.2</strong>
        {" "}
        <span className={styles.underlined}>Server Location.</span>
        {" "}
        If you are visiting the website
        or using Drumroom from outside of the USA, you understand that your connection will be through and to servers
        located in the USA, and the information you provide will be securely stored in our web servers and internal
        systems located within the USA. By accessing or using the services or otherwise providing information to us, you
        consent to the processing, transfer, and storage of information in and to the USA, where you may not have the
        same rights and protections as you do under local law.
      </p>

      <p>
        <strong>9.3</strong>
        {" "}
        <span className={styles.underlined}>Storage.</span>
        {" "}
        We store Personal Data for as long as
        reasonably required for its purpose or for any additional period required by law (if any). Account information
        is deleted when you or when we delete your account. We may store information longer for legitimate business
        reasons (for example, Personal Data may remain in backups for a reasonable period of time), or as legally
        required.
      </p>

      <h1>
        <strong>10. Changes to this Policy</strong>
      </h1>

      <p>
        Because our business needs may change over time, we reserve the right to modify this Privacy Policy. If at any
        time in the future we plan to use your Personal Information in a way that differs from this Privacy Policy, we
        will revise this Privacy Policy as appropriate. In the event of a change to our Privacy Policy, we will email
        the updated Privacy Policy to the email address that you have provided to us. Your continued use of the services
        following our notice of changes to this Privacy Policy means you accept such changes.
      </p>

      <h1>
        <strong>11. Contact Us</strong>
      </h1>

      <p>
        If you have any questions or concerns about these Terms, the website, or Drumroom, please
        {" "}
        <a href="https://drumroom.io/">click here</a>
        {" "}
        to contact us or call us at (978) 462 - 1100 ext. 301.
      </p>
    </Box>
  </>
);

export default PrivacyPolicy;
