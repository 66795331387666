import gql from "graphql-tag";

const REGISTER = gql`
  mutation(
    $firstName: String!
    $lastName: String!
    $email: String!
    $confirmEmail: String!
    $password: String!
    $confirmPassword: String!
    $userType: Int!
    $update: Boolean!
    $teacherId: Int
  ) {
    register(
      firstName: $firstName
      lastName: $lastName
      email: $email
      confirmEmail: $confirmEmail
      password: $password
      confirmPassword: $confirmPassword
      userType: $userType
      update: $update
      teacherId: $teacherId
    ) {
      ok
      validateToken
      id
      errors {
        path
        message
      }
    }
  }
`;

export default REGISTER;
