import { useState } from "react";
import { Anchorme } from "react-anchorme";
import { Button, Modal } from "semantic-ui-react";
import { useLazyQuery } from "@apollo/client";
import ALL_FOR_STUDENT from "graphql/assignment/queries/allForStudent";
import { assignmentType } from "types/assignment";
import { beatType } from "types/beat";
import Beat from "screens/Main/BeatLibrary/Beat";
import MyBeatsFolder from "screens/Main/BeatLibrary/MyBeatsFolder";
import styles from "./styles.module.scss";

interface Props {
  studentId: number;
  firstName: string;
  lastName: string;
}

const AllAssignments = ({ studentId, firstName, lastName }: Props) => {
  const [open, setOpen] = useState(false);
  const [getAssignments, { data }] = useLazyQuery(ALL_FOR_STUDENT, {
    variables: { studentId },
  });

  const handleClick = () => {
    setOpen(true);
    getAssignments();
  };

  const buildBeats = (assignment: assignmentType) => assignment.beats.map((beat: beatType) => (
    <Beat key={beat.name} beat={beat} hideDropdown />
  ));

  return (
    <div className={styles.container}>
      <button type="button" onClick={handleClick} className={styles.link}>
        See all assignments...
      </button>
      <Modal onClose={() => setOpen(false)} onOpen={() => setOpen(true)} open={open}>
        <Modal.Header>
          All Assignments:
          {`${firstName} ${lastName}`}
        </Modal.Header>
        <Modal.Content>
          {data?.allStudentAssignments?.assignments.map((assignment: assignmentType, index: number) => (
            <MyBeatsFolder
              key={assignment.id}
              beatsLength={assignment.beats.length}
              content={(
                <div key={assignment.id}>
                  <div className="StudentAssignmentsBorder">
                    <div className="StudentAssignmentsContent">
                      <p className="StudentAssignmentsAssignmentsTextP">
                        <pre className="StudentAssignmentsAssignmentsText">
                          <Anchorme target="_blank" rel="noreferrer noopener">
                            {assignment.content}
                          </Anchorme>
                        </pre>
                      </p>
                    </div>
                  </div>
                  {buildBeats(assignment)}
                </div>
              )}
              folder={{
                name: new Date(parseInt(assignment.createdAt, 10)).toDateString(),
                id: 1000000 + index,
              }}
            />
          ))}
        </Modal.Content>
        <Modal.Actions>
          <Button color="black" onClick={() => setOpen(false)}>
            Close
          </Button>
        </Modal.Actions>
      </Modal>
    </div>
  );
};

export default AllAssignments;
