/* eslint-disable max-len */
import { FullProps } from "./baseProps";

const Snare = ({
  colored, index, note, onClick, opacity,
}: FullProps) => {
  const fill = colored ? "#db3939" : "#00000";

  return (
    <g>
      {note.includes("n") && !note.includes("r") && (
        <path
          d="m67.22146,67c0.36,-0.03 1.2,0 1.53,0.06c1.17,0.24 1.89,0.84 2.16,1.83c0.06,0.18 0.06,0.3 0.06,0.66c0,0.45 0,0.63 -0.15,1.08c-0.66,2.04 -3.06,3.93 -5.52,4.38c-0.54,0.09 -1.44,0.09 -1.83,0.03c-1.23,-0.27 -1.98,-0.87 -2.25,-1.86c-0.06,-0.18 -0.06,-0.3 -0.06,-0.66c0,-0.45 0,-0.63 0.15,-1.08c0.24,-0.78 0.75,-1.53 1.44,-2.22c1.2,-1.2 2.85,-2.01 4.47,-2.22zm3.72,-23.075l0,26.35l-0.6,0l0,-41l0.6,0z"
          fill={fill}
          fillOpacity={opacity}
          id={`snare_${index}`}
          onClick={onClick}
          transform={`translate(${30 * index}, -6)`}
        />
      )}

      {note.includes("a") && (
        <g stroke="null" id="layer1" transform={`translate(${30 * index}, 0)`}>
          <path
            d="m72.2258,16l-7.80645,3.28693l0,-1.47912l6.16299,-2.54737l-6.16299,-2.54737l0,-1.47912l7.80645,3.28693l0,1.47912z"
            fill="#000000"
            id={`accent_${index}`}
          />
        </g>
      )}

      {note.includes("r") && (
        <path
          d="m61.8,35.075c0.12,-0.03 0.24,-0.03 0.36,0.03c0.03,0.03 0.93,0.72 1.95,1.56l1.86,1.5l1.86,-1.5c1.02,-0.84 1.92,-1.53 1.95,-1.56c0.21,-0.12 0.33,-0.09 0.75,0.24c0.3,0.27 0.36,0.36 0.36,0.54c0,0.03 -0.03,0.12 -0.06,0.18c-0.03,0.06 -0.9,0.75 -1.89,1.56l-1.8,1.47c0,0.03 0.81,0.69 1.8,1.5c0.99,0.81 1.86,1.5 1.89,1.56c0.03,0.06 0.06,0.15 0.06,0.18c0,0.18 -0.06,0.27 -0.36,0.54c-0.42,0.33 -0.54,0.36 -0.75,0.24c-0.03,-0.03 -0.93,-0.72 -1.95,-1.56l-1.86,-1.5l-1.86,1.5c-1.02,0.84 -1.92,1.53 -1.95,1.56c-0.21,0.12 -0.33,0.09 -0.75,-0.24c-0.3,-0.27 -0.36,-0.36 -0.36,-0.54c0,-0.03 0.03,-0.12 0.06,-0.18c0.03,-0.06 0.9,-0.75 1.89,-1.56l1.8,-1.47c0,-0.03 -0.81,-0.69 -1.8,-1.5c-0.99,-0.81 -1.86,-1.5 -1.89,-1.56c-0.06,-0.12 -0.09,-0.21 -0.03,-0.36c0.03,-0.09 0.57,-0.57 0.72,-0.63zm9.063,-37.075l0,39.35l-0.6,0l0,-39.35l0.6,0z"
          fill={fill}
          fillOpacity={opacity}
          id={`snare_${index}`}
          onClick={onClick}
          transform={`translate(${30 * index}, 26)`}
        />
      )}

      {note.includes("g") && (
        <g>
          <path
            d="m73.341,86.46194c0.57447,-0.89362 1.2766,-1.85107 1.65958,-2.87235c0.38298,-1.08511 0.57447,-2.10639 0.57447,-3.12767c0,-1.02128 -0.19149,-2.04256 -0.57447,-3.12767c-0.38298,-1.02128 -1.08511,-1.97873 -1.65958,-2.87235l-0.76596,0c1.21277,1.97873 1.85107,4.02129 1.85107,6.00002c0,1.97873 -0.6383,4.02129 -1.85107,6.00002l0.76596,0z"
            fill={fill}
            fillOpacity={opacity}
            id={`snare_ghost${index}`}
            onClick={onClick}
            transform={`translate(${30 * index}, -15.5)`}
          />

          <path
            d="m118.26596,86.46185c0.57447,-0.89362 1.2766,-1.85107 1.65958,-2.87235c0.38298,-1.08511 0.57447,-2.10639 0.57447,-3.12767c0,-1.02128 -0.19149,-2.04256 -0.57447,-3.12767c-0.38298,-1.02128 -1.08511,-1.97873 -1.65958,-2.87235l-0.76596,0c1.21277,1.97873 1.85107,4.02129 1.85107,6.00002c0,1.97873 -0.6383,4.02129 -1.85107,6.00002l0.76596,0z"
            fill={fill}
            fillOpacity={opacity}
            id={`snare_${index}`}
            onClick={onClick}
            transform={`translate(${30 * index}, -15.5), rotate(-180 89.00000762939453,80.46183013916016) `}
          />
        </g>
      )}
    </g>
  );
};

export default Snare;
