import { useState } from "react";
import {
  Modal, Button, Dropdown, Form,
} from "semantic-ui-react";
import { useQuery, useMutation } from "@apollo/client";
import { useAppSelector, useAppDispatch } from "store/hooks";
import ALL_FOR_TEACHER from "graphql/student/queries/allForTeacher";
import GET_STUDENTS_LINKED_TO_FOLDER from "graphql/student/queries/getLinkedToFolder";
import ADD_STUDENT_TO_FOLDER from "graphql/folder/mutations/addStudentToFolder";
import CREATE_NOTIFICATION from "graphql/notification/mutations/create";
import { sendAlert } from "store/alert/slice";
import styles from "./styles.module.scss";

interface Props {
  open: boolean;
  setOpen: Function;
  folderId: number;
  name: string; // name of folder
}

interface studentType {
  email: string;
  studentId: number;
  userId: number;
  firstName: string;
  lastName: string;
}

const StudentFolderAccess = ({
  folderId, open, setOpen, name,
}: Props) => {
  const {
    typeId: teacherId, id: userId, firstName, lastName,
  } = useAppSelector((store) => store.user);

  const { loading: loadingStudents, data: dataStudents } = useQuery(ALL_FOR_TEACHER, {
    fetchPolicy: "network-only",
    variables: { teacherId, linked: true },
  });
  const { data: dataFolderStudents } = useQuery(GET_STUDENTS_LINKED_TO_FOLDER, {
    fetchPolicy: "network-only",
    variables: { folderId },
  });
  const [createNotification] = useMutation(CREATE_NOTIFICATION);

  const dispatch = useAppDispatch();

  const [addStudent] = useMutation(ADD_STUDENT_TO_FOLDER);

  const [studentId, setStudent] = useState(undefined);
  const [error, setError] = useState(false);

  const dropdownStudents: {
    key: number;
    value: number;
    text: string;
  }[] = [];

  let noLinkedStudents = [];
  if (
    dataStudents?.allStudents?.students && dataFolderStudents?.allStudentsLinkedToFolder
  ) {
    noLinkedStudents = dataStudents.allStudents.students.filter((student: studentType) => (
      !dataFolderStudents.allStudentsLinkedToFolder.ids.includes(student.studentId)
    ));

    noLinkedStudents.forEach((student: studentType, index: number) => {
      dropdownStudents[index] = {
        key: index,
        value: student.studentId,
        text: `${student.firstName} ${student.lastName}`,
      };
    });
  }

  return (
    <div>
      <Modal
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        open={open}
        style={{ width: 600 }}
      >
        <Modal.Header>Add Student</Modal.Header>
        <Modal.Content>
          {!(noLinkedStudents && noLinkedStudents.length === 0) && (
            <div>
              <Modal.Description>
                <div className={styles.description}>
                  Select a Student to add to this folder. They will gain access and will see all future changes.
                </div>
              </Modal.Description>
              <Form>
                <Form.Field error={error}>
                  {loadingStudents && (
                    <Dropdown
                      placeholder="Select Student"
                      search
                      selection
                      clearable
                      className={styles.dropdown}
                    />
                  )}
                  {dataStudents && (
                    <Dropdown
                      placeholder="Select Student"
                      search
                      selection
                      clearable
                      options={dropdownStudents}
                      name="studentId"
                      className={styles.dropdown}
                      onChange={(e: any, data: any) => setStudent(data.value)}
                      value={studentId}
                    />
                  )}
                </Form.Field>
              </Form>
            </div>
          )}

          {noLinkedStudents && noLinkedStudents.length === 0 && (
            <h5>All of your students already have access to this folder.</h5>
          )}
        </Modal.Content>
        <Modal.Actions>
          <Button color="black" onClick={() => setOpen(false)}>
            Cancel
          </Button>
          {!(noLinkedStudents && noLinkedStudents.length === 0) && (
            <Button
              content="Add"
              labelPosition="right"
              icon="plus"
              onClick={async () => {
                if (studentId) {
                  await addStudent({
                    variables: {
                      studentId,
                      folderId,
                    },
                  });

                  await createNotification({
                    variables: {
                      messageType: "ADDED_TO_FOLDER",
                      type: "student", // indicates the type of target account
                      targetId: studentId,
                      senderName: `${firstName} ${lastName}`,
                      senderId: userId, // userId of sender
                      payloadName: name,
                      payloadType: "folder",
                      payloadId: folderId,
                    },
                  });

                  setOpen(false);
                  dispatch(
                    sendAlert({
                      message: "Student successfully added to Folder.",
                      duration: 10000,
                      open: true,
                    }),
                  );
                } else {
                  setError(true);
                }
              }}
            />
          )}
        </Modal.Actions>
      </Modal>
    </div>
  );
};

export default StudentFolderAccess;
