import { Button, Icon } from "semantic-ui-react";
import { context, start } from "tone";
import { useAppSelector, useAppDispatch } from "store/hooks";
import EditorToggles from "screens/Main/EditorToggles";
import PlaybackControls from "screens/Main/PlaybackControls";
import TapTempo from "screens/Main/TapTempo";
import { setCountingIn, setPlaying } from "store/drumroom/slice";
import useInitReactGa from "hooks/useInitReactGa";

import styles from "./styles.module.scss";

interface Props {
  beatLibraryOpen: boolean;
  handleShowClick: () => void;
  readOnly: boolean;
}

const ControlPanel = ({
  beatLibraryOpen,
  handleShowClick,
  readOnly,
}: Props) => {
  const ReactGA = useInitReactGa();
  const dispatch = useAppDispatch();
  const playing = useAppSelector((state) => state.drumroom.playing);
  const countIn = useAppSelector((state) => state.drumroom.countIn);
  const countingIn = useAppSelector((state) => state.drumroom.countingIn);

  const onPlaybackToggle = () => {
    if ((!playing && countIn) || (playing && countingIn)) dispatch(setCountingIn(!playing));

    ReactGA.event({
      category: "User",
      action: `Playback ${playing ? "stopped" : "started"}`,
    });

    if (context.state !== "running") {
      context.resume();

      start().then(() => dispatch(setPlaying(!playing)));
    } else {
      dispatch(setPlaying(!playing));
    }
  };

  return (
    <div className={styles.container}>
      {!readOnly && (
        <div className={styles.button}>
          <Button
            icon
            disabled={beatLibraryOpen}
            onClick={handleShowClick}
          >
            <Icon name={beatLibraryOpen ? "folder open" : "folder"} />
          </Button>
        </div>
      )}
      <div className={styles.button}>
        <TapTempo />
      </div>
      <div className={styles.button}>
        <Button icon onClick={onPlaybackToggle} aria-label="Play button">
          <Icon name={playing ? "stop" : "play"} />
        </Button>
      </div>

      <PlaybackControls />
      <EditorToggles />
    </div>
  );
};

export default ControlPanel;
