import gql from "graphql-tag";

const REMOVE_INVALID_USER = gql`
  mutation($validateToken: String!) {
    removeInvalUser(validateToken: $validateToken) {
      ok
    }
  }
`;

export default REMOVE_INVALID_USER;
