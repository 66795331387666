import { Icon } from "semantic-ui-react";
import { useAppDispatch } from "store/hooks";
import { useMutation, useApolloClient } from "@apollo/client";
import GET_BEAT from "graphql/beat/queries/get";
import UPDATE_READ from "graphql/notification/mutations/updateRead";
import { loadBeat } from "store/beat/slice";
import { setBeatLibraryOpen } from "store/drumroom/slice";
import styles from "./styles.module.scss";

interface Props {
  notification: {
    id: number;
    messageType: string;
    senderName: string;
    senderId: number;
    payloadName: string;
    payloadType: string;
    payloadId: number;
    read: boolean;
    type: string;
    createdAt: string;
  };
}

// each notif has a type. actual message body is generated here. It is much easier for dynamically formatting different
// portions of the message, rather than resorting to string parsing and regex.

const Notification = ({
  notification: {
    id, messageType, senderName, payloadName, payloadId, read, createdAt,
  },
}: Props) => {
  const dispatch = useAppDispatch();
  const [readNotification] = useMutation(UPDATE_READ); // apollo auto-updates the cache on these! nice
  const client = useApolloClient();

  switch (messageType) {
    case "SHARED_BEAT": {
      return (
        <button
          type="button"
          className={styles.container}
          style={{ color: read ? "#8b8b8b" : "#000000" }}
          onClick={async () => {
            if (!read) {
              // only read unread notifications to elim unneeded network req's
              readNotification({ variables: { id } });
            }
            // add loadbeat action
            // pass in full beat object
            const { data } = await client.query({
              query: GET_BEAT,
              variables: { id: payloadId },
            });

            if (data?.getBeat?.beat) {
              dispatch(loadBeat({ ...data.getBeat.beat, notes: JSON.parse(data.getBeat.beat.notes) }));
            }
          }}
        >
          <p>
            <b>{senderName}</b>
            {" "}
            sent you a beat:
          </p>
          <Icon name="music" className={styles.icon} />
          <p className={styles.payloadName}>{payloadName}</p>
          <p className={styles.date}>{new Date(parseInt(createdAt, 10)).toDateString()}</p>
        </button>
      );
    }

    case "NEW_STUDENT": {
      return (
        <button
          type="button"
          className={styles.container}
          style={{ color: read ? "#8b8b8b" : "#000000" }}
          onClick={() => {
            if (!read) {
              readNotification({ variables: { id } });
            }
          }}
        >
          <p>A new student has signed up using your personalized link:</p>

          <Icon name="student" className={styles.icon} />
          <p className={styles.payloadName}>
            <b>{payloadName}</b>
          </p>
          <p className={styles.date}>{new Date(parseInt(createdAt, 10)).toDateString()}</p>
        </button>
      );
    }

    case "NEW_ASSIGNMENT": {
      return (
        <button
          type="button"
          className={styles.container}
          style={{ color: read ? "#8b8b8b" : "#000000" }}
          onClick={() => {
            if (!read) {
              // only read unread notifications to elim unneeded network req's
              readNotification({ variables: { id } });
            }
            dispatch(setBeatLibraryOpen(true));
          }}
        >
          <p>
            You have a new
            {" "}
            <b>Assignment</b>
            .
          </p>

          <p className={styles.date}>{new Date(parseInt(createdAt, 10)).toDateString()}</p>
        </button>
      );
    }

    case "ADDED_TO_FOLDER": {
      return (
        <button
          type="button"
          className={styles.container}
          style={{ color: read ? "#8b8b8b" : "#000000" }}
          onClick={() => {
            if (!read) {
              // only read unread notifications to elim unneeded network req's
              readNotification({ variables: { id } });
            }
            dispatch(setBeatLibraryOpen(true));
          }}
        >
          <p>
            You have been added to the folder
            {" "}
            <b>{payloadName}</b>
            .
          </p>

          <p className={styles.date}>{new Date(parseInt(createdAt, 10)).toDateString()}</p>
        </button>
      );
    }

    default: {
      return (
        <div className={styles.container}>
          <p>Notification type not recognized</p>
        </div>
      );
    }
  }
};

export default Notification;
