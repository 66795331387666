import { useState } from "react";
import { Button } from "semantic-ui-react";
import { useAppSelector, useAppDispatch } from "store/hooks";
import { setBpm } from "store/beat/slice";

const buffer = new Audio("./metronomelo.mp3");

const TapTempo = () => {
  const dispatch = useAppDispatch();
  const globalBpm = useAppSelector((state) => state.beat.present.bpm);

  const initialState = {
    bpm: globalBpm,
    count: 0,
    current: 0,
  };

  const [state, setState] = useState(initialState);

  const handleTap = () => {
    buffer.play();
    const current = Date.now();
    const delay = (current - state.current) / 1000; // delay between clicks (s/beat)
    const bpm = Math.floor(60 / delay); // beat/s * 60 s / 1 min
    let averageBpm = state.count !== 0 ? Math.floor((state.bpm * state.count + bpm) / (state.count + 1)) : globalBpm;

    if (averageBpm > 244) {
      averageBpm = 244;
    }

    if (delay > 2 && state.count) {
      setState(initialState);

      return;
    }

    setState({
      bpm: averageBpm,
      count: state.count < 2 ? state.count + 1 : 1, // allow moving average. counter goes up to 1, then stops
      current,
    });

    if (state.count !== 0) {
      dispatch(setBpm(averageBpm));
    }
  };

  return (
    <Button icon onMouseDown={handleTap}>
      Tap
    </Button>
  );
};

export default TapTempo;
