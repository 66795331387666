import { useQuery } from "@apollo/client";
import { useHistory } from "react-router-dom";
import { RootState } from "store";
import isAuthenticated from "utils/isAuthenticated";
import GET_USER from "graphql/user/queries/getUser";
import useClearAppState from "./useClearAppState";

interface GetUserData {
  getUser: RootState["user"];
}

const useRequireActiveSubscription = () => {
  const history = useHistory();
  const clearAppState = useClearAppState();

  if (!isAuthenticated()) return;

  const { data, loading } = useQuery<GetUserData>(GET_USER, {
    fetchPolicy: "network-only",
    pollInterval: parseInt(process.env.SUBSCRIPTION_POLL_INTERVAL_IN_MS || "300000", 10),
  });
  const { getUser: user } = data || {};

  if (!loading && !user?.subscriptionActive) {
    clearAppState();

    history.push("/inactive-subscription");
  }
};

export default useRequireActiveSubscription;
