import useWindowDimensions from "hooks/useWindowDimensions";
import { getNotes, getSubdivision } from "store/beat/selectors";
import { useAppSelector } from "store/hooks";
import {
  MAX_ROW_MEASURES_16,
  MAX_ROW_MEASURES_8,
  MIN_FIRST_MEASURE_WIDTH,
  MIN_MEASURE_WIDTH,
  SHEET_MAX_WIDTH_16,
  SHEET_MAX_WIDTH,
  SUBDIVISION_WIDTH_DIFF,
  TOTAL_GUTTERS_WIDTH,
} from "utils/constants";

const getMeasureScaleFactor = () => {
  const { width } = useWindowDimensions();
  const notesLength = useAppSelector(getNotes).length;
  const subdivision = useAppSelector(getSubdivision);
  const minMeasureWidth = MIN_MEASURE_WIDTH + (subdivision ? SUBDIVISION_WIDTH_DIFF : 0);
  const maxRowMeasures = subdivision ? MAX_ROW_MEASURES_16 : MAX_ROW_MEASURES_8;

  const unscaledWidth = MIN_FIRST_MEASURE_WIDTH
    + (subdivision ? SUBDIVISION_WIDTH_DIFF : 0)
    + minMeasureWidth * (notesLength - 1);
  const availableSpace = Math.min(width, subdivision ? SHEET_MAX_WIDTH_16 : SHEET_MAX_WIDTH) - TOTAL_GUTTERS_WIDTH;

  const minScale = Math.max(availableSpace / (minMeasureWidth * maxRowMeasures), 1);
  const scale = Math.max(Math.min(availableSpace / unscaledWidth, 2.2), minScale);

  return scale;
};

export default getMeasureScaleFactor;
