import { useState, useEffect } from "react";
import { Icon, Popup } from "semantic-ui-react";
import { useAppDispatch } from "store/hooks";
import { setCountingIn, setPlaying } from "store/drumroom/slice";
import useInitReactGa from "hooks/useInitReactGa";
import styles from "./styles.module.scss";

const PrintBeat = () => {
  const ReactGA = useInitReactGa();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (open) {
      window.print();
      setOpen(false);
    }
  }, [open]);

  const dispatch = useAppDispatch();
  const handlePrint = () => {
    ReactGA.event({
      category: "User",
      action: "Browser printing opened",
    });

    dispatch(setPlaying(false));
    dispatch(setCountingIn(false));
    setOpen(true);
  };

  return (
    <Popup
      content="Print Current Beat"
      position="bottom left"
      on="hover"
      closeOnTriggerClick
      trigger={(
        <Icon
          link
          circular
          name="print"
          onClick={handlePrint}
          className={styles.container}
        />
      )}
    />
  );
};

export default PrintBeat;
