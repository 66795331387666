import { useState } from "react";
import {
  Icon,
} from "semantic-ui-react";
import { useMutation } from "@apollo/client";
import _ from "lodash";
import CREATE_FOLDER from "graphql/folder/mutations/create";
import ALL_FOLDERS from "graphql/folder/queries/allFolders";
import STOCK_FOLDERS from "graphql/folder/queries/stockFolders";
import { folderType } from "types/folder";

type Props =
  | { stock?: false; exercise?: boolean, song?: boolean }
  | { stock?: true; exercise?: never, song?: never }

const CreateFolder = ({ stock, exercise, song }: Props) => {
  const [state, setState] = useState({
    email: "",
    errors: {},
    name: "",
    password: "",
    success: 0,
    successName: "",
  });

  const [createFolder] = useMutation(CREATE_FOLDER);

  const onSubmit = async () => {
    await createFolder({
      variables: { stock, exercise, song },
      update: (store, { data: { createFolder: createFolderResponse } }) => {
        const { ok, folder } = createFolderResponse;
        if (!ok) {
          return;
        }
        // update stock or allfolders cache based on which instance of component is executing
        const data = _.cloneDeep<{stockFolders: folderType[], allFolders: folderType[]} | null>(
          store.readQuery({
            query: stock ? STOCK_FOLDERS : ALL_FOLDERS,
          }),
        );

        if (stock) data?.stockFolders.push(folder);
        else data?.allFolders.push(folder);

        store.writeQuery({
          query: stock ? STOCK_FOLDERS : ALL_FOLDERS,
          data,
        });
      },
    });
    setState({
      ...state,
      name: "",
    });
  };

  return (
    <Icon onClick={onSubmit} name="folder" link />
  );
};

export default CreateFolder;
