import gql from "graphql-tag";

const VALIDATE = gql`
  mutation($token: String!) {
    validateRegister(token: $token) {
      ok
      user {
        id
        email
        validated
        type
        firstName
        lastName
      }
      typeId
    }
  }
`;

export default VALIDATE;
