import { Route, Redirect, RouteProps } from "react-router-dom";

export interface Props extends RouteProps {
  fallbackPath?: string;
  hasAccess?: () => boolean;
}

const PrivateRoute = ({
  component: Component,
  fallbackPath,
  hasAccess,
  ...rest
}: Props) => (Component ? (
  <Route
    {...rest}
    render={(routeProps) => {
      if (hasAccess?.()) return <Component {...routeProps} />;

      return <Redirect to={{ pathname: fallbackPath }} />;
    }}
  />
) : null);

export default PrivateRoute;

PrivateRoute.defaultProps = {
  fallbackPath: "/login",
  hasAccess: () => true,
};
