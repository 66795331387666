import gql from "graphql-tag";

const GET = gql`
  query($id: Int!) {
    getBeat(id: $id) {
      ok
      beat {
        notes
        id
        name
        folderId
        bpm
        subdivision
        sticking
        counting
        metronome
        grid
        color
        comments
        swing
        shared
        uuid
      }
      error
    }
  }
`;

export default GET;
