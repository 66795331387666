import {
  useState, useRef, useEffect, ReactElement,
} from "react";
import { GRID_ANIMATION_DURATION } from "utils/constants";
import { useSpring, animated } from "react-spring";

type ListItem = {
  component: ReactElement;
  hidden: boolean;
  // eslint-disable-next-line react/no-unused-prop-types
  key: string;
  open: boolean;
}

interface Props {
  items: ListItem[]
  subdivision: boolean;
}

const Item = ({ component, hidden, open }: ListItem) => {
  const [contentMaxHeight, setContentMaxHeight] = useState(200); // arbitrary initial height to elim the load animation
  const ref = useRef<HTMLDivElement>(null);

  const calcContentMaxHeight = () => {
    if (ref?.current) setContentMaxHeight(ref.current.scrollHeight);
  };

  useEffect(() => {
    calcContentMaxHeight();

    window.addEventListener("resize", () => calcContentMaxHeight());

    return () => window.removeEventListener("resize", () => calcContentMaxHeight());
  }, [ref, contentMaxHeight]);

  const { ...props } = useSpring({
    scY: open ? -1 : 1,
    opacity: open ? 1 : 0,
    maxHeight: open || hidden ? `${contentMaxHeight}px` : "0px",
    config: { duration: GRID_ANIMATION_DURATION },
  });

  return (
    <animated.div
      ref={ref}
      style={{ overflow: "hidden", ...props }}
    >
      {component}
    </animated.div>
  );
};

const AnimatedList = ({ items }: Props) => (
  <>
    {items?.map(({ key, ...rest }: ListItem) => <Item key={key} {...rest} />)}
  </>
);

export default AnimatedList;
