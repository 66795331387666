import { useLocation } from "react-router-dom";
import GET_USER_FROM_RESET_TOKEN from "graphql/user/queries/getUserFromResetToken";
import { useQuery } from "@apollo/client";
import ResetPasswordForm from "./Form";

const Reset = () => {
  const location = useLocation();
  const URLresetToken = location.search.slice(1);
  const { data } = useQuery(GET_USER_FROM_RESET_TOKEN, { variables: { token: URLresetToken } });

  return (
    <>
      {data?.getUserFromResetToken?.email && (
        <ResetPasswordForm
          email={data?.getUserFromResetToken.email}
          isAccountCreatedFromAPI={data?.getUserFromResetToken.isAccountCreatedFromAPI}
        />
      )}
    </>
  );
};

export default Reset;
