import { ChangeEvent } from "react";

import {
  Form, Message, Button, Input, Header,
} from "semantic-ui-react";
import { Link } from "react-router-dom";
import { Box } from "@material-ui/core";

import styles from "./styles.module.scss";

interface Props {
  emailError: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  email: string;
  passwordError: string;
  password: string;
  onSubmit: () => void;
  errorList: string[];
}

const LoginForm = ({
  emailError, onChange, email, passwordError, password, onSubmit, errorList,
}: Props) => (
  <Box p={4}>
    <Form>
      <Header as="h1">Login</Header>
      <Form.Field error={!!emailError}>
        <Input name="email" onChange={onChange} value={email} placeholder="Email" fluid className={styles.field} />
      </Form.Field>
      <Form.Field error={!!passwordError}>
        <Input
          name="password"
          onChange={onChange}
          value={password}
          type="password"
          placeholder="Password"
          fluid
          className={styles.field}
        />
      </Form.Field>

      {!!errorList.length && (
        <Message
          error
          className="ui warning visible message"
          list={errorList}
        />
      )}

      <Form.Field>
        <Button onClick={onSubmit}>Login</Button>
      </Form.Field>
    </Form>

    <br />
    <Link to="/reset-password">Forgot password? Click to reset.</Link>
  </Box>
);

export default LoginForm;
