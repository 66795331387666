import { useEffect, useRef, useState } from "react";
import { Sidebar as SemanticSidebar } from "semantic-ui-react";
import { useMutation } from "@apollo/client";
import { useAppSelector, useAppDispatch } from "store/hooks";
import UPDATE_BEAT_FOLDER from "graphql/beat/mutations/updateFolder";
import { beatType } from "types/beat";
import Sidebar from "components/Sidebar";
import { getCreateAssignmentOpen, getReadOnly } from "store/drumroom/selectors";
import { setBeatLibraryOpen } from "store/drumroom/slice";
import getUser from "store/user/selectors";
import useRequireActiveSubscription from "hooks/useRequireActiveSubscription";
import BeatLibrary from "./BeatLibrary";
import Editor from "./Editor";

import "./App.scss";

const Main = () => {
  useRequireActiveSubscription();
  const dispatch = useAppDispatch();
  const readOnly = useAppSelector(getReadOnly);
  const createAssignmentOpen = useAppSelector(getCreateAssignmentOpen);
  const beatLibraryOpen = useAppSelector((store) => store.drumroom.beatLibraryOpen);
  const segmentRef = useRef<HTMLElement>(null); // so beat lib can be closed on ref click
  const { type, isAdmin, email } = useAppSelector(getUser);
  const [canToggleOpen, setCanToggleOpen] = useState(true);
  const [deleteBeatId, setDeleteBeatId] = useState(-1);
  const [deleteFolderId, setDeleteFolderId] = useState(-1);
  const [updateBeatFolder] = useMutation(UPDATE_BEAT_FOLDER);

  useEffect(() => window.heap.identify(email));

  const handleShowClick = () => dispatch(setBeatLibraryOpen(true));

  const onMoveBeat = async (beatId: string, folderId: number, beat: beatType) => {
    await updateBeatFolder({
      variables: {
        id: parseInt(beatId, 10),
        folderId,
        name: beat.name,
      },
    });
  };

  const onOpenDeleteBeat = (id: number) => {
    setCanToggleOpen(!canToggleOpen);
    setDeleteBeatId(id);
  };

  const onCloseDeleteBeat = () => {
    setCanToggleOpen(!canToggleOpen);
    setDeleteBeatId(-1);
  };

  const onOpenDeleteFolder = (id: number) => {
    setCanToggleOpen(!canToggleOpen);
    setDeleteFolderId(id);
  };

  const onCloseDeleteFolder = () => {
    setCanToggleOpen(!canToggleOpen);
    setDeleteFolderId(-1);
  };

  return readOnly ? (
    <Editor
      beatLibraryOpen={beatLibraryOpen}
      createAssignmentOpen={createAssignmentOpen}
      handleShowClick={handleShowClick}
      isAdmin={isAdmin}
      readOnly={readOnly}
      segmentRef={segmentRef}
      type={type}
    />
  ) : (
    <SemanticSidebar.Pushable>
      <Sidebar
        canToggleOpen={canToggleOpen && !createAssignmentOpen}
        handleHide={() => dispatch(setBeatLibraryOpen(false))}
        open={beatLibraryOpen}
        ref={segmentRef}
      >
        <BeatLibrary
          closeDeleteBeat={onCloseDeleteBeat}
          closeDeleteFolder={onCloseDeleteFolder}
          deleteBeatId={deleteBeatId}
          deleteFolderId={deleteFolderId}
          isAdmin={isAdmin}
          moveBeat={onMoveBeat}
          openDeleteBeat={onOpenDeleteBeat}
          openDeleteFolder={onOpenDeleteFolder}
          type={type}
        />
      </Sidebar>

      <SemanticSidebar.Pusher>
        <Editor
          beatLibraryOpen={beatLibraryOpen}
          createAssignmentOpen={createAssignmentOpen}
          handleShowClick={handleShowClick}
          isAdmin={isAdmin}
          readOnly={readOnly}
          segmentRef={segmentRef}
          type={type}
        />
      </SemanticSidebar.Pusher>
    </SemanticSidebar.Pushable>
  );
};

export default Main;
