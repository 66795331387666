import { useAppDispatch } from "store/hooks";
import { Popup, Icon } from "semantic-ui-react";
import { clear } from "store/beat/slice";
import styles from "./styles.module.scss";

const ClearGrid = () => {
  const dispatch = useAppDispatch();

  return (
    <Popup
      content="Clear Beat"
      position="bottom center"
      on="hover"
      trigger={(
        <Icon
          name="eraser"
          circular
          link
          onClick={(e: any) => {
            dispatch(clear());
            e.target.blur();
          }}
          onKeyDown={(e: any) => e.preventDefault()}
          className={styles.icon}
        />
      )}
    />
  );
};

export default ClearGrid;
