import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface State {
  email: string;
  firstName: string;
  id: number;
  isAdmin: boolean;
  lastName: string;
  subscriptionActive: boolean;
  type: number;
  typeId: number;
  validated: boolean;
}

const initialState: State = {
  email: "",
  firstName: "",
  id: -1,
  isAdmin: false,
  lastName: "",
  type: -1,
  typeId: -1,
  subscriptionActive: true,
  validated: false,
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<State>) => action.payload,
  },
});

export const { setUser } = userSlice.actions;

export default userSlice.reducer;
