import { useSpring, animated } from "react-spring";
import { Icon } from "semantic-ui-react";

type Props = {
  message: string;
};

const AnimatedConfirmMsg = ({ message }: Props) => {
  const springProps = useSpring({
    opacity: 1,
    from: { opacity: 0 },
    config: { duration: 1500 },
  });

  return (
    <div style={{
      position: "relative", width: "100%", top: "0px", left: "0px",
    }}
    >
      <animated.div style={springProps}>
        <Icon style={{ color: "#2E8B57" }} name="check" />
        {message}
      </animated.div>
    </div>
  );
};

export default AnimatedConfirmMsg;
