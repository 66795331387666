import gql from "graphql-tag";

const CREATE = gql`
  mutation(
    $messageType: String!
    $type: String!
    $senderName: String!
    $senderId: Int!
    $payloadName: String!
    $payloadType: String!
    $payloadId: Int
    $targetId: Int
  ) {
    createNotification(
      messageType: $messageType
      type: $type
      senderName: $senderName
      senderId: $senderId
      payloadName: $payloadName
      payloadType: $payloadType
      payloadId: $payloadId
      targetId: $targetId
    ) {
      ok
    }
  }
`;

export default CREATE;
