import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { cloneDeep, times } from "lodash";
import NOTES_INDEX_TO_KEY from "utils/notesIndexToKey";
import { initReactGa } from "hooks/useInitReactGa";

interface ActiveNote {
  inst: number;
  index: number;
  type?: string;
}

interface UpdateNotes {
  activeNote: ActiveNote;
  add?: boolean;
  activeMeasureIndex: number;
}
interface ObjectKeys {
  [key: string]: Array<string | number>;
}
export interface Measure extends ObjectKeys {
  bass: string[];
  hiHat: string[];
  hiTom: string[];
  isolate: string[];
  loTom: string[];
  midTom: string[];
  snare: string[];
  sticking: number[];
}

export interface State {
  activeMeasureIndex: number;
  activeNote: ActiveNote;
  bpm: number;
  cleared: boolean;
  color: boolean;
  comments: string;
  counting: boolean;
  folderId: number | null;
  grid: boolean;
  id: number;
  metronome: boolean;
  name: string;
  notes: Measure[];
  shared: boolean;
  sticking: boolean;
  subdivision: boolean;
  swing: number;
  toms: boolean;
  uuid: string;
}

// order matters here. Since it's a constant, can it be guaranteed?
export const emptyMeasure = {
  hiHat: ["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", ""],
  hiTom: ["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", ""],
  midTom: ["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", ""],
  snare: ["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", ""],
  loTom: ["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", ""],
  bass: ["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", ""],
  isolate: ["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", ""],
  sticking: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
};

export const instrumentKeys = Object.keys(emptyMeasure).filter((key) => !(key === "sticking" || key === "isolate"));

const initialState: State = {
  activeMeasureIndex: 0,
  activeNote: { inst: -1, index: -1, type: "" },
  bpm: 60,
  cleared: true,
  color: true,
  comments: "",
  counting: false,
  folderId: null,
  grid: true,
  id: -1,
  metronome: false,
  name: "",
  notes: [cloneDeep(emptyMeasure)],
  shared: false,
  sticking: false,
  subdivision: false,
  swing: 0,
  toms: false,
  uuid: "",
};

export const beatSlice = createSlice({
  name: "beat",
  initialState,
  reducers: {
    loadBeat: (state, action: PayloadAction<State>) => {
      const ReactGA = initReactGa();

      ReactGA.event({
        category: "User",
        action: `Beat loaded`,
        label: JSON.stringify({ id: action.payload.id, name: action.payload.name }),
      });

      return { ...initialState, ...action.payload };
    },
    setActiveNote: (state, action: PayloadAction<ActiveNote>) => ({ ...state, activeNote: action.payload }),
    setActiveMeasure: (state, action: PayloadAction<number>) => ({ ...state, activeMeasureIndex: action.payload }),
    addMeasures: (state, action: PayloadAction<number>) => {
      times(action.payload, () => state.notes.push(cloneDeep(emptyMeasure)));
    },
    advanceActiveMeasure: (state) => ({ ...state, activeMeasureIndex: state.activeMeasureIndex + 1 }),
    deleteMeasure: (state, action: PayloadAction<number>) => {
      state.notes.splice(action.payload, 1);
    },
    duplicateMeasure: (state, action: PayloadAction<number>) => {
      state.notes.splice(action.payload, 0, cloneDeep(state.notes[action.payload]));
    },
    updateNotes: (state, action: PayloadAction<UpdateNotes>) => {
      const {
        add,
        activeNote,
        activeMeasureIndex,
      } = action.payload;

      const updatedNotes = cloneDeep(state.notes);
      const updatedMeasure = updatedNotes[activeMeasureIndex];
      const noteKey = NOTES_INDEX_TO_KEY[activeNote?.inst];

      if (noteKey === "sticking") {
        updatedMeasure[noteKey][activeNote?.index] = (
          updatedMeasure[noteKey][activeNote?.index] + 1
        ) % 3;

        updatedNotes[activeMeasureIndex] = updatedMeasure;
        return {
          ...state,
          notes: updatedNotes,
        };
      }

      if (add) {
        updatedMeasure[noteKey][activeNote?.index] = (
          `${updatedMeasure[noteKey][activeNote?.index]}${activeNote?.type}`
        );
      } else if (activeNote?.type === "n") {
        // if coming from notegridbutton, shut off note
        updatedMeasure[noteKey][activeNote?.index] = "";
      } else {
        // if coming from anywhere else (removing emellishments), remove embellishments
        updatedMeasure[noteKey][activeNote?.index] = updatedMeasure[noteKey][
          activeNote?.index
        ].replace(activeNote?.type || "", "");
      }

      updatedNotes[activeMeasureIndex] = updatedMeasure;

      return {
        ...state,
        notes: updatedNotes,
        activeNote: {
          inst: activeNote?.inst,
          index: activeNote?.index,
          type: updatedMeasure[noteKey][activeNote?.index],
        },
      };
    },
    clear: () => ({ ...initialState }),
    setBpm: (state, action: PayloadAction<number>) => ({ ...state, bpm: action.payload }),
    setMetronome: (state, action: PayloadAction<boolean>) => ({ ...state, metronome: action.payload }),
    setSwing: (state, action: PayloadAction<number>) => ({ ...state, swing: action.payload }),
    toggleColor: (state) => ({ ...state, color: !state.color }),
    toggleCounting: (state) => ({ ...state, counting: !state.counting }),
    toggleGrid: (state) => ({ ...state, grid: !state.grid }),
    toggleSticking: (state) => ({ ...state, sticking: !state.sticking }),
    toggleSubdivision: (state) => ({ ...state, subdivision: !state.subdivision }),
    toggleToms: (state) => ({ ...state, toms: !state.toms }),
  },
});

export const {
  addMeasures,
  advanceActiveMeasure,
  clear,
  deleteMeasure,
  duplicateMeasure,
  loadBeat,
  setActiveMeasure,
  setActiveNote,
  setBpm,
  setMetronome,
  setSwing,
  toggleColor,
  toggleCounting,
  toggleGrid,
  toggleSticking,
  toggleSubdivision,
  toggleToms,
  updateNotes,
} = beatSlice.actions;

export default beatSlice.reducer;
