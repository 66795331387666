import { useEffect, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { getToms } from "store/beat/selectors";
import { GRID_ANIMATION_DURATION, INSTRUMENTS, TOMS } from "utils/constants";
import { Measure, updateNotes } from "store/beat/slice";
import NOTES_INDEX_TO_KEY from "utils/notesIndexToKey";
import getMeasureScaleFactor from "utils/getMeasureScaleFactor";
import { times } from "lodash";

import snare from "images/snare.png";
import bass from "images/bass.png";
import hihat from "images/hihat.png";
import hiTom from "images/hitom.png";
import loTom from "images/lotom.png";
import midTom from "images/midtom.png";
import triggerResize from "utils/triggerResize";
import styles from "./styles.module.scss";

const IMAGES: ObjectKeys = {
  Snare: snare,
  "Hi-Hat": hihat,
  Bass: bass,
  "Hi-Tom": hiTom,
  "Mid-Tom": midTom,
  "Lo-Tom": loTom,
};

interface ObjectKeys {
  [key: string]: string;
}

export interface Props {
  activeMeasureIndex: number;
  color: boolean;
  gridOpacity: { background: string }[][];
  isActive: boolean;
  isFirst: boolean;
  measure: Measure;
  measureChangeAnimationApplies: boolean;
  readOnly?: boolean;
  subdivision: boolean;
}

const Grid = ({
  activeMeasureIndex,
  color,
  gridOpacity,
  isActive,
  isFirst,
  measure,
  measureChangeAnimationApplies,
  readOnly,
  subdivision,
}: Props) => {
  const dispatch = useAppDispatch();
  const scale = getMeasureScaleFactor();

  const [isClickable, setIsClickable] = useState(isActive);
  const toms = useAppSelector(getToms);

  useEffect(() => { triggerResize(); }, [toms]);

  const instruments = useMemo(
    () => {
      if (toms) return INSTRUMENTS;

      return INSTRUMENTS.filter((instrument) => !TOMS.includes(instrument));
    },
    [toms],
  );

  useEffect(() => {
    setTimeout(() => setIsClickable(isActive), measureChangeAnimationApplies ? GRID_ANIMATION_DURATION : 0);
  }, [isActive]);

  let hidden: string[] = [];
  if (measure && measure?.isolate) {
    hidden = measure.isolate;
  } else {
    hidden = new Array(subdivision ? 16 : 8).fill(false);
  }

  const clickDragIsolate = (index: number, event: any) => {
    if (!isClickable) return;

    event.preventDefault();

    if (event.buttons) {
      dispatch(
        updateNotes({
          activeNote: {
            inst: 7,
            index,
            type: "true",
          },
          add: !hidden[index],
          activeMeasureIndex,
        }),
      );
    }
  };

  const clickDragGrid = (inst: number, index: number, event: any) => {
    if (!isClickable) return;

    event.preventDefault();

    if (event.buttons) {
      dispatch(
        updateNotes({
          activeNote: {
            inst,
            index,
            type: "n",
          },
          add: !measure[NOTES_INDEX_TO_KEY[inst]][index],
          activeMeasureIndex,
        }),
      );
    }
  };

  const handleClick = (i: number, j: number) => {
    if (!isClickable) return;

    dispatch(
      updateNotes({
        activeNote: {
          inst: i,
          index: j,
          type: "n",
        },
        add: !measure[NOTES_INDEX_TO_KEY[i]][j],
        activeMeasureIndex,
      }),
    );
  };

  const onIsolateClickHandler = (inst: number, index: number, type: string, add: boolean) => {
    if (!isClickable) return;

    dispatch(
      updateNotes({
        activeNote: {
          inst,
          index,
          type,
        },
        add,
        activeMeasureIndex,
      }),
    );
  };

  return (
    <div className={styles.container}>
      {instruments.map((e: any, index: number) => {
        const i = INSTRUMENTS.indexOf(e);

        return (
          <div
            key={e}
            className={`
              ${styles.gridRowContainer} ${isFirst ? styles.first : ""} ${subdivision ? styles.subdivision : ""}
            `}
          >
            {isFirst && (
              <div className="GridLabel" style={{ fontSize: 11 * scale ** 0.4 }}>
                {instruments[index]}
                <img
                  alt={`${instruments[index]}Logo`}
                  className="GridImg"
                  src={IMAGES[instruments[index]]}
                  style={{
                    transform: `scale(${scale * 0.1})`,
                    transformOrigin: "center left",
                    position: "absolute",
                    left: `-${scale * 18}px`,
                  }}
                />
              </div>
            )}
            {times(subdivision ? 16 : 8, (j) => {
              j *= subdivision ? 1 : 2;

              return (
                <input
                  // eslint-disable-next-line react/no-array-index-key
                  key={j}
                  checked={!!measure[NOTES_INDEX_TO_KEY[i]][j]}
                  className={color ? instruments[index] : "Blk"}
                  disabled={readOnly}
                  onClick={() => handleClick(i, j)}
                  onFocus={(event: any) => clickDragGrid(i, j, event)}
                  onMouseDown={(c) => c.preventDefault()}
                  onMouseOver={(event: any) => clickDragGrid(i, j, event)}
                  readOnly
                  style={{
                    height: 20 * scale,
                    width: 20 * scale,
                    borderRadius: 7 * scale,
                    ...gridOpacity[i + 1][j],
                  }}
                  type="checkbox"
                />
              );
            })}
          </div>
        );
      })}
      <div
        className={`
          ${styles.gridRowContainer} ${isFirst ? styles.first : ""} ${subdivision ? styles.subdivision : ""}
        `}
      >
        {isFirst && (
          <p className="GridLabel" style={{ fontSize: 12 * scale ** 0.4 }}>
            Isolate
          </p>
        )}
        {times(subdivision ? 16 : 8, (i) => {
          i *= subdivision ? 1 : 2;

          return (
            <input
              // eslint-disable-next-line react/no-array-index-key
              key={i}
              checked={!!hidden[i]}
              className="Hide"
              disabled={readOnly}
              onClick={() => onIsolateClickHandler(7, i, "true", !hidden[i])}
              onFocus={(event: any) => clickDragIsolate(i, event)}
              onKeyDown={(e) => e.preventDefault()}
              onMouseOver={(event: any) => clickDragIsolate(i, event)}
              readOnly
              style={{
                height: 20 * scale,
                width: 20 * scale,
                borderRadius: 7 * scale,
              }}
              type="checkbox"
            />
          );
        })}
      </div>
    </div>
  );
};

export default Grid;

Grid.defaultProps = {
  readOnly: false,
};
