import { useState } from "react";
import { Icon } from "semantic-ui-react";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import CreateFolder from "screens/Main/BeatLibrary/CreateFolder";
import styles from "./styles.module.scss";

interface Props {
  search: string;
  setSearch: (search: string) => void;
  setShown: (shown: boolean) => void;
  showCreateFolder?: boolean;
  showSearch?: boolean;
  shown: boolean;
  stock?: boolean;
  title: string;
}

const Header = ({
  search,
  setSearch,
  setShown,
  showCreateFolder,
  showSearch,
  shown,
  stock,
  title,
}: Props) => {
  const [searchOpen, setSearchOpen] = useState(false);

  return (
    <div className={styles.container}>
      <Box p={1}>
        <Grid container justify="space-between">
          <Grid item>
            <h4>{title}</h4>
          </Grid>
          <Grid item>
            <Grid container justify="flex-end">
              <Grid item>
                {showSearch && (
                  <div>
                    {searchOpen && (
                      <input
                        placeholder="Search"
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                        className={styles.searchField}
                        onBlur={() => {
                          if (search === "") {
                            setSearchOpen(!searchOpen);
                          }
                        }}
                      />
                    )}
                    <Icon
                      name="search"
                      color="grey"
                      onClick={() => setSearchOpen(!searchOpen)}
                    />
                  </div>
                )}
              </Grid>
              {showCreateFolder && (
                <Grid item>
                  <CreateFolder stock={!!stock} />
                </Grid>
              )}
              <Grid item>
                <Icon
                  link
                  name={shown ? "minus" : "plus"}
                  onClick={() => setShown(!shown)}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

Header.defaultProps = {
  showCreateFolder: false,
  showSearch: true,
  stock: false,
};

export default Header;
