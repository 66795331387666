import CreateBeat from "screens/Main/Tabs/CreateBeat";
import CreateAssignment from "screens/Main/Tabs/CreateAssignment";

interface Props {
  readOnly: boolean;
  isAdmin: boolean;
  type: number;
}

const Tabs = ({ readOnly, isAdmin, type }: Props) => (
  <>
    {!readOnly && <CreateBeat stock={false} />}
    {!readOnly && isAdmin && <CreateBeat stock />}
    {type === 1 && <CreateAssignment />}
  </>
);

export default Tabs;
