import BuiltInBeats from "./BuiltInBeats";
import MyAssignments from "./MyAssignments";
import MyStudents from "./MyStudents";
import MyCustomBeats from "./MyCustomBeats";
import MyCurriculum from "./MyCurriculum";
import styles from "./styles.module.scss";

interface Props {
  closeDeleteBeat: () => void;
  closeDeleteFolder: () => void;
  deleteBeatId: number;
  deleteFolderId: number;
  isAdmin: boolean;
  moveBeat: Function;
  openDeleteBeat: (id: number) => void;
  openDeleteFolder: (id: number) => void;
  type: number;
}

const BeatLibrary = ({
  closeDeleteBeat,
  closeDeleteFolder,
  deleteBeatId,
  deleteFolderId,
  isAdmin,
  moveBeat,
  openDeleteBeat,
  openDeleteFolder,
  type,
}: Props) => (
  <div className={styles.container}>
    <div className={styles.titleBg}>
      <h3 className={styles.title}>{isAdmin ? "Master Beat Library" : "Beat Library"}</h3>
    </div>

    <BuiltInBeats
      closeDeleteBeat={closeDeleteBeat}
      closeDeleteFolder={closeDeleteFolder}
      deleteBeatId={deleteBeatId}
      deleteFolderId={deleteFolderId}
      isAdmin={isAdmin}
      moveBeat={moveBeat}
      openDeleteBeat={openDeleteBeat}
      openDeleteFolder={openDeleteFolder}
    />

    {type === 0 && (
      <MyCurriculum
        closeDeleteBeat={closeDeleteBeat}
        closeDeleteFolder={closeDeleteFolder}
        deleteBeatId={deleteBeatId}
        deleteFolderId={deleteFolderId}
        moveBeat={moveBeat}
        openDeleteBeat={openDeleteBeat}
        openDeleteFolder={openDeleteFolder}
        type={type}
      />
    )}

    {type === 0 && <MyAssignments />}

    <MyCustomBeats
      closeDeleteBeat={closeDeleteBeat}
      closeDeleteFolder={closeDeleteFolder}
      deleteBeatId={deleteBeatId}
      deleteFolderId={deleteFolderId}
      moveBeat={moveBeat}
      openDeleteBeat={openDeleteBeat}
      openDeleteFolder={openDeleteFolder}
      type={type}
    />

    {type === 1 && <MyStudents />}
  </div>
);

export default BeatLibrary;
