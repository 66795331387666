import { useSpring, animated } from "react-spring";
import { Icon } from "semantic-ui-react";

const ConfirmResetMessage = () => {
  const props = useSpring({
    opacity: 1,
    from: { opacity: 0 },
    config: { duration: 2000 },
  });

  return (
    <div
      style={{
        position: "relative",
        height: "100px",
        paddingTop: "50px",
        paddingBottom: "150px",
        justifyContent: "center",
      }}
    >
      <animated.div style={props}>
        <Icon style={{ color: "#2E8B57" }} name="check" />
        A password reset link was sent to the email provided. Please follow the link to reset your password.
      </animated.div>
    </div>
  );
};

export default ConfirmResetMessage;
