import { useApolloClient } from "@apollo/client";
import { useAppDispatch } from "store/hooks";

const useClearAppState = () => {
  const client = useApolloClient();
  const dispatch = useAppDispatch();

  const clearAppState = () => {
    // resets apollo store. clearStore does not refetch any queries, where resetStore does
    // https://www.apollographql.com/docs/react/networking/authentication/
    // also clears context. context reestablished again on login
    // context referring to setContext in root index.js on frontend, which sets http req headers for which the
    // backend index.js converts to user context.
    // this way, backend always knows which user is making queries without requiring passing of a userId to each query
    client.clearStore();
    localStorage.clear();
    dispatch({ type: "USER_LOGOUT" }); // resets redux store to initial state
  };

  return clearAppState;
};

export default useClearAppState;
