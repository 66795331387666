import { useState, ChangeEvent } from "react";
import { useMutation, useApolloClient } from "@apollo/client";
import _ from "lodash";
import {
  Button, Form, Input, Message,
} from "semantic-ui-react";
import CONNECT_TO_STUDENT_SANS_TEACHER from "graphql/student/mutations/connectSansTeacher";
import ALL_STUDENTS_FOR_TEACHER from "graphql/student/queries/allForTeacher";
import ALL_STUDENTS_STRUCTURED_FOR_TEACHER from "graphql/student/queries/allStructuredForTeacher";
import GET_STRUCTURED from "graphql/student/queries/getStructured";
import AnimatedConfirmMsg from "components/AnimatedConfirmMsg";
import styles from "./styles.module.scss";

interface Props {
  typeId: number; // teacherId
}

type EmailFieldType = undefined | string;

const AddStudent = ({ typeId }: Props) => {
  const client = useApolloClient();
  const [email, setEmail] = useState<EmailFieldType>(undefined);
  const [error, setError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [addStudent] = useMutation(CONNECT_TO_STUDENT_SANS_TEACHER);

  const onSubmit = async () => {
    setError("");
    setSuccessMessage("");

    const response = await addStudent({
      variables: {
        email,
        teacherId: typeId,
      },
      update: async (cache, { data: { connectToStudentSansTeacher } }) => {
        const { ok, student } = connectToStudentSansTeacher;

        if (!ok) return;

        const students: any = _.cloneDeep(
          cache.readQuery({
            query: ALL_STUDENTS_FOR_TEACHER,
            variables: { teacherId: typeId },
          }),
        );

        const structuredStudents: any = _.cloneDeep(
          cache.readQuery({
            query: ALL_STUDENTS_STRUCTURED_FOR_TEACHER,
            variables: { teacherId: typeId },
          }),
        );
        const newStudents = students.allStudents.students.push(student);

        if (structuredStudents) {
          const { data } = await client.query({
            query: GET_STRUCTURED,
            variables: { studentId: student.id },
          });
          const newStructuredStudents = structuredStudents.allStudentsBeatsAssignments.students.push(
            data.getStudentBeatsAssignments.student,
          );

          cache.writeQuery({
            query: ALL_STUDENTS_STRUCTURED_FOR_TEACHER,
            variables: { teacherId: typeId },
            data: { allStudentsBeatsAssignments: { ok: true, students: newStructuredStudents } },
          });
        }

        cache.writeQuery({
          query: ALL_STUDENTS_FOR_TEACHER,
          variables: { teacherId: typeId },
          data: { allStudents: { ok: true, students: newStudents } },
        });
      },
    });

    if (!response.data.connectToStudentSansTeacher.ok) {
      setError(response.data.connectToStudentSansTeacher.message);
    } else {
      setSuccessMessage(response.data.connectToStudentSansTeacher.message);
    }
  };

  return (
    <div className={styles.container}>
      <h1>Connect to Student</h1>
      <p>Use this field to connect with student that has a Drumroom account and has not been paired with a teacher.</p>

      <Form>
        <Form.Field>
          <Form.Field>
            <Input
              placeholder="Student Email"
              name="studentId"
              onChange={(e: ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)}
              value={email}
            />
          </Form.Field>
        </Form.Field>

        {error && <Message className="ui warning visible message" error content={error} />}
        {successMessage && <AnimatedConfirmMsg message={successMessage} />}

        <Button onClick={onSubmit}>Connect</Button>
      </Form>
    </div>
  );
};

export default AddStudent;
