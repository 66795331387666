import EighthFlag from "./EighthFlag";

interface Props {
  fill: string;
  opacity: number;
  transformX: number;
  transformY: number;
}

const SixteenthFlag = ({
  fill, opacity, transformX, transformY,
}: Props) => (
  <>
    <EighthFlag
      fill={fill}
      opacity={opacity}
      transformX={transformX}
      transformY={transformY}
    />
    <EighthFlag
      fill={fill}
      opacity={opacity}
      transformX={transformX}
      transformY={transformY + 7.65}
    />
  </>
);

export default SixteenthFlag;
