import { useRef, useEffect } from "react";
import usePlayback from "hooks/usePlayback";
import { useAppSelector, useAppDispatch } from "store/hooks";
import { Box } from "@material-ui/core";
import { Button } from "semantic-ui-react";
import { getActiveMeasureIndex, getNotes, getSubdivision } from "store/beat/selectors";
import {
  addMeasures, setActiveMeasure, deleteMeasure, duplicateMeasure,
} from "store/beat/slice";
import { RootState } from "store";
import { SHEET_MAX_WIDTH, SHEET_MAX_WIDTH_16 } from "utils/constants";
import useInitReactGa from "hooks/useInitReactGa";
import triggerResize from "utils/triggerResize";
import MeasureWrapper from "./MeasureWrapper";
import styles from "./styles.module.scss";

const EVENT_POLL_INTERVAL_IN_MS = 60000;
interface Props {
  readOnly: boolean;
}

const Sheet = ({ readOnly }: Props) => {
  const ReactGA = useInitReactGa();
  const dispatch = useAppDispatch();
  const availableSheetAreaRef = useRef<HTMLDivElement>(null);
  const availableSheetWidth = availableSheetAreaRef.current?.offsetWidth || 0;

  const notes = useAppSelector(getNotes);
  const subdivision = useAppSelector(getSubdivision);
  const activeMeasureIndex = useAppSelector(getActiveMeasureIndex);

  const notesRef = useRef<RootState["beat"]["present"]["notes"]>(notes);
  const prevNotesRef = useRef<RootState["beat"]["present"]["notes"]>(notes);
  notesRef.current = notes;

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (prevNotesRef.current !== notesRef.current) {
        ReactGA.event({
          category: "User",
          action: "Beat notes state updated",
        });
      }

      prevNotesRef.current = notesRef.current;
    }, EVENT_POLL_INTERVAL_IN_MS);

    return () => clearInterval(intervalId);
  }, []);

  usePlayback(notes[activeMeasureIndex]);

  const onAddMeasure = () => {
    dispatch(addMeasures(1));
    triggerResize();
  };
  const onDuplicateMeasure = (index : number) => {
    dispatch(duplicateMeasure(index));
    triggerResize();
  };

  const onDeleteMeasure = (index: number) => {
    if (activeMeasureIndex === index) dispatch(setActiveMeasure(0));
    if (activeMeasureIndex > index) dispatch(setActiveMeasure(activeMeasureIndex - 1));
    if (notes.length > 1) dispatch(deleteMeasure(index));
    setTimeout(() => triggerResize(), 10);
  };

  return (
    <Box className={styles.box} maxWidth={subdivision ? SHEET_MAX_WIDTH_16 : SHEET_MAX_WIDTH}>
      {!readOnly && (
        <Box pb={2} className={styles.addMeasureButton}>
          <Button icon="plus" circular onClick={onAddMeasure} />
        </Box>
      )}

      <div ref={availableSheetAreaRef} className={`${styles.container} sheet`}>
        {notes.map((measure: any, i: number) => (
          <MeasureWrapper
            // if we give this a proper key, the animation state is not retained since components remount whenever
            // Sheet is re-rendered. Not sure if there are perf implications from this but we need to do it this way
            // to retain animation functionality.
            /* eslint-disable-next-line react/no-array-index-key */
            key={`key-${i}`}
            availableSheetWidth={availableSheetWidth}
            measureIndex={i}
            onDeleteMeasure={onDeleteMeasure}
            onDuplicateMeasure={onDuplicateMeasure}
            readOnly={readOnly}
          />
        ))}
      </div>
    </Box>
  );
};

export default Sheet;
