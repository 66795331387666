import { Button, Divider } from "semantic-ui-react";
import { useHistory } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { Box } from "@material-ui/core";
import RESEND_VALIDATE_EMAIL from "graphql/user/mutations/resendValidateEmail";

interface Props {
  email: string;
}

const Form = ({ email }: Props) => {
  const history = useHistory();
  const [resendValidateEmail] = useMutation(RESEND_VALIDATE_EMAIL);

  const onResend = async () => {
    const response = await resendValidateEmail({
      variables: { email },
    });

    const { ok, validateToken } = response.data.resendValidateEmail;

    if (ok) {
      localStorage.clear();
      localStorage.setItem("validateToken", validateToken);
      history.push("/validate");
    }
  };

  return (
    <>
      <Box p={4}>
        <Box pb={2}>
          We&apos;re sorry, your account is not validated. Please check your inbox or spam folder for a
          confirmation email. If you cannot find one please resend the message below.
        </Box>
        <Box pb={2}>
          <Button onClick={onResend}>Resend email</Button>
        </Box>
        <Box pb={2}>
          <Divider />
        </Box>

        For any further issues logging in, please contact
        {" "}
        <a href={`mailto: ${process.env.REACT_APP_SUPPORT_EMAIL}`}>{process.env.REACT_APP_SUPPORT_EMAIL}</a>
        {" "}
        and we will be happy to assist you.
      </Box>
    </>
  );
};

export default Form;
