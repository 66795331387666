import gql from "graphql-tag";

const GET_USER = gql`
  query {
    getUser {
      email
      firstName
      id
      isAdmin
      lastName
      subscriptionActive
      type
      typeId
      validated
    }
  }
`;

export default GET_USER;
