import gql from "graphql-tag";

const ALL_FOLDERS = gql`
  {
    allFolders {
      id
      name
      order
      exercise
      song
    }
  }
`;

export default ALL_FOLDERS;
