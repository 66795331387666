import gql from "graphql-tag";

const ALL = gql`
  query($userId: Int!) {
    allNotifications(userId: $userId) {
      ok
      notifications {
        id
        messageType
        read
        senderName
        senderId
        payloadName
        payloadType
        payloadId
        type
        createdAt
      }
    }
  }
`;

export default ALL;
