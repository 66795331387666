import gql from "graphql-tag";

const UPDATE_NAME = gql`
  mutation($id: Int!, $name: String!) {
    updateFolderName(id: $id, name: $name) {
      ok
      folder {
        id
        name
      }
    }
  }
`;

export default UPDATE_NAME;
