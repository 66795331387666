import gql from "graphql-tag";

const DELETE = gql`
  mutation($userId: Int!) {
    delete(id: $userId) {
      ok
      errors {
        path
        message
      }
    }
  }
`;

export default DELETE;
