import gql from "graphql-tag";

const ADD_STUDENT_TO_FOLDER = gql`
  mutation($studentId: Int!, $folderId: Int!) {
    addStudentToFolder(studentId: $studentId, folderId: $folderId) {
      ok
    }
  }
`;

export default ADD_STUDENT_TO_FOLDER;
