import { useState } from "react";
import { useAppSelector, useAppDispatch } from "store/hooks";
import { useLazyQuery } from "@apollo/client";
import { Button, Modal, TextArea } from "semantic-ui-react";
import SEND_FEEDBACK from "graphql/contact/queries/sendFeedback";
import { sendAlert } from "store/alert/slice";
import styles from "./styles.module.scss";

const Feedback = () => {
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");

  const first = useAppSelector((state) => state.user.firstName);
  const last = useAppSelector((state) => state.user.lastName);
  const email = useAppSelector((state) => state.user.email);

  const dispatch = useAppDispatch();

  const [sendFeedback] = useLazyQuery(SEND_FEEDBACK);

  const send = async () => {
    await sendFeedback({
      variables: {
        first,
        last,
        email,
        message,
      },
    });

    setOpen(false);
    dispatch(
      sendAlert({
        message: "Feedback sent. Thanks!",
        duration: 10000,
        open: true,
      }),
    );
  };

  return (
    <div className={styles.container}>
      <Button icon="help" circular onClick={() => setOpen(true)} />
      <div className={styles.modal}>
        <Modal onClose={() => setOpen(false)} onOpen={() => setOpen(true)} open={open}>
          <Modal.Header>Feedback</Modal.Header>
          <Modal.Content>
            <Modal.Description className={styles.description}>
              <p>We&apos;d love to hear what you have to say about Drumroom. What can we do to make it better?</p>
            </Modal.Description>
            <TextArea
              placeholder="It would be cool if Drumroom had..."
              value={message}
              onChange={(event: any) => setMessage(event.target.value)}
              className={styles.textArea}
            />
          </Modal.Content>
          <Modal.Actions>
            <Button color="black" onClick={() => setOpen(false)}>
              Cancel
            </Button>
            <Button content="Send" labelPosition="right" icon="checkmark" onClick={send} positive />
          </Modal.Actions>
        </Modal>
      </div>
    </div>
  );
};

export default Feedback;
