import { ChangeEvent, useEffect, useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import {
  Form, Message, Button, Input, Icon, Dropdown, Confirm, TextArea, Segment, DropdownMenuProps,
} from "semantic-ui-react";
import { useAppSelector, useAppDispatch } from "store/hooks";
import _ from "lodash";
import STOCK_BEATS from "graphql/beat/queries/stockBeats";
import ALL_BEATS from "graphql/beat/queries/allBeats";
import CREATE_BEAT from "graphql/beat/mutations/create";
import OVERWRITE from "graphql/beat/mutations/overwrite";
import ALL_FOLDERS from "graphql/folder/queries/allFolders";
import STOCK_FOLDERS from "graphql/folder/queries/stockFolders";
import CREATE_FOLDER from "graphql/folder/mutations/create";
import {
  loadBeat,
  State as Beat,
} from "store/beat/slice";
import { State as Folder } from "store/folder/slice";
import {
  setSaveBeatOpen,
  setSaveBeatShown,
  setSaveStockBeatOpen,
  setSaveStockBeatShown,
} from "store/drumroom/slice";
import { sendAlert } from "store/alert/slice";
import { folderType } from "types/folder";

import "./CreateBeat.scss";
import styles from "./styles.module.scss";

interface Props {
  stock: boolean
}

interface DropdownFolder {
  key: number;
  value: number;
  text: string;
}

interface Error {
  path: "email" | "password";
  message: string;
}

interface FormErrors {
  nameError: string;
  folderError: string;
}

const CreateBeat = ({ stock }: Props) => {
  const {
    counting, metronome, subdivision, sticking, color, grid, comments, swing, folderId, bpm, notes, name, shared,
  } = useAppSelector((store) => store.beat.present);

  const {
    saveBeatOpen: open,
    saveStockBeatOpen: openStock,
    saveBeatShown: shown,
    saveStockBeatShown: shownStock,
    createAssignmentOpen,
    createAssignmentShown,
  } = useAppSelector((store) => store.drumroom.openTabs);

  const initialState = {
    folderId: name && !folderId ? 10000 : folderId,
    confirm: false,
    errors: {
      nameError: "",
      folderError: "",
    },
    name,
    comments,
    success: false,
    successName: "",
    confirmOpen: false,
    formOpen: false,
    dropdownFolders: [],
    shown: true,
  };
  const dispatch = useAppDispatch();
  const [state, setState] = useState(initialState);

  const [createBeat] = useMutation(CREATE_BEAT);
  const [overwriteBeat] = useMutation(OVERWRITE);
  const [createFolder] = useMutation(CREATE_FOLDER);
  const { loading, error, data } = useQuery(stock ? STOCK_FOLDERS : ALL_FOLDERS);

  useEffect(() => {
    setState({
      ...state,
      name,
      comments,
      folderId: name && !folderId ? 10000 : folderId,
    });
  }, [name]);

  const openConfirm = () => {
    setState({
      ...state,
      confirmOpen: true,
    });
  };

  const closeConfirm = () => {
    setState({
      ...state,
      confirmOpen: false,
    });
  };

  const toggleConfirm = () => {
    setState({
      ...state,
      confirm: !state.confirm,
    });
  };

  const onChange = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    e.preventDefault();
    const { name: currentName, value } = e.target;

    setState({
      ...state,
      [currentName]: value,
    });
  };

  const onFolderChange = (e: any, folderData: DropdownMenuProps) => {
    if (folderData.value) {
      setState({ ...state, folderId: folderData.value });
    } else {
      setState({ ...state, folderId: null });
    }
  };

  const handleOverwrite = async () => {
    const response = await overwriteBeat({
      variables: {
        bpm,
        color,
        comments: state.comments,
        counting,
        folderId: state.folderId === 10000 ? null : state.folderId, // if my beats folder, don't pass folderId
        grid,
        metronome,
        name: state.name,
        notes: JSON.stringify(notes),
        sticking,
        stock,
        subdivision,
        swing,
      },
    });

    const { beat: savedBeat } = response.data.overwriteBeat;

    dispatch(loadBeat({ ...savedBeat, notes: JSON.parse(savedBeat.notes) }));
    setState({
      ...state,
      errors: {
        nameError: "",
        folderError: "",
      },
      confirmOpen: false,
    });
    dispatch(sendAlert({
      message: "Beat overwritten successfully.",
      duration: 10000,
      open: true,
    }));
  };

  const onSubmit = async () => {
    setState({
      ...state,
      success: false,
    });

    // MUST select folder if saving stock beats
    if ((state.folderId && stock) || !stock) {
      const response = await createBeat({
        variables: {
          bpm,
          color,
          comments: state.comments,
          counting,
          folderId: state.folderId === 10000 ? null : state.folderId, // if my beats folder, don't pass folderId
          grid,
          metronome,
          name: state.name,
          notes: JSON.stringify(notes),
          sticking,
          stock,
          subdivision,
          swing,
        },

        update: (proxy, { data: { createBeat: createBeatResponse } }) => {
          const allBeatsNotIncludeStock = _.cloneDeep<{allBeats: Beat[] } | null>(
            proxy.readQuery({
              query: ALL_BEATS,
              variables: { includeStock: false },
            }),
          );

          allBeatsNotIncludeStock?.allBeats.push(createBeatResponse.beat);
          proxy.writeQuery({
            query: ALL_BEATS,
            data: allBeatsNotIncludeStock,
            variables: { includeStock: false },
          });

          const allBeatsIncludeStock = _.cloneDeep<{allBeats: Beat[] } | null>(
            proxy.readQuery({
              query: ALL_BEATS,
              variables: { includeStock: true },
            }),
          );

          if (allBeatsIncludeStock) {
            allBeatsIncludeStock.allBeats.push(createBeatResponse.beat);

            proxy.writeQuery({
              query: ALL_BEATS,
              data: allBeatsIncludeStock,
              variables: { includeStock: true },
            });
          }

          if (stock) {
            const dataStockBeats = _.cloneDeep<{stockBeats: Beat[] } | null>(proxy.readQuery({ query: STOCK_BEATS }));
            dataStockBeats?.stockBeats.push(createBeatResponse.beat);
            proxy.writeQuery({
              query: STOCK_BEATS,
              data: dataStockBeats,
            });
          }
        },
      });

      const { ok, errors, beat: savedBeat } = response.data.createBeat;

      if (ok) {
        dispatch(loadBeat({ ...savedBeat, notes: JSON.parse(savedBeat.notes) }));
        dispatch(sendAlert({
          message: "Beat saved successfully.",
          duration: 10000,
          open: true,
        }));
        setState({
          ...state,
          successName: name,
          errors: {
            nameError: "",
            folderError: "",
          },
        });
      } else {
        const err: { [char: string]: string } & FormErrors = {
          nameError: "",
          folderError: "",
        };
        errors.forEach(({ path, message }: Error) => {
          err[`${path}Error`] = message;
        });

        setState({
          ...state,
          errors: err,
        });

        if (err && err.nameError && err.nameError === "Beat already exists.") {
          openConfirm();
        }
      }
    } else if (stock) {
      const err: { [char: string]: string } & FormErrors = {
        nameError: "",
        folderError: "",
      };
      err.folderError = "Must select folder to save stock beats.";
      setState({
        ...state,
        errors: err,
      });
    }
  };

  const handleAddition = async (e: any, { value }: DropdownMenuProps) => {
    const response = await createFolder({
      variables: {
        name: value,
        stock: !!stock,
      },

      update: (store, { data: { createFolder: createFolderResponse } }) => {
        const { ok, folder } = createFolderResponse;
        if (!ok) {
          return;
        }

        const allFoldersData = store.readQuery<{allFolders: Folder[] }>({ query: ALL_FOLDERS });
        allFoldersData?.allFolders.push(folder);
        store.writeQuery({ query: ALL_FOLDERS, data: allFoldersData });
      },
    });

    if (response?.data?.createFolder?.folder) setState({ ...state, folderId: response.data.createFolder.folder.id });
  };
  const errorList = [];

  let nameError = "";
  let folderError = "";
  if (state.errors && state.errors.nameError) nameError = state.errors.nameError;
  if (state.errors && state.errors.folderError) folderError = state.errors.folderError;

  if (nameError) {
    errorList.push(nameError);
  } else if (folderError) {
    errorList.push(folderError);
  }

  // when openTabs updates, simply render own X position based on combo of open tabs.
  // basically a manual flexbox situation since all of these are in different spots in the DOM flow
  // CreateAssignment -> CreateBeat

  // just an update here ^^ there should be no reason why we can't actually use flexbox!

  let containerX = 0;

  if (stock && open) {
    containerX += 505;
    if (!shown) {
      containerX -= 200;
    }
  }

  if (createAssignmentOpen) {
    containerX += 505;
    if (!createAssignmentShown) {
      containerX -= 200;
    }
  }

  let foldersToRender = [];
  let dropdownFolders: DropdownFolder[] = [];
  if (data) {
    foldersToRender = stock ? data.stockFolders : data.allFolders;

    // add option for My Beats for if not create Stock Beat
    // it will save it into My Beats if there is no selection, but could be good to specify as well
    if (!stock) {
      dropdownFolders = [
        {
          key: 10000,
          value: 10000,
          text: "My Beats",
        },
      ];
    }

    foldersToRender.forEach((folder: folderType) => {
      dropdownFolders.push({
        key: folder.id,
        value: folder.id,
        text: folder.name,
      });
    });
  }

  // these will have independent values for stock beats, normal beats.
  // I reused this component and rendered it slightly differently for editing the stock beat library
  // versus normal library.

  // DEFINITELY a better solution is to only render one instance of the component, then simply add a checkbox
  // in it which will toggle between saving stock beats and update UI accordingly

  const isShown = stock ? shownStock : shown;
  const isOpen = stock ? openStock : open;

  return (
    <div className={styles.container}>
      {isOpen && (
        <div className="CreateBeat" style={{ left: containerX }}>
          <Segment.Group raised className={isShown ? "CreateAssignmentMax" : "CreateAssignmentMin"}>
            <Segment inverted color="grey" className="CreateAssignmentMenu">
              <h5 className="CreateAssignmentTitle">
                Save
                {stock && " Stock"}
                {" "}
                Beat
              </h5>
              <Icon
                link
                className="CreateAssignmentSizeIcon"
                name={isShown ? "minus" : "expand"}
                onClick={() => {
                  if (stock) dispatch(setSaveStockBeatShown(!shownStock));
                  if (!stock) dispatch(setSaveBeatShown(!shown));
                }}
              />
              <Icon
                link
                className="CreateAssignmentCloseIcon"
                name="close"
                // need to check if there are any state changes to prevent confirm from being rendered every time.
                // if stated has changed, render confirm. else, simply close window.
                onClick={() => {
                  // comparing state to initialState always yielded false even if the objects looked the same?
                  // need to compare keys/values directly.
                  // https://dmitripavlutin.com/how-to-compare-objects-in-javascript/

                  const isEqual = initialState.comments === state.comments
                    && initialState.name === state.name
                    && initialState.folderId === state.folderId;

                  if (isEqual) {
                    if (stock) {
                      dispatch(setSaveStockBeatOpen(false));
                      dispatch(setSaveStockBeatShown(true));
                    } else {
                      dispatch(setSaveBeatOpen(false));
                      dispatch(setSaveBeatShown(true));
                    }
                  } else {
                    toggleConfirm();
                  }
                }}
              />

              <Confirm
                open={state.confirm}
                content="You haven't saved your Beat. Are you sure you wish to discard?"
                confirmButton="Confirm"
                onCancel={toggleConfirm}
                onConfirm={() => setState((prevState) => {
                  if (stock) {
                    dispatch(setSaveStockBeatOpen(false));
                    dispatch(setSaveStockBeatShown(true));
                  } else {
                    dispatch(setSaveBeatOpen(false));
                    dispatch(setSaveBeatShown(true));
                  }

                  return {
                    ...initialState,
                    confirm: !prevState.confirm,
                  };
                })}
                style={{ width: "450px" }}
              />
            </Segment>

            {isShown && (
              <Segment className={isShown ? "CreateBeatContentShown" : "CreateBeatContentHidden"}>
                <Form className="CreateBeatForm">
                  <Form.Field error={!!nameError} className="CreateBeatInput">
                    <div>
                      <Input
                        name="name"
                        onChange={onChange}
                        value={state.name}
                        placeholder="Beat Name"
                        style={{ height: 40 }}
                      />
                    </div>
                  </Form.Field>

                  <Form.Field error={!!nameError} className="CreateBeatDropdownContainer">
                    {loading && !data && (
                      <Dropdown
                        placeholder="Select or Add Folder"
                        search
                        selection
                        clearable
                        allowAdditions
                        defaultValue={folderId || undefined}
                        style={{ height: 40 }}
                      />
                    )}

                    {error && <span>error...</span>}

                    {data && (
                      <Dropdown
                        placeholder="Select or Add Folder"
                        search
                        selection
                        clearable
                        allowAdditions
                        options={dropdownFolders}
                        onAddItem={handleAddition}
                        value={shared ? -1 : state.folderId || undefined}
                        onChange={onFolderChange}
                        className="CreateBeatDropdown"
                      />
                    )}
                  </Form.Field>

                  <Form.Field className="CreateBeatTextArea">
                    <TextArea
                      name="comments"
                      onChange={onChange}
                      value={state.comments}
                      placeholder="Comments"
                      style={{ minHeight: 100, maxHeight: 100 }}
                    />
                  </Form.Field>

                  <Form.Field>
                    <Button onClick={onSubmit}>
                      Save
                    </Button>
                  </Form.Field>
                </Form>
              </Segment>
            )}
          </Segment.Group>
        </div>
      )}

      <Confirm
        open={state.confirmOpen}
        content="There is already a beat saved with this name. Would you like to overwrite?"
        confirmButton="Overwrite"
        onCancel={closeConfirm}
        onConfirm={handleOverwrite}
        style={{ width: "450px" }}
      />

      <div className="CreateBeatErrorMsg">
        {errorList.length ? (
          <Message error header={(folderError || nameError) && "Something went wrong. Please reach out to our team!"} />
        ) : null}
      </div>
    </div>
  );
};

export default CreateBeat;
