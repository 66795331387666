import { useEffect } from "react";
import ReactGA from "react-ga";

export const initReactGa = () => {
  if (!window.location.href.includes("localhost")) {
    ReactGA.initialize(process.env.REACT_APP_GA_ID || "");
  }

  return ReactGA;
};

const useInitReactGa = () => {
  useEffect(() => {
    initReactGa();
  }, []);

  return ReactGA;
};

export default useInitReactGa;
