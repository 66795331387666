import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type State = Alert[]

const initialState: State = [];

interface Alert {
  message: string;
  duration: number;
  open: boolean;
}

export const alertSlice = createSlice({
  name: "alert",
  initialState,
  reducers: {
    sendAlert: (state, action: PayloadAction<Alert>) => {
      state.push(action.payload);
    },
  },
});

export const { sendAlert } = alertSlice.actions;

export default alertSlice.reducer;
