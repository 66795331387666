import { useState } from "react";
import { useQuery, useMutation } from "@apollo/client";
import { Icon } from "semantic-ui-react";
import ALL_EXERCISE_SONG_BEATS from "graphql/beat/queries/allExerciseSongBeats";
import UPDATE_FOLDER_ORDER from "graphql/folder/mutations/updateOrder";
import SHARED_FOLDERS from "graphql/folder/queries/sharedFolders";
import { searchBeatsFolders } from "utils/search";
import Folder from "screens/Main/BeatLibrary/Folder";
import Beat from "screens/Main/BeatLibrary/Beat";
import Header from "screens/Main/BeatLibrary/Header";
import styles from "./styles.module.scss";

interface Props {
  closeDeleteBeat: () => void;
  closeDeleteFolder: () => void;
  deleteBeatId: number;
  deleteFolderId: number;
  moveBeat: Function;
  openDeleteBeat: (id: number) => void;
  openDeleteFolder: (id: number) => void;
  type: number;
}

interface beatType {
  folderId: number;
  id: number;
  name: string;
  shared: boolean;
}

interface folderType {
  exercise: boolean;
  id: number;
  name: string;
  order: number;
  song: boolean;
}

const MyCurriculum = ({
  closeDeleteBeat,
  closeDeleteFolder,
  deleteBeatId,
  deleteFolderId,
  moveBeat,
  openDeleteBeat,
  openDeleteFolder,
  type,
}: Props) => {
  const { loading: loadingFolders, data: dataFolders } = useQuery(SHARED_FOLDERS, {
    variables: { student: type === 0 },
    fetchPolicy: "cache-and-network",
  });
  const { loading: loadingBeats, data: dataBeats } = useQuery(ALL_EXERCISE_SONG_BEATS);
  const [moveFolderMutation] = useMutation(UPDATE_FOLDER_ORDER);
  const [search, setSearch] = useState("");
  const [shown, setShown] = useState(true);
  const [exercisesShown, setExercisesShown] = useState(true);
  const [songsShown, setSongsShown] = useState(true);

  const moveFolder = async (dragged: number, droppedOn: number) => {
    await moveFolderMutation({
      variables: {
        dragged,
        droppedOn,
      },
      update: (client, { data: { updateFolderOrder } }) => {
        const { ok, folders } = updateFolderOrder;

        if (!ok) {
          return;
        }

        const newData = {
          stockFolders: folders,
        };

        client.writeQuery({
          query: SHARED_FOLDERS,
          data: newData,
        });
      },
    });
  };

  if (loadingFolders && !dataFolders) {
    return null;
  }

  if (loadingBeats && !dataBeats) {
    return null;
  }

  let foldersToRender = dataFolders.sharedFolders;
  let beatsToRender = dataBeats.allExerciseSongBeats;

  [beatsToRender, foldersToRender] = searchBeatsFolders(search, beatsToRender, foldersToRender);
  const exerciseFolders = foldersToRender.filter((folder: folderType) => folder.exercise === true);
  const songFolders = foldersToRender.filter((folder: folderType) => folder.song === true);

  return (
    <div>
      <Header
        title="My Curriculum"
        search={search}
        setSearch={setSearch}
        setShown={setShown}
        shown={shown}
      />

      {shown && (
        <div>
          <div>
            <div className={styles.subHeader}>
              Exercises
              <Icon
                link
                name={exercisesShown ? "minus" : "plus"}
                className={styles.showHideIcon}
                onClick={() => setExercisesShown(!exercisesShown)}
              />
            </div>

            {exercisesShown
              && exerciseFolders.map((folder: folderType) => (
                <div key={folder.name}>
                  <Folder
                    beats={beatsToRender
                      .filter((beat: beatType) => {
                        if (beat && beat.folderId === folder.id) {
                          return true;
                        }
                        return false;
                      })
                      .map((beat: beatType) => (
                        <div key={beat.name}>
                          <Beat
                            beat={beat}
                            closeDelete={closeDeleteBeat}
                            handleDrop={(beatId: number, folderId: number | null) => moveBeat(beatId, folderId, beat)}
                            hideDropdown
                            confirmOpen={deleteBeatId === beat.id}
                            openDelete={openDeleteBeat}
                          />
                        </div>
                      ))}
                    closeDelete={closeDeleteFolder}
                    folder={folder}
                    folders={foldersToRender}
                    moveFolder={moveFolder}
                    isConfirmOpen={deleteFolderId === folder.id}
                    openDelete={openDeleteFolder}
                    stock
                  />
                </div>
              ))}

            {exercisesShown && exerciseFolders && exerciseFolders.length === 0 && <p>You have no folders yet.</p>}

            <div className={styles.subHeader}>
              Songs
              <Icon
                link
                name={songsShown ? "minus" : "plus"}
                className={styles.showHideIcon}
                onClick={() => setSongsShown(!songsShown)}
              />
            </div>

            {songsShown
              && songFolders.map((folder: folderType) => (
                <div key={folder.name}>
                  <Folder
                    beats={beatsToRender
                      .filter((beat: beatType) => {
                        if (beat && beat.folderId === folder.id) {
                          return true;
                        }
                        return false;
                      })
                      .map((beat: beatType) => (
                        <div key={beat.name}>
                          <Beat
                            beat={beat}
                            closeDelete={closeDeleteBeat}
                            handleDrop={(beatId: number, folderId: number | null) => moveBeat(beatId, folderId, beat)}
                            hideDropdown
                            confirmOpen={deleteBeatId === beat.id}
                            openDelete={openDeleteBeat}
                          />
                        </div>
                      ))}
                    closeDelete={closeDeleteFolder}
                    folder={folder}
                    folders={foldersToRender}
                    moveFolder={moveFolder}
                    isConfirmOpen={deleteFolderId === folder.id}
                    openDelete={openDeleteFolder}
                    stock
                  />
                </div>
              ))}
            {songsShown && songFolders && songFolders.length === 0 && <p>You have no folders yet.</p>}
          </div>
        </div>
      )}
    </div>
  );
};

export default MyCurriculum;
