import { times } from "lodash";
import styles from "./styles.module.scss";

interface Props {
  beatIsEmpty: boolean[];
  division: number;
  isFirst: boolean;
}

const Counting = ({ beatIsEmpty, division, isFirst }: Props) => {
  const handleCount = (div: number, step: number) => {
    let count = "";
    // 16 note grid
    if (div === 16) {
      const stepIsCounted = !beatIsEmpty[step - 1];
      const subdivision = step % 4;
      switch (subdivision) {
        case 1:
          count = `${Math.floor(step / 4) + 1}`;
          break;
        case 2:
          if (stepIsCounted) {
            count = "e";
          }
          break;
        case 3:
          if (stepIsCounted) {
            count = "+";
          }
          break;
        case 0:
          if (stepIsCounted) {
            count = "a";
          }
          break;
        default:
          break;
      }
    } else {
      // 8th note grid
      const reduceToEighth = beatIsEmpty.filter((el: boolean, i: number) => i % 2 === 0);
      const stepIsCounted = !reduceToEighth[step - 1];
      const subdivision = step % 2;
      if (subdivision) {
        count = `${Math.floor(step / 2) + 1}`;
      } else if (stepIsCounted) {
        count = "+";
      }
    }

    return count;
  };

  return (
    <div className={`${styles.container} ${isFirst ? styles.first : ""} ${division === 16 ? styles.subdivision : ""}`}>
      {times(division, (index) => (
        <div
          key={index}
          className={styles.label}
        >
          {handleCount(division, index + 1)}
        </div>
      ))}
    </div>
  );
};

export default Counting;
