interface Props {
  fill: string;
  opacity: number;
  transformX: number;
  transformY: number
}

const EighthFlag = ({
  fill, opacity, transformX, transformY,
}: Props) => (
  <svg>
    <g
      id="g128"
      strokeMiterlimit="10"
      strokeLinejoin="round"
      strokeWidth="0"
      stroke="#2fa839"
      fillRule="evenodd"
      fillOpacity={opacity}
    >
      <path
        transform={`translate(${transformX}, ${transformY})`}
        /* eslint-disable max-len */
        d="m 69.95445669291338 15.75l 0 -3.75l 0.21 0l 0.21 0l 0 0.18c 0 0.3 0.06 0.84 0.12 1.23c 0.24 1.53 0.9 3.12 2.13 5.16l 0.99 1.59c 0.87 1.44 1.38 2.34 1.77 3.09c 0.81 1.68 1.2 3.06 1.26 4.53c 0.03 1.53 -0.21 3.27 -0.75 5.01c -0.21 0.69 -0.51 1.5 -0.6 1.59c -0.09 0.12 -0.27 0.21 -0.42 0.21c -0.15 0 -0.42 -0.12 -0.51 -0.21c -0.15 -0.18 -0.18 -0.42 -0.09 -0.66c 0.15 -0.33 0.45 -1.2 0.57 -1.62c 0.42 -1.38 0.6 -2.58 0.6 -3.9c 0 -0.66 0 -0.81 -0.06 -1.11c -0.39 -2.07 -1.8 -4.26 -4.59 -7.14l -0.42 -0.45l -0.21 0l -0.21 0l 0 -3.75z"
        id="path130"
        stroke="#000000"
        fill={fill}
      />
    </g>
  </svg>
);

export default EighthFlag;
