import gql from "graphql-tag";

const UPDATE_PASSWORD = gql`
  mutation(
    $password: String!,
    $confirmPassword: String!,
    $email: String!,
    $firstName: String,
    $lastName: String
  ) {
    updatePassword(
      password: $password,
      confirmPassword: $confirmPassword,
      email: $email,
      firstName: $firstName,
      lastName: $lastName
    ) {
      ok
      user {
        email
      }
      errors {
        path
        message
      }
    }
  }
`;

export default UPDATE_PASSWORD;
