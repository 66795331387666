import { useState, ChangeEvent, CSSProperties } from "react";
import {
  Form, Message, Button, Input, Header as SemanticHeader,
} from "semantic-ui-react";
import { Link, useHistory } from "react-router-dom";
import { useMutation } from "@apollo/client";
import VALIDATE from "graphql/user/mutations/validate";
import REMOVE_INVALID_USER from "graphql/user/mutations/removeInvalidUser";
import RESEND_VALIDATE_EMAIL from "graphql/user/mutations/resendValidateEmail";
import CREATE_NOTIFICATION from "graphql/notification/mutations/create";

import background from "images/loginBg.png";
import AnimatedConfirmMsg from "components/AnimatedConfirmMsg";
import AnimatedConfirmMsgFadeOut from "components/AnimatedConfirmMsgFadeOut";
import Header from "components/Header";

const Validate = () => {
  const [state, setState] = useState({
    email: "",
    emailError: "",
    confirmEmail: "",
    confirmEmailError: "",
    password: "",
    confirmPassword: "",
    passwordError: "",
    validateView: false,
    validated: false,
    validateError: "",
    token: "",
    resent: false,
  });

  const history = useHistory();
  const [validateUser] = useMutation(VALIDATE);
  const [resendValidateEmail] = useMutation(RESEND_VALIDATE_EMAIL);
  const [removeInvalUser] = useMutation(REMOVE_INVALID_USER);
  const [createNotification] = useMutation(CREATE_NOTIFICATION);

  const updateField = (e: ChangeEvent<HTMLInputElement>) => setState({
    ...state,
    [e.target.name]: e.target.value,
  });

  const onValidate = async () => {
    const response = await validateUser({
      variables: { token: state.token },
    });

    const { ok, user } = response.data.validateRegister;

    if (ok) {
      if (user.type === 0) {
        createNotification({
          variables: {
            messageType: "NEW_STUDENT",
            type: "teacher", // indicates the type of target account
            senderName: `${user.firstName} ${user.lastName}`,
            senderId: user.id,
            payloadName: `${user.firstName} ${user.lastName}`,
            payloadType: "user",
          },
        });
      }

      localStorage.clear();
      setState({ ...state, validated: true });
    } else {
      setState({
        ...state,
        validateError: "There was an error validating your email. Please ensure you have copied the correct code.",
      });
    }
  };

  const onResend = async () => {
    const validateToken = localStorage.getItem("validateToken");
    const response = await resendValidateEmail({
      variables: { validateToken },
    });

    const { ok } = response.data.resendValidateEmail;
    if (ok) {
      setState({ ...state, resent: false });
      setState({ ...state, resent: true });
    }
  };

  const onReturn = async () => {
    const validateToken = localStorage.getItem("validateToken");
    const response = await removeInvalUser({
      variables: { validateToken },
    });

    const { ok } = response.data.removeInvalUser;
    if (ok) {
      localStorage.clear();
      history.push("/register");
    }
  };

  const errorList = [];

  const backgroundImgStyle: CSSProperties = {
    position: "absolute",
    width: "100%",
    height: "100%",
    objectFit: "cover",
  };

  if (state.emailError) {
    errorList.push(state.emailError);
  }
  if (state.passwordError) {
    errorList.push(state.passwordError);
  }

  if (state.confirmEmailError) {
    errorList.push(state.confirmEmailError);
  }

  let panelHeight = -1;
  let panelWidth = 550;

  if (!state.validated) {
    panelHeight = 265;
    if (state.validateError) {
      panelHeight = 370;
    }
  } else if (state.validated) {
    panelHeight = 75;
    panelWidth = 330;
  }

  return (
    <div>
      <img src={background} style={backgroundImgStyle} alt="LoginBg" />
      <Header marketing />

      <div className="RegisterPanel" style={{ height: panelHeight, width: panelWidth }}>
        {!state.validated && (
          <div style={{ justifyContent: "center" }}>
            <Form>
              <SemanticHeader as="h2">Validate Email</SemanticHeader>
              <p>
                {" "}
                Please enter the code we sent to your email. This will let us know you have access to the email you
                signed up with.
              </p>
              <Form.Field error={!!state.emailError}>
                <Input name="token" onChange={updateField} value={state.token} placeholder="Enter Code" fluid />
              </Form.Field>

              <Form.Field>
                <Button onClick={onValidate}>Validate</Button>
              </Form.Field>
              <Form.Field>
                <Button onClick={onResend} className="ResendEmailButton">
                  Resend Email
                </Button>
              </Form.Field>
              <Form.Field>
                {/* eslint-disable-next-line */}
                <Link to="#" onClick={onReturn} className="ReturnToRegistration">
                  Don&apos;t have access to this email? Click to return to registration.
                </Link>
              </Form.Field>

              {state.resent && (
                <div className="EmailResentMessage">
                  <AnimatedConfirmMsgFadeOut message="Email Resent." />
                </div>
              )}
            </Form>

            {state.validateError ? (
              <Message
                error
                header="There were some errors with your submission. "
                style={{
                  position: "relative",
                  top: "0px",
                  left: "0px",
                  height: "90px",
                  width: "100%",
                }}
                list={[state.validateError]}
              />
            ) : null}
          </div>
        )}

        {state.validated && (
          <div style={{ justifyContent: "center" }}>
            <Link style={{ position: "relative", top: "-20px", padding: "30px" }} to="/login">
              <AnimatedConfirmMsg message="Registration successful. Click to login." />
            </Link>
          </div>
        )}
      </div>
    </div>
  );
};

export default Validate;
