import decode from "jwt-decode";

const isAuthenticated = () => {
  let result = false;
  const token = localStorage.getItem("token") || "";
  const refreshToken = localStorage.getItem("refreshToken") || "";

  try {
    decode(token);
    decode(refreshToken);
    result = true;
  } catch {
    result = false;
  }

  return result;
};

export default isAuthenticated;
