import { times } from "lodash";
import { Measure } from "store/beat/slice";
import getMeasureScaleFactor from "utils/getMeasureScaleFactor";

import styles from "./styles.module.scss";

interface Props {
  isFirst: boolean;
  measure: Measure;
  readOnly: boolean,
  showSticking: boolean[];
  subdivision: boolean;
  updateNotes: (a: number, b: number, c?: string, add?: boolean) => void;
}

const Sticking = ({
  isFirst,
  measure,
  readOnly,
  showSticking,
  subdivision,
  updateNotes,
}: Props) => {
  const modifier = subdivision ? 1 : 2;
  const totalDivisions = measure.hiHat.length / modifier;
  const scale = getMeasureScaleFactor();

  const handleClick = (i: number) => { if (!readOnly) updateNotes(6, i * modifier); };

  return (
    <div className={`${styles.container} ${isFirst ? styles.first : ""} ${subdivision ? styles.subdivision : ""}`}>
      {isFirst && (
        <p className="GridLabel" style={{ fontSize: 11 * scale ** 0.4 }}>
          Sticking
        </p>
      )}
      {times(totalDivisions, (i) => (
        <button key={`${Math.random()}`} type="button" className={styles.label} onClick={() => handleClick(i)}>
          {
            showSticking[i * modifier] && (
              measure.sticking[i * modifier] === 0
                ? "-"
                : measure.sticking[i * modifier] === 1
                  ? "R"
                  : "L"
            )
          }
        </button>
      ))}
    </div>
  );
};

export default Sticking;
