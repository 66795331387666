import { ReactElement, ReactNode } from "react";
import { DropTarget } from "react-dnd";
import { folderType } from "types/folder";

function collect(
  connect: { dropTarget: () => any },
  monitor: { isOver: () => boolean; getItem: () => void; didDrop: () => boolean },
) {
  return {
    connectDropTarget: connect.dropTarget(),
    hovered: monitor.isOver(),
    draggedItem: monitor.getItem(),
    dropped: monitor.didDrop(),
    isOver: monitor.isOver(),
  };
}

interface Props {
  connectDropTarget: (a: ReactNode) => ReactElement<any, any> | null;
  hovered: boolean;
  draggedItem: any;
  folderId: number;
  folders: folderType[];
  numberOfBeats: number;
  active: boolean;
}

const Target = ({
  connectDropTarget,
  hovered,
  draggedItem,
  folderId,
  folders,
  numberOfBeats,
  active,
}: Props) => {
  // Logic to control height of the border that appears when hovering over a folder when dragging beats.
  // changes based on whether the folder is active and how many beats are in it.
  const borderHeight = active && draggedItem ? 70 + numberOfBeats * 30 : 40;
  const borderStyle = hovered ? "3px solid grey" : "";

  // select the folder being hovered over from array of all folders
  const hoveredFolder = folders.filter((folder) => {
    if (folder.id === folderId) {
      return folder;
    }
    return false;
  });

  let beatHoveredStyle = {};
  let moveCircleStyle = {};
  let targetShouldRender = true;

  if (draggedItem?.folderId === folderId) {
    // don't render the target for the folder the beat is dragged from.
    targetShouldRender = false;

    // eslint-disable-next-line no-underscore-dangle
  } else if (draggedItem?.__typename === "Beat") {
    beatHoveredStyle = {
      zIndex: 10, //
      height: `${borderHeight}px`,
      width: "325px",
      borderRadius: "4px",
      opacity: 1,
      position: "absolute",
      border: borderStyle,
    };

    // otherwise, a folder is being dropped. if the order of the dragged folder is higher than the hovered folder,
    // render the bar at top of hovered folder. If the order of the dragged folder is lower than the hovered folder,
    // render the bar at bottom of hovered folder.

    // dragging folders
  } else if (draggedItem?.order > hoveredFolder[0].order) {
    beatHoveredStyle = {
      zIndex: 10,
      height: `${borderHeight}px`,
      width: "297px",
      opacity: 1,
      position: "absolute",
      borderTop: hovered ? "3px solid purple" : "",
    };

    moveCircleStyle = {
      position: "relative",
      top: "-2px",
      left: "-5px",
      borderRadius: "50%",
      width: "6px",
      height: "6px",
      opacity: hovered ? 1 : 0,
      backgroundColor: "purple",
      float: "left",
    };
  } else if (draggedItem?.order < hoveredFolder[0].order) {
    beatHoveredStyle = {
      zIndex: 10,
      height: `${borderHeight + 2}px`,
      width: "297px",
      opacity: 1,
      position: "absolute",
      borderBottom: hovered ? "3px solid purple" : "",
    };

    moveCircleStyle = {
      position: "relative",
      top: `${borderHeight - 3}px`,
      left: "-5px",
      borderRadius: "50%",
      width: "6px",
      height: "6px",
      opacity: hovered ? 1 : 0,
      backgroundColor: "purple",
      float: "left",
    };
  }

  if (draggedItem) {
    return connectDropTarget(
      targetShouldRender && (
        <div className="Target">
          <div style={beatHoveredStyle} />
          <div style={moveCircleStyle} />
        </div>
      ),
    );
  }
  return (
    targetShouldRender ? (
      <div className="Target">
        <div style={beatHoveredStyle} />
        <div style={moveCircleStyle} />
      </div>
    ) : <></>
  );
};

export default DropTarget(
  ["folder", "beat"],
  {
    drop({ folderId }: { folderId: number }) {
      return {
        target: "FOLDER",
        payload: folderId,
      };
    },
  },
  collect,
)(Target);
