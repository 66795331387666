import gql from "graphql-tag";

const SHARED_FOLDERS = gql`
  query($student: Boolean) {
    sharedFolders(student: $student) {
      id
      name
      order
      exercise
      song
    }
  }
`;

export default SHARED_FOLDERS;
