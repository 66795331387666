import { useState } from "react";
import { Menu, Segment, MenuItemProps } from "semantic-ui-react";
import { Box } from "@material-ui/core";
import Header from "components/Header";
import useRequireActiveSubscription from "hooks/useRequireActiveSubscription";

import Form from "./Form";

const Settings = () => {
  useRequireActiveSubscription();
  const [activeItem, setActiveItem] = useState("account");

  const handleItemClick = (_: any, { name }: MenuItemProps) => setActiveItem(name || "");

  return (
    <>
      <Header />
      <Box mx={2} my={2}>
        <Menu attached="top" tabular>
          <Menu.Item name="account" active={activeItem === "account"} onClick={handleItemClick} />
        </Menu>

        <Segment attached="bottom">
          <Form />
        </Segment>
      </Box>
    </>
  );
};

export default Settings;
