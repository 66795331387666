import { Measure, instrumentKeys } from "store/beat/slice";
import { INSTRUMENT_TO_COLOR_MAP } from "./constants";

const sixteenth = (measure: Measure, colored: boolean, opacity: number[]) => {
  let beat = [];
  const noteDecorationFill = [];
  let topNoteIndex = 0;
  let distance = 0;
  let distanceOpacity = 0;
  let first = 4; // first is 4 here if no hihats are in the beat.
  let firstOpacity = 4;
  let foundFirst = false;
  let foundFirstOpacity = false;
  const topNotes = [];
  const topOpacity = [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1]; // 12 bars, 3 per beat
  for (let i = 0; i < 16; i += 1) {
    // on every downbeat
    if (i % 4 === 0) {
      distance = 0;
      first = 4;
      foundFirst = false;
      distanceOpacity = 0;
      firstOpacity = 4;
      foundFirstOpacity = false;
      // look ahead to next three beats

      for (let j = 0; j < 4; j += 1) {
        beat = [measure.hiHat[i + j], measure.snare[i + j], measure.bass[i + j]];
        topNoteIndex = beat.findIndex((e) => e !== "");

        if (opacity[i + j] === 1 && !foundFirstOpacity) {
          firstOpacity = j;
          foundFirstOpacity = true;
        } else if (opacity[i + j] === 1) {
          distanceOpacity = j - firstOpacity;
          topOpacity[i + j] = 1;
        } else {
          topOpacity[i + j] = 0.1;
        }

        // set distance to next hihat
        if (topNoteIndex === 0 && !foundFirst) {
          first = j;
          foundFirst = true;
        } else if (topNoteIndex === 0) {
          distance = j - first;
        }
      }
    }

    beat = instrumentKeys.map((instrument) => measure[instrument][i]);
    topNoteIndex = beat.findIndex((e) => e !== "");

    if (colored) {
      noteDecorationFill[i] = Object.values(INSTRUMENT_TO_COLOR_MAP)[topNoteIndex];

      if (i % 4 >= first && i % 4 <= first + distance) {
        noteDecorationFill[i] = INSTRUMENT_TO_COLOR_MAP.hiHat;
      }
    } else {
      noteDecorationFill[i] = "#000000";
    }

    if (i % 4 >= firstOpacity && i % 4 <= firstOpacity + distanceOpacity) {
      topOpacity[i] = 1;
    }

    topNotes.push(topNoteIndex);
  }

  return { noteDecorationFill, topOpacity, topNotes };
};

const eighth = (measure: Measure, colored: boolean) => {
  let beat = [];
  const noteDecorationFill = [];
  let topNoteIndex = 0;
  for (let i = 0; i < measure.hiHat.length; i += 1) {
    beat = instrumentKeys.map((instrument) => measure[instrument][i]);
    topNoteIndex = beat.findIndex((e) => e !== "");

    if (colored) {
      noteDecorationFill[i] = Object.values(INSTRUMENT_TO_COLOR_MAP)[topNoteIndex];
    } else {
      noteDecorationFill[i] = "#000000";
    }
  }

  return { noteDecorationFill, topOpacity: [], topNotes: [] };
};

const calculateNoteDecorationFill = (
  subdivision: boolean, measure: Measure, colored: boolean, opacity?: number[],
) => (subdivision && opacity ? sixteenth(measure, colored, opacity) : eighth(measure, colored));

export default calculateNoteDecorationFill;
