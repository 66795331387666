import { useState } from "react";
import {
  Segment, Icon, Confirm,
} from "semantic-ui-react";
import { useAppSelector, useAppDispatch } from "store/hooks";
import { setCreateAssignmentOpen, setCreateAssignmentShown } from "store/drumroom/slice";
import Form from "./Form";
import "./CreateAssignment.scss";

const CreateAssignment = () => {
  const [state, setState] = useState({
    confirm: false,
  });

  const open = useAppSelector((store) => store.drumroom.openTabs.createAssignmentOpen);
  const shown = useAppSelector((store) => store.drumroom.openTabs.createAssignmentShown);
  const dispatch = useAppDispatch();

  const toggleConfirm = () => {
    setState({
      ...state,
      confirm: !state.confirm,
    });
  };

  return (
    <div className="CreateAssignment">
      {open && (
      <Segment.Group raised className={shown ? "CreateAssignmentMax" : "CreateAssignmentMin"}>
        <Segment inverted color="grey" className="CreateAssignmentMenu">
          <h5 className="CreateAssignmentTitle">Create Assignment</h5>
          <Icon
            link
            className="CreateAssignmentSizeIcon"
            name={shown ? "minus" : "expand"}
            onClick={() => dispatch(setCreateAssignmentShown(!shown))}
          />
          <Icon
            link
            className="CreateAssignmentCloseIcon"
            name="close"
            onClick={() => {
              dispatch(setCreateAssignmentShown(true));
              dispatch(setCreateAssignmentOpen(false));
            }}
          />
          <Confirm
            open={state.confirm}
            content="You haven't sent your assignment. Are you sure you wish to discard?"
            confirmButton="Confirm"
            onCancel={toggleConfirm}
            onConfirm={() => {
              setState({ confirm: false });
              dispatch(setCreateAssignmentShown(true));
              dispatch(setCreateAssignmentOpen(false));
            }}
            style={{ width: "450px" }}
          />
        </Segment>
        <Segment className={shown ? "CreateAssignmentContentShown" : "CreateAssignmentContentHidden"}>
          <Form />
        </Segment>
      </Segment.Group>
      )}
    </div>
  );
};

export default CreateAssignment;
