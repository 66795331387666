import { useState } from "react";
import { Button, Icon } from "semantic-ui-react";
import AnimatedConfirmMsgFadeOut from "components/AnimatedConfirmMsgFadeOut";
import styles from "./styles.module.scss";

interface Props {
  link: string;
  title?: string;
  description?: string;
}

const ShareableLink = ({ link, title, description }: Props) => {
  const [copied, setCopied] = useState(0);

  const copyToClipboard = () => {
    navigator.clipboard.writeText(link);
    setCopied(copied + 1);
  };

  return (
    <>
      {title && <h1>{title}</h1>}
      {description && <p>{description}</p>}
      <div className={styles.copiedLinkMsg}>
        {!!copied && <AnimatedConfirmMsgFadeOut message="Copied!" key={copied} />}
      </div>
      <div className={styles.container}>
        <a href={link}>{link}</a>
      </div>
      <Button onClick={copyToClipboard}>
        Copy
        {" "}
        <Icon name="copy" />
      </Button>
    </>
  );
};

export default ShareableLink;

ShareableLink.defaultProps = {
  title: null,
  description: null,
};
