import { useApolloClient } from "@apollo/client";
import { Icon, Popup } from "semantic-ui-react";
import { useAppSelector, useAppDispatch } from "store/hooks";
import GET_BEAT from "graphql/beat/queries/get";
import { getComments } from "store/beat/selectors";
import { loadBeat } from "store/beat/slice";
import { Anchorme } from "react-anchorme";
import { ActionCreators } from "redux-undo";
import ClearGrid from "./ClearGrid";
import CurrentBeatSharingActions from "../CurrentBeatSharingActions";
import styles from "./styles.module.scss";

interface Props {
  readOnly: boolean;
}

const BeatHeaderBar = ({ readOnly }: Props) => {
  const client = useApolloClient();
  const dispatch = useAppDispatch();
  const name = useAppSelector((state) => state.beat.present.name);
  const id = useAppSelector((state) => state.beat.present.id);
  const comments = useAppSelector(getComments);

  const reloadBeat = async () => {
    if (id > 0) {
      const { data } = await client.query({
        query: GET_BEAT,
        variables: { id },
      });

      if (data && data.getBeat && data.getBeat.beat) {
        dispatch(loadBeat({ ...data.getBeat.beat, notes: JSON.parse(data.getBeat.beat.notes) }));
      }
    }
  };

  const Comments = (
    <Anchorme target="_blank" rel="noreferrer noopener">
      {comments}
    </Anchorme>
  );

  return (
    <div>
      {!readOnly && <CurrentBeatSharingActions />}

      <div className={styles.container}>
        <h5>{name}</h5>
        {!readOnly && (
          <>
            <Popup
              content="Load Original"
              on="hover"
              position="bottom center"
              trigger={(
                <Icon
                  aria-label="load original"
                  circular
                  className={styles.reloadIcon}
                  link
                  name="history"
                  onClick={() => reloadBeat()}
                />
              )}
            />
            <Popup
              content="Undo"
              on="hover"
              position="bottom center"
              trigger={(
                <Icon
                  aria-label="undo"
                  circular
                  className={styles.undoIcon}
                  link
                  name="undo"
                  onClick={() => dispatch(ActionCreators.undo())}
                />
              )}
            />
            <Popup
              content="Redo"
              on="hover"
              position="bottom center"
              trigger={(
                <Icon
                  aria-label="redo"
                  circular
                  className={styles.redoIcon}
                  link
                  name="redo"
                  onClick={() => dispatch(ActionCreators.redo())}
                />
              )}
            />
            <Popup
              content={comments ? Comments : "This beat has no comments."}
              mouseEnterDelay={0}
              mouseLeaveDelay={0}
              on="click"
              position="bottom center"
              trigger={(
                <Icon
                  aria-label="comments"
                  circular
                  className={styles.infoIcon}
                  link
                  name="info"
                />
              )}
            />
            <ClearGrid />
          </>
        )}
      </div>
    </div>
  );
};

export default BeatHeaderBar;
