import {
  useState, useEffect, CSSProperties, ChangeEvent,
} from "react";
import {
  Form as SemanticForm, Button, Input, Header as SemanticHeader, Message,
} from "semantic-ui-react";
import { useMutation } from "@apollo/client";

import { Link } from "react-router-dom";
import UPDATE_PASSWORD from "graphql/user/mutations/updatePassword";
import background from "images/loginBg.png";
import Header from "components/Header";
import AnimatedConfirmMsg from "components/AnimatedConfirmMsg";

interface Error {
  path: "email" | "password";
  message: string;
}

interface FormErrors {
  passwordError: string;
  emailError: string;
}

interface Props {
  email: string;
  isAccountCreatedFromAPI: boolean;
}

const Form = ({ email, isAccountCreatedFromAPI }: Props) => {
  const [state, setState] = useState({
    confirmPassword: "",
    done: false,
    firstNameError: "",
    lastNameError: "",
    password: "",
    passwordError: "",
    ...isAccountCreatedFromAPI && { firstName: "", lastName: "" },
  });

  useEffect(() => {
    setState({
      ...state,
      passwordError: (state.password === state.confirmPassword) ? "" : "Passwords do not match.",
    });
  }, [state.password, state.confirmPassword]);

  const [updatePassword] = useMutation(UPDATE_PASSWORD, { errorPolicy: "all" });

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };

  const onSubmit = async () => {
    setState({
      ...state,
      passwordError: "",
    });
    const {
      password, confirmPassword, firstName, lastName,
    } = state;

    if (password === confirmPassword) {
      const response = await updatePassword({
        variables: {
          password, confirmPassword, email, firstName, lastName,
        },
      });

      if (response && response.data && response.data.updatePassword && response.data.updatePassword.ok) {
        setState({
          ...state,
          done: true,
        });
      } else {
        const err: { [char: string]: string } & FormErrors = {
          emailError: "",
          passwordError: "",
        };

        if (response?.data?.updatePassword?.errors) {
          const { errors } = response.data.updatePassword;
          errors.forEach(({ path, message }: Error) => {
            err[`${path}Error`] = message;
          });

          setState({
            ...state,
            firstNameError: err.firstNameError,
            lastNameError: err.lastNameError,
            passwordError: err.passwordError,
          });
        }
      }
    }
  };

  const {
    password, confirmPassword, passwordError, firstNameError, lastNameError,
  } = state;

  const error = firstNameError || passwordError || lastNameError;

  const backgroundImgStyle: CSSProperties = {
    position: "absolute",
    width: "100%",
    height: "100%",
    objectFit: "cover",
  };

  return (
    <div>
      <img src={background} style={backgroundImgStyle} alt="LoginBg" />
      <Header marketing />

      <div className="ResetPasswordFormPanel">
        {!state.done && (
          <SemanticForm>
            <SemanticHeader as="h2">
              {isAccountCreatedFromAPI ? "Finish setting up your account" : "Choose a New Password" }
            </SemanticHeader>

            <SemanticForm.Field>
              <Input
                aria-label="email"
                disabled
                fluid
                name="email"
                placeholder="Email"
                value={email}
              />
            </SemanticForm.Field>

            {isAccountCreatedFromAPI && (
              <>
                <SemanticForm.Field error={!!firstNameError}>
                  <Input
                    aria-label="first-name"
                    name="firstName"
                    onChange={onChange}
                    value={state.firstName}
                    placeholder="First Name"
                    fluid
                  />
                </SemanticForm.Field>

                <SemanticForm.Field error={!!lastNameError}>
                  <Input
                    aria-label="last-name"
                    name="lastName"
                    onChange={onChange}
                    value={state.lastName}
                    placeholder="Last Name"
                    fluid
                  />
                </SemanticForm.Field>
              </>
            )}

            <SemanticForm.Field error={!!passwordError}>
              <Input
                name="password"
                onChange={onChange}
                value={password}
                type="password"
                placeholder="Password"
                fluid
              />
            </SemanticForm.Field>

            <SemanticForm.Field error={!!passwordError}>
              <Input
                name="confirmPassword"
                onChange={onChange}
                value={confirmPassword}
                type="password"
                placeholder="Re-enter Password"
                fluid
              />
            </SemanticForm.Field>

            <SemanticForm.Field>
              <Button onClick={onSubmit}>Submit</Button>
            </SemanticForm.Field>

            {error && (
              <Message
                className="ui warning visible message"
                error
                list={[passwordError, firstNameError, lastNameError].filter(Boolean)}
                style={{
                  position: "relative",
                  top: "0px",
                  left: "0px",
                  width: "100%",
                }}
              />
            )}
          </SemanticForm>
        )}

        {state.done && (
          <div>
            <Link to="/login">
              <AnimatedConfirmMsg message="Account details updated successfully! Click to login." />
            </Link>
          </div>
        )}
      </div>
    </div>
  );
};

export default Form;
