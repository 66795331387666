import { useAppSelector, useAppDispatch } from "store/hooks";
import { times } from "lodash";

import { ReactComponent as PercussionClef } from "svg/percussion-clef.svg";
import { ReactComponent as FourFour } from "svg/four-four.svg";
import Measure from "svg/measure";
import EighthRest from "svg/rests/Eighth";
import EighthFlag from "svg/notes/EighthFlag";
import Quarter from "svg/rests/Quarter";
import OneAnd from "svg/bars/OneAnd";
import calculateNoteOpacity from "utils/calculateNoteOpacity";
import calculateNoteDecorationFill from "utils/calculateNoteDecorationFill";
import isBeatPattern from "utils/isBeatPattern";
import { Measure as IMeasure, setActiveNote } from "store/beat/slice";
import getMeasureScaleFactor from "utils/getMeasureScaleFactor";
import removeOddNotesIfApplies from "utils/removeOddNotesIfApplies";
import { INSTRUMENT_KEYS } from "utils/constants";
import Note from "screens/Main/NoteGrid/Note";

import styles from "./styles.module.scss";

interface Props {
  measure: IMeasure;
  colored: boolean;
  showClefs: boolean;
}

const StaffSvg = ({
  measure: measure16, colored, showClefs,
}: Props) => {
  const dispatch = useAppDispatch();
  const measure = removeOddNotesIfApplies(measure16, false);
  const scale = getMeasureScaleFactor();

  const countingIn = useAppSelector((state) => state.drumroom.countingIn);
  const opacity = calculateNoteOpacity(countingIn, 8, measure.isolate);
  const { noteDecorationFill } = calculateNoteDecorationFill(false, measure, colored);

  const isEmpty = Object.keys(measure).every((key) => measure[key].every((e: string | number) => e === ""));

  return (
    <div className={`${styles.staffSvgContainer} staff-container`}>
      <svg
        height={scale * 85}
        viewBox={`0 0 ${(showClefs ? 300 : 240)} 85`}
        width={scale * (showClefs ? 300 : 240)}
        xmlns="http://www.w3.org/2000/svg"
      >
        <Measure showClefs={showClefs} />
        {showClefs && <PercussionClef />}
        {showClefs && <FourFour />}

        <g transform={`translate(${showClefs ? 10 : -50}, 0)`}>
          {/* eslint-disable react/jsx-key */}
          {INSTRUMENT_KEYS.flatMap(
            (instrumentType, instrumentIndex) => measure[instrumentType].map(
              (note, index) => note && (
                <Note
                  note={note}
                  instrumentType={instrumentType}
                  colored={colored}
                  index={index}
                  onClick={
                    () => dispatch(
                      setActiveNote({
                        index: index * 2,
                        inst: INSTRUMENT_KEYS.length - instrumentIndex - 1,
                      }),
                    )
                  }
                  opacity={opacity[index]}
                />
              ),
            ),
          )}
          {/* eslint-enable react/jsx-key */}

          {times(4, (n) => (
            <>
              {
                isBeatPattern("+", 2 * n, measure, false) && (
                  <>
                    <EighthFlag
                      opacity={opacity[1 + 2 * n]}
                      transformX={30.4 + 60 * n}
                      transformY={10}
                      fill={noteDecorationFill[1 + 2 * n]}
                    />
                    <EighthRest opacity={opacity[2 * n]} transform={`translate(${60 * n - 30}, 0)`} />
                  </>
                )
              }

              {
                isBeatPattern("1+", 2 * n, measure, false) && (
                  <OneAnd
                    n={2 * n}
                    noteDecorationFill={noteDecorationFill}
                    opacity={opacity}
                    subdivision={false}
                  />
                )
              }

              {
                isBeatPattern("", 2 * n, measure, false) && !isEmpty && (
                  <Quarter
                    opacity={opacity[2 * n] || opacity[1 + 2 * n]}
                    transform={`translate(${-420 + 60 * n}, -77)`}
                  />
                )
              }
            </>
          ))}
        </g>
      </svg>
    </div>
  );
};

export default StaffSvg;
