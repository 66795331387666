import { useState } from "react";
import { useQuery, useMutation } from "@apollo/client";
import ALL_BEATS from "graphql/beat/queries/allBeats";
import UPDATE_ORDER from "graphql/folder/mutations/updateOrder";
import ALL_FOLDERS from "graphql/folder/queries/allFolders";
import { searchBeatsFolders } from "utils/search";
import Beat from "screens/Main/BeatLibrary/Beat";
import MyBeatsFolder from "screens/Main/BeatLibrary/MyBeatsFolder";
import Folder from "screens/Main/BeatLibrary/Folder";
import Header from "screens/Main/BeatLibrary/Header";
import Section from "screens/Main/BeatLibrary/Section";

interface Props {
  closeDeleteBeat: () => void;
  closeDeleteFolder: () => void;
  deleteBeatId: number;
  deleteFolderId: number;
  moveBeat: Function;
  openDeleteBeat: (id: number) => void;
  openDeleteFolder: (id: number) => void;
  type: number;
}

interface beatType {
  id: number;
  name: string;
  folderId: number;
  shared: boolean;
}

interface folderType {
  id: number;
  name: string;
  order: number;
  exercise: boolean;
  song: boolean;
}

const MyCustomBeats = ({
  closeDeleteBeat,
  closeDeleteFolder,
  deleteBeatId,
  deleteFolderId,
  moveBeat,
  openDeleteBeat,
  openDeleteFolder,
  type,
}: Props) => {
  const { loading: loadingFolders, data: dataFolders } = useQuery(ALL_FOLDERS, {
    fetchPolicy: "cache-and-network",
  });

  const { loading: loadingBeats, data: dataBeats } = useQuery(ALL_BEATS, {
    variables: { includeStock: false },
  });

  const [moveFolderMutation] = useMutation(UPDATE_ORDER);
  const [search, setSearch] = useState("");
  const [shown, setShown] = useState(true);
  const [exercisesShown, setExercisesShown] = useState(true);
  const [songsShown, setSongsShown] = useState(true);

  const moveFolder = async (dragged: number, droppedOn: number) => {
    await moveFolderMutation({
      variables: {
        dragged,
        droppedOn,
      },
      update: (client, { data: { updateFolderOrder } }) => {
        const { ok, folders } = updateFolderOrder;

        if (!ok) {
          return;
        }

        const newData = {
          allFolders: folders,
        };

        client.writeQuery({
          query: ALL_FOLDERS,
          data: newData,
        });
      },
    });
  };

  if (loadingFolders && !dataFolders) {
    return null;
  }

  if (loadingBeats && !dataBeats) {
    return null;
  }

  let foldersToRender = dataFolders.allFolders;
  let beatsToRender = dataBeats.allBeats;
  [beatsToRender, foldersToRender] = searchBeatsFolders(search, beatsToRender, foldersToRender);

  const exerciseFolders = foldersToRender.filter((folder: folderType) => folder.exercise === true);
  const songFolders = foldersToRender.filter((folder: folderType) => folder.song === true);

  const filteredBeats = beatsToRender.filter((beat: beatType) => {
    if (beat && !beat.folderId) {
      return true;
    }
    return false;
  });

  const beatItems = filteredBeats.map((beat: beatType) => (
    <Beat
      key={beat.name}
      beat={beat}
      confirmOpen={deleteBeatId === beat.id}
      openDelete={openDeleteBeat}
      closeDelete={closeDeleteBeat}
      handleDrop={(beatId: number, folderId: number | null) => moveBeat(beatId, folderId, beat)}
    />
  ));

  return (
    <div>
      <Header
        search={search}
        setSearch={setSearch}
        setShown={setShown}
        showCreateFolder={!type}
        shown={shown}
        title="My Custom Beats"
      />

      {shown && (
        <div>
          <div>
            <MyBeatsFolder
              beatsLength={filteredBeats.length}
              content={beatItems}
              folder={{
                name: "My Beats",
                id: 1000000,
              }}
            />

            <br />

            {type === 1 && (
              <div>
                <Section
                  title="Exercises"
                  setShown={setExercisesShown}
                  shown={exercisesShown}
                />

                {exercisesShown
                  && exerciseFolders.map((folder: folderType) => (
                    <div key={folder.name}>
                      <Folder
                        beats={beatsToRender
                          .filter((beat: beatType) => {
                            if (beat && beat.folderId === folder.id) {
                              return true;
                            }
                            return false;
                          })
                          .map((beat: beatType) => (
                            <div key={beat.name}>
                              <Beat
                                beat={beat}
                                confirmOpen={deleteBeatId === beat.id}
                                openDelete={openDeleteBeat}
                                closeDelete={closeDeleteBeat}
                                handleDrop={(beatId: number, folderId: number | null) => (
                                  moveBeat(beatId, folderId, beat)
                                )}
                              />
                            </div>
                          ))}
                        folder={folder}
                        folders={foldersToRender}
                        isConfirmOpen={deleteFolderId === folder.id}
                        openDelete={openDeleteFolder}
                        closeDelete={closeDeleteFolder}
                        moveFolder={moveFolder}
                      />
                    </div>
                  ))}

                {exercisesShown && exerciseFolders && exerciseFolders.length === 0 && <p>You have no folders yet.</p>}

                <Section
                  title="Songs"
                  setShown={setSongsShown}
                  shown={songsShown}
                />

                {songsShown
                  && songFolders.map((folder: folderType) => (
                    <div key={folder.name}>
                      <Folder
                        beats={beatsToRender
                          .filter((beat: beatType) => {
                            if (beat && beat.folderId === folder.id) {
                              return true;
                            }
                            return false;
                          })
                          .map((beat: beatType) => (
                            <div key={beat.name}>
                              <Beat
                                beat={beat}
                                confirmOpen={deleteBeatId === beat.id}
                                openDelete={openDeleteBeat}
                                closeDelete={closeDeleteBeat}
                                handleDrop={(beatId: number, folderId: number | null) => (
                                  moveBeat(beatId, folderId, beat)
                                )}
                              />
                            </div>
                          ))}
                        folder={folder}
                        folders={foldersToRender}
                        isConfirmOpen={deleteFolderId === folder.id}
                        openDelete={openDeleteFolder}
                        closeDelete={closeDeleteFolder}
                        moveFolder={moveFolder}
                      />
                    </div>
                  ))}
                {songsShown && songFolders && songFolders.length === 0 && <p>You have no folders yet.</p>}
              </div>
            )}

            {type === 0
              && foldersToRender.map((folder: folderType) => (
                <div key={folder.name}>
                  <Folder
                    beats={beatsToRender
                      .filter((beat: beatType) => {
                        if (beat && beat.folderId === folder.id) {
                          return true;
                        }
                        return false;
                      })
                      .map((beat: beatType) => (
                        <div key={beat.name}>
                          <Beat
                            beat={beat}
                            confirmOpen={deleteBeatId === beat.id}
                            openDelete={openDeleteBeat}
                            closeDelete={closeDeleteBeat}
                            handleDrop={(beatId: number, folderId: number | null) => moveBeat(beatId, folderId, beat)}
                          />
                        </div>
                      ))}
                    folder={folder}
                    folders={foldersToRender}
                    isConfirmOpen={deleteFolderId === folder.id}
                    openDelete={openDeleteFolder}
                    closeDelete={closeDeleteFolder}
                    moveFolder={moveFolder}
                  />
                </div>
              ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default MyCustomBeats;
