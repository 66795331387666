import gql from "graphql-tag";

const SEND_INVITE = gql`
  query($email: String!, $teacherLink: String!) {
    sendInviteEmail(email: $email, teacherLink: $teacherLink) {
      ok
      message
      error
    }
  }
`;

export default SEND_INVITE;
