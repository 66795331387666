interface Props {
  subdivision?: boolean,
  showClefs?: boolean,
}

const MeasureSvg = ({ subdivision, showClefs }: Props) => {
  const baseWidth = showClefs ? 300 : 240;
  const width = baseWidth + (subdivision ? 240 : 0);

  return (
    <svg className="measure-svg">
      <g id="svg_1">
        <path id="svg_2" d={`m0,53l${width},0l0,0.7l${-width},0l0,-0.7z`} />
        <path id="svg_3" d={`m0,60.75l${width},0l0,0.7l${-width},0l0,-0.7z`} />
        <path id="svg_5" d={`m0,68l${width},0l0,0.7l${-width},0l0,-0.7z`} />
        <path id="svg_6" d={`m0,75.25l${width},0l0,0.7l${-width},0l0,-0.7z`} />
        <path id="svg_7" d={`m0,84l${width},0l0,0.7l${-width},0l0,-0.7z`} />
      </g>
      <rect
        x={`${width - 1}`}
        y="53"
        width="1"
        height="31.5"
        stroke="#000"
        fillOpacity="100"
        strokeOpacity="0"
        id="svg_11"
      />
    </svg>
  );
};

export default MeasureSvg;

MeasureSvg.defaultProps = {
  subdivision: false,
  showClefs: false,
};
