import { useState, useEffect, ChangeEvent } from "react";
import {
  Button,
  Confirm,
  Divider,
  Form,
  Header as SemanticHeader,
  Input,
  Message,
} from "semantic-ui-react";
import getUser from "store/user/selectors";
import { setUser } from "store/user/slice";
import { useAppSelector, useAppDispatch } from "store/hooks";
import { useMutation } from "@apollo/client";

import DELETE from "graphql/user/mutations/delete";
import UPDATE from "graphql/user/mutations/update";
import AnimatedConfirmMsg from "components/AnimatedConfirmMsg";
import useClearAppState from "hooks/useClearAppState";

import styles from "./styles.module.scss";

const DEFAULT_TIME_TO_REDIRECT = 5000;

interface FormError {
  emailError?: string;
  nameError?: string;
}

interface Error {
  path: "email" | "name";
  message: string;
}

const emptyErr: { [char: string]: string } & FormError = {
  emailError: "",
  nameError: "",
};

const SettingsForm = () => {
  const clearAppState = useClearAppState();
  const dispatch = useAppDispatch();

  const user = useAppSelector(getUser);

  const [deleteAccount] = useMutation(DELETE);
  const [updateUser] = useMutation(UPDATE);

  const [emailError, setEmailError] = useState("");
  const [nameError, setNameError] = useState("");
  const [confirmationEmailSent, setConfirmationEmailSent] = useState(false);
  const [email, setEmail] = useState(user.email);
  const [firstName, setFirstName] = useState(user.firstName);
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const [isDeleted, setIsDeleted] = useState(false);
  const [isUpdatingEmail, setisUpdatingEmail] = useState(false);
  const [isUpdatingName, setisUpdatingName] = useState(false);
  const [lastName, setLastName] = useState(user.lastName);

  useEffect(() => {
    setFirstName(user.firstName);
    setLastName(user.lastName);
    setEmail(user.email);
  }, [user]);

  const handleDeleteAccount = async () => {
    const response = await deleteAccount({
      variables: { userId: user.id },
    });

    if (response?.data?.delete?.ok) {
      clearAppState();
      setIsDeleted(true);
      setTimeout(() => window.location.replace("https://drumroom.io/"), DEFAULT_TIME_TO_REDIRECT);
    }
  };

  const handleUpdateName = async () => {
    if (isUpdatingName) {
      const { data: { update } } = await updateUser({
        variables: {
          firstName,
          id: user.id,
          lastName,
        },
      });

      if (update?.ok) {
        setNameError("");
        dispatch(setUser(update.user));
        setisUpdatingName(false);
      } else {
        const err = emptyErr;

        update.errors.forEach(({ path, message }: Error) => {
          err[`${path}Error`] = message;
        });

        setNameError(err.nameError || "");
      }
    } else {
      setisUpdatingName(true);
    }
  };

  const handleUpdateEmail = async () => {
    if (isUpdatingEmail) {
      const { data: { update } } = await updateUser({
        variables: {
          email,
          id: user.id,
        },
      });

      if (update?.ok) {
        setEmailError("");
        setConfirmationEmailSent(true);
        dispatch(setUser(update.user));
        setisUpdatingEmail(false);
      } else {
        const err = emptyErr;

        update.errors.forEach(({ path, message }: Error) => {
          err[`${path}Error`] = message;
        });

        if (err.emailError === "") setisUpdatingEmail(false);

        setEmailError(err.emailError || "");
      }
    } else {
      setConfirmationEmailSent(false);
      setisUpdatingEmail(true);
    }
  };

  const updateNameButtonText = isUpdatingName ? "Save" : "Edit";
  const updateEmailButtonText = isUpdatingEmail ? "Save" : "Edit";
  const confirmContent = isDeleted
    ? `Your account has been succesfully deleted. You can close this page, or we will redirect you to our homescreen
       in five seconds.`
    : "Are you sure you want to delete your account? We cannot recover this data.";

  const EMAIL_UPDATED_MESSAGE = `We sent an email to your new address. Please confirm your new email
    before the next time you log out. If not, we will require it when you log back in.`;

  return (
    <Form>
      <SemanticHeader as="h4">Email address</SemanticHeader>
      <Form.Field className={styles.firstName} error={emailError}>
        <Input
          aria-label="email"
          name="email"
          onChange={(e: ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)}
          value={email}
          placeholder="Email address"
          disabled={!isUpdatingEmail}
          fluid
        />
      </Form.Field>
      {emailError && (
        <Message
          className="ui warning visible message"
          error
          content={emailError}
        />
      )}

      {confirmationEmailSent && <AnimatedConfirmMsg message={EMAIL_UPDATED_MESSAGE} />}

      <Button onClick={handleUpdateEmail}>{updateEmailButtonText}</Button>

      <SemanticHeader as="h4">Name</SemanticHeader>
      <Form.Field className={styles.firstName} error={nameError}>
        <Input
          aria-label="first-name"
          name="firstName"
          onChange={(e: ChangeEvent<HTMLInputElement>) => setFirstName(e.target.value)}
          value={firstName}
          placeholder="First Name"
          disabled={!isUpdatingName}
          fluid
        />
      </Form.Field>
      <Form.Field className={styles.lastName} error={nameError}>
        <Input
          aria-label="last-name"
          name="lastName"
          onChange={(e: ChangeEvent<HTMLInputElement>) => setLastName(e.target.value)}
          value={lastName}
          placeholder="Last Name"
          disabled={!isUpdatingName}
          fluid
        />
      </Form.Field>
      {nameError && (
        <Message
          className="ui warning visible message"
          error
          content={nameError}
        />
      )}

      <Button onClick={handleUpdateName}>{updateNameButtonText}</Button>

      <Divider />

      <Button negative onClick={() => setIsConfirmOpen(true)}>
        Delete my Account
      </Button>

      <Confirm
        open={isDeleted || isConfirmOpen}
        content={confirmContent}
        confirmButton={(
          <Button negative disabled={isDeleted}>
            Delete anyway
          </Button>
        )}
        cancelButton={<Button disabled={isDeleted}>Cancel</Button>}
        onCancel={() => setIsConfirmOpen(false)}
        onConfirm={handleDeleteAccount}
        style={{ width: "450px" }}
      />
    </Form>
  );
};

export default SettingsForm;
