import { RootState } from "store";

export const getAlternate = (state: RootState) => state.drumroom.alternate;

export const getBeatLibraryOpen = (state: RootState) => state.drumroom.beatLibraryOpen;

export const getCountIn = (state: RootState) => state.drumroom.countIn;

export const getCountingIn = (state: RootState) => state.drumroom.countingIn;

export const getCreateAssignmentOpen = (state: RootState) => state.drumroom.openTabs.createAssignmentOpen;

export const getLoop = (state: RootState) => state.drumroom.loop;

export const getReadOnly = (state: RootState) => state.drumroom.readOnly;

export const getStep = (state: RootState) => state.drumroom.step;
