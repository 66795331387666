import gql from "graphql-tag";

const OVERWRITE = gql`
  mutation(
    $name: String!
    $notes: String!
    $folderId: Int
    $bpm: Int
    $subdivision: Boolean
    $sticking: Boolean
    $counting: Boolean
    $metronome: Boolean
    $grid: Boolean
    $color: Boolean
    $comments: String
    $swing: Float
  ) {
    overwriteBeat(
      name: $name
      notes: $notes
      folderId: $folderId
      bpm: $bpm
      subdivision: $subdivision
      sticking: $sticking
      counting: $counting
      metronome: $metronome
      grid: $grid
      color: $color
      comments: $comments
      swing: $swing
    ) {
      beat {
        bpm
        color
        comments
        counting
        folderId
        grid
        id
        metronome
        name
        notes
        sticking
        subdivision
        swing
      }
      ok
    }
  }
`;

export default OVERWRITE;
