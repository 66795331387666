import {
  SyntheticEvent, CSSProperties, useState, ChangeEvent, useEffect,
} from "react";
import {
  Form, Message, Button, Input, Header as SemanticHeader, Dropdown, DropdownProps,
} from "semantic-ui-react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client";
import REGISTER from "graphql/user/mutations/register";
import VALIDATE_SIGNUP_TOKEN from "graphql/student/queries/validateSignupToken";
import GET_TEACHER from "graphql/teacher/queries/get";
import Header from "components/Header";
import background from "images/loginBg.png";
import { cloneDeep } from "lodash";
import styles from "./styles.module.scss";

const initialErr = {
  emailError: "",
  firstNameError: "",
  lastNameError: "",
  passwordError: "",
  userError: "",
};

const initialErrorList: string[] = [];
const initialState = {
  firstName: "",
  firstError: "",
  lastName: "",
  lastError: "",
  email: "",
  emailError: "",
  confirmEmail: "",
  confirmEmailError: "",
  password: "",
  confirmPassword: "",
  passwordError: "",
  validateView: false,
  validated: false,
  validateError: "",
  token: "",
  errorList: initialErrorList,
  userType: -1,
  err: initialErr,
};

const Register = () => {
  const [state, setState] = useState(initialState);
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    setState(initialState);
  }, [state.validateView]);

  const signupToken = location.search.slice(1);

  const { data: dataTeacher, loading: loadingTeacher } = useQuery(GET_TEACHER, {
    variables: {
      teacherId: parseInt(signupToken, 10),
    },
    skip: !parseInt(signupToken, 10),
  });

  const { data: dataValidate, loading: loadingSignup } = useQuery(VALIDATE_SIGNUP_TOKEN, {
    variables: {
      signupToken,
    },
  });

  const validTeacher = dataTeacher?.getTeacher?.ok;
  const validSignup = (dataValidate?.validateSignupToken?.ok || validTeacher);

  if (!validSignup && !loadingSignup && !loadingTeacher) history.push("login");

  if (validSignup && state.userType !== 0) {
    setState({
      ...state,
      userType: 0,
    });
  }

  const [createUser] = useMutation(REGISTER);

  const updateField = (e: ChangeEvent<HTMLInputElement>) => setState({
    ...state,
    [e.target.name]: e.target.value,
  });

  const onSubmit = async () => {
    setState({
      ...state,
      emailError: "",
      confirmEmailError: "",
      passwordError: "",
      errorList: [],
    });

    const response = await createUser({
      variables: {
        email: state.email,
        firstName: state.firstName,
        lastName: state.lastName,
        password: state.password,
        confirmPassword: state.confirmPassword,
        confirmEmail: state.email,
        userType: state.userType,
        update: !!validSignup,
        teacherId: validTeacher ? dataTeacher.getTeacher.teacher.teacherId : null,
      },
    });

    const {
      ok, validateToken, errors,
    } = response.data.register;

    if (ok) {
      localStorage.setItem("validateToken", validateToken);
      history.push("/validate");
    } else {
      const err: { [key: string]: string } & typeof initialErr = cloneDeep(initialErr);
      const errorList: string[] = [];
      errors.forEach(({ path, message }: { path: string, message: string }) => {
        err[`${path}Error`] = message;
        errorList.push(message);
      });

      setState({
        ...state,
        err,
        errorList,
      });
    }
  };

  const onUserSelect = (e: SyntheticEvent<HTMLElement, Event>, data: DropdownProps) => {
    const userType = typeof data.value === "number" ? data.value : -1;

    setState({ ...state, userType });
  };

  const backgroundImgStyle: CSSProperties = {
    position: "absolute",
    width: "100%",
    height: "100%",
    objectFit: "cover",
  };

  const totalErrors = state.errorList.length;

  let panelHeight = 570 + (validTeacher ? -10 : 0);
  let panelWidth = 610 - validSignup * 0;

  if (!state.validateView) {
    panelHeight = totalErrors ? panelHeight + totalErrors * 22 : panelHeight - 60;
  } else if (!state.validated) {
    panelHeight = 240;
    if (state.validateError) {
      panelHeight = 340;
    }
  } else if (state.validated) {
    panelHeight = 75;
    panelWidth = 320;
  }

  const dropdownFolders = [
    {
      key: 0,
      value: 0,
      text: "Student",
    },
    {
      key: 1,
      value: 1,
      text: "Teacher",
    },
  ];

  return (
    <div>
      <img src={background} style={backgroundImgStyle} alt="LoginBg" />
      <Header marketing />

      <div className="RegisterPanel" style={{ height: panelHeight, width: panelWidth }}>
        {!state.validateView && (
          <div>
            <Form>
              <SemanticHeader as="h2">Register</SemanticHeader>
              {!validSignup && (
                <Form.Field error={!!(state.err && state.err.userError)}>
                  <Dropdown
                    placeholder="Who are you?"
                    selection
                    clearable
                    options={dropdownFolders}
                    onChange={onUserSelect}
                  />
                </Form.Field>
              )}

              {validSignup && !validTeacher && (
                <h4>Please complete the form to be automatically linked with your teacher.</h4>
              )}
              {validTeacher && (
                <div className={styles.caption}>
                  <p>
                    Register for a student account with your teacher,
                    {" "}
                    <b>
                      {dataTeacher.getTeacher.teacher.firstName}
                      {" "}
                      {dataTeacher.getTeacher.teacher.lastName}
                    </b>
                    .
                  </p>
                </div>
              )}

              <div className={styles.flexContainer}>
                <Form.Field error={!!(state.err && state.err.firstNameError)} className={styles.firstName}>
                  <Input
                    aria-label="first-name"
                    name="firstName"
                    onChange={updateField}
                    value={state.firstName}
                    placeholder="First Name"
                    fluid
                  />
                </Form.Field>
                <Form.Field error={!!(state.err && state.err.lastNameError)} className={styles.lastName}>
                  <Input
                    aria-label="last-name"
                    name="lastName"
                    onChange={updateField}
                    value={state.lastName}
                    placeholder="Last Name"
                    fluid
                  />
                </Form.Field>
              </div>

              <Form.Field error={!!(state.err && state.err.emailError)}>
                <Input
                  aria-label="email"
                  name="email"
                  onChange={updateField}
                  value={state.email}
                  placeholder="Email"
                  fluid
                />
              </Form.Field>
              <Form.Field error={!!(state.err && state.err.passwordError)}>
                <Input
                  aria-label="password"
                  name="password"
                  onChange={updateField}
                  value={state.password}
                  type="password"
                  placeholder="Password"
                  fluid
                />
              </Form.Field>
              <Form.Field error={!!(state.err && state.err.passwordError)}>
                <Input
                  aria-label="confirm-password"
                  name="confirmPassword"
                  onChange={updateField}
                  value={state.confirmPassword}
                  type="password"
                  placeholder="Confirm Password"
                  fluid
                />
              </Form.Field>

              <p>
                By clicking Submit, I verify that I am 13 years of age or older and am creating a Drumroom account for
                either myself or a child for whom I am a parent or legal guardian. I have read and agree to
                Drumroom&apos;s&nbsp;
                <Link to="/user-agreement">User Agreement</Link>
                &nbsp;and&nbsp;
                <Link to="/privacy-policy">Privacy Policy</Link>
                .
              </p>
              <Form.Field>
                <Button onClick={onSubmit}>Submit</Button>
              </Form.Field>
            </Form>
            <br />
            <Link to="/login" style={{ position: "absolute", bottom: "15px" }}>
              Already registered? Click to login.
            </Link>

            {state.errorList.length !== 0 ? (
              <Message
                error
                header="There were some errors with your submission. "
                style={{
                  position: "relative",
                  top: "-20px",
                  left: "0px",
                  height: totalErrors ? 50 + totalErrors * 22 : 0,
                  width: "100%",
                }}
                list={state.errorList}
              />
            ) : null}
          </div>
        )}
      </div>
    </div>
  );
};

export default Register;
