import { EIGHTH_BAR_X, EIGHTH_BAR_Y } from ".";

interface Props {
  n: number;
  noteDecorationFill: string[];
  opacity: number[];
  subdivision: boolean;
}

const OneA = ({
  n, noteDecorationFill, opacity, subdivision,
}: Props) => (
  <>
    <path
      transform={`translate(${30 * n}, 0)`}
      /* eslint-disable max-len */
      d={`m${EIGHTH_BAR_X}, ${EIGHTH_BAR_Y}l${subdivision ? 60 : 30}.5,0l0,3.875l-${subdivision ? 60 : 30}.5,0l0,-3.875z`}
      fill={noteDecorationFill[n]}
      fillOpacity={opacity[n]}
    />
  </>
);

export default OneA;
