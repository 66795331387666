import { Instruments } from "utils/notesIndexToKey";

interface ObjectKeys {
  [key: string]: string;
}
export const GRID_ANIMATION_DURATION = 300; // ms
export const MIN_FIRST_MEASURE_WIDTH = 300; // px
export const MIN_MEASURE_WIDTH = 240; // px
export const SHEET_MAX_WIDTH = 1440; // px
// this is for 2 measures max in 16th row. increase it to allow more.
export const SHEET_MAX_WIDTH_16 = 1440; // px
export const SUBDIVISION_WIDTH_DIFF = 240; // px
export const TOTAL_GUTTERS_WIDTH = 160; // px

export const MAX_ROW_MEASURES_8 = 4;
export const MAX_ROW_MEASURES_16 = 2;

// this should come directly from the interface ideally, or at least we should define instruments constant and use
// in both places
export const TOMS = ["Hi-Tom", "Mid-Tom", "Lo-Tom"];
export const INSTRUMENTS = ["Hi-Hat", "Hi-Tom", "Mid-Tom", "Snare", "Lo-Tom", "Bass"] as const;
// order matters here
export const TOM_KEYS = ["hiTom", "midTom", "loTom"];
export const INSTRUMENT_KEYS: Instruments[] = ["bass", "loTom", "snare", "midTom", "hiTom", "hiHat"];
export const INSTRUMENT_TO_COLOR_MAP: ObjectKeys = {
  hiHat: "rgba(47, 168, 57, 1)",
  hiTom: "rgb(226, 209, 18)",
  midTom: "rgb(255, 157, 38)",
  snare: "rgba(219, 57, 57, 1)",
  loTom: "rgb(135, 91, 223)",
  bass: "rgba(43, 121, 229, 1)",
};

export const INSTRUMENT_TO_OP_COLOR_MAP: ObjectKeys = {
  hiHat: "rgba(47, 168, 57, 0.2)",
  hiTom: "rgb(226, 209, 18, 0.2)",
  midTom: "rgb(255, 157, 38, 0.2)",
  snare: "rgba(219, 57, 57, 0.2)",
  loTom: "rgb(135, 91, 223, 0.2)",
  bass: "rgba(43, 121, 229, 0.2)",
};
