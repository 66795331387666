import gql from "graphql-tag";

const DELETE = gql`
  mutation($id: Int!, $folderId: Int, $name: String!) {
    deleteBeat(id: $id, name: $name, folderId: $folderId) {
      ok
      beat {
        id
        folderId
        name
      }
      errors {
        path
        message
      }
      subscriptionInactive
    }
  }
`;

export default DELETE;
