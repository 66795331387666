const calculateNoteOpacity = (countingIn: boolean, division: 16 | 8, hidden: string[]): number[] => {
  const opacity = Array(division).fill(1);

  if (countingIn) return opacity.map(() => 0.1);

  if (!hidden.every((el) => el === "")) {
    return opacity.map((el, i) => (hidden[i] ? 1 : 0.1));
  }

  return opacity;
};

export default calculateNoteOpacity;
