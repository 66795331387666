import gql from "graphql-tag";

const SEND_FEEDBACK = gql`
  query($first: String!, $last: String!, $email: String!, $message: String!) {
    sendFeedback(message: $message, first: $first, last: $last, email: $email) {
      ok
    }
  }
`;

export default SEND_FEEDBACK;
