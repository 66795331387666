import { useAppSelector, useAppDispatch } from "store/hooks";
import { Popup } from "semantic-ui-react";
import ghost from "svg/ghost.svg";
import accent from "svg/accent.svg";
import open from "svg/open.svg";
import rimclick from "svg/click.svg";
import { updateNotes } from "store/beat/slice";
import { getActiveMeasureIndex } from "store/beat/selectors";
import { useMediaQuery } from "@material-ui/core";

import styles from "./styles.module.scss";

const TYPES = ["g", "a", "o", "r"];
const TYPE_NAMES = ["Ghost", "Accent", "Open Hi-Hat", "Rimclick"];
const IMAGES = [ghost, accent, open, rimclick];
const OK_TARGETS = [
  ["o", "g", "a"],
  [],
  [],
  ["g", "a", "r"],
  [],
  [],
];

const Toolbox = () => {
  const dispatch = useAppDispatch();
  const activeNote = useAppSelector((state) => state.beat.present.activeNote);
  const activeMeasureIndex = useAppSelector(getActiveMeasureIndex);
  const matches = useMediaQuery("(max-width: 1000px)");

  const onItemPress = (type: string) => {
    const shouldDispatch = activeNote
        && OK_TARGETS[activeNote.inst]?.includes(type)
        && activeNote.type !== "";

    if (shouldDispatch) {
      dispatch(updateNotes({
        activeNote: {
          inst: activeNote.inst,
          index: activeNote.index,
          type,
        },
        add: !activeNote?.type?.includes(type),
        activeMeasureIndex,
      }));
    }
  };

  return (
    <div className={styles.container}>
      {TYPES.map((type: string, index: number) => (
        <Popup
          key={`${type}`}
          content={TYPE_NAMES[index]}
          position={matches ? "left center" : "bottom left"}
          on="hover"
          trigger={(
            <button
              type="button"
              className={styles[`item${activeNote?.type?.includes(type) ? "Active" : ""}`]}
              onClick={() => onItemPress(type)}
            >
              <img src={IMAGES[index]} alt={TYPE_NAMES[index]} />
            </button>
          )}
        />
      ))}
    </div>
  );
};

export default Toolbox;
