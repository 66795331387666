import gql from "graphql-tag";

const GET_WITH_UUID = gql`
  query($uuid: String!) {
    getBeatWithUUID(uuid: $uuid) {
      ok
      beat {
        notes
        id
        name
        folderId
        bpm
        subdivision
        sticking
        counting
        metronome
        grid
        color
        comments
        swing
        shared
      }
      error
    }
  }
`;

export default GET_WITH_UUID;
