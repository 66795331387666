import gql from "graphql-tag";

const ALL_FOR_TEACHER = gql`
  query($teacherId: Int!, $linked: Boolean) {
    allStudents(teacherId: $teacherId, linked: $linked) {
      ok
      students {
        email
        firstName
        lastName
        userId
        studentId
        signupEmail
      }
    }
  }
`;

export default ALL_FOR_TEACHER;
