import { useState } from "react";
import { useQuery } from "@apollo/client";
import { useAppSelector } from "store/hooks";
import { Anchorme } from "react-anchorme";
import TEN_ASSIGNMENTS_FOR_STUDENT from "graphql/assignment/queries/tenForStudent";
import { beatType } from "types/beat";
import Header from "screens/Main/BeatLibrary/Header";
import Beat from "screens/Main/BeatLibrary/Beat";
import MyBeatsFolder from "screens/Main/BeatLibrary/MyBeatsFolder";
import AllAssignments from "screens/Main/BeatLibrary/AllAssignments";
import "./MyAssignments.scss";

interface assignmentType {
  id: number;
  content: string;
  title: string;
  beats: beatArrType;
  createdAt: string;
}

type beatArrType = beatType[];

const MyAssignments = () => {
  const [shown, setShown] = useState(true);
  const { typeId: studentId, firstName, lastName } = useAppSelector((store) => store.user);

  const { loading: loadingAssignments, data: dataAssignments } = useQuery(TEN_ASSIGNMENTS_FOR_STUDENT, {
    variables: {
      studentId,
    },
  });

  const buildBeats = (assignment: any) => assignment.beats.map((beat: any) => (
    <Beat key={beat.name} beat={beat} hideDropdown />
  ));

  if (loadingAssignments) return <div>loading...</div>;

  return (
    <div>
      <Header
        search="" // there's no search algorithm for assignments yet.
        setSearch={() => {}}
        showSearch={false}
        setShown={setShown}
        shown={shown}
        title="My Assignments"
      />

      {shown && (
        <div>
          {dataAssignments?.studentAssignments?.assignments?.map((assignment: assignmentType, index: number) => (
            <MyBeatsFolder
              key={assignment.title}
              beatsLength={assignment.beats.length}
              content={(
                <div key={assignment.id}>
                  <div className="StudentAssignmentsBorder">
                    <div className="StudentAssignmentsContent">
                      <p className="StudentAssignmentsAssignmentsTextP">
                        <pre className="StudentAssignmentsAssignmentsText">
                          <Anchorme target="_blank" rel="noreferrer noopener">
                            {assignment.content}
                          </Anchorme>
                        </pre>
                      </p>
                    </div>
                  </div>
                  {buildBeats(assignment)}
                </div>
              )}
              folder={{
                name: new Date(parseInt(assignment.createdAt, 10)).toDateString(),
                id: 1000000 + index,
              }}
            />
          ))}

          {dataAssignments?.studentAssignments?.assignments.length && (
            <AllAssignments studentId={studentId} firstName={firstName} lastName={lastName} />
          )}

          {!dataAssignments?.studentAssignments?.assignments.length && <p>You have no assignments yet.</p>}
        </div>
      )}
    </div>
  );
};

export default MyAssignments;
