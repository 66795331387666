import gql from "graphql-tag";

const ALL_BEATS = gql`
  query($includeStock: Boolean) {
    allBeats(includeStock: $includeStock) {
      id
      name
      folderId
      shared
    }
  }
`;

export default ALL_BEATS;
