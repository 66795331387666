import { ReactNode } from "react";
import { Link, useLocation } from "react-router-dom";
import NavBar from "components/NavBar";
import { Icon } from "semantic-ui-react";
import logo from "images/logo-header-full-compressed.png";
import isAuthenticated from "utils/isAuthenticated";

import styles from "./styles.module.scss";

interface Props {
  marketing?: boolean;
  readOnly?: boolean;
}

interface LogoWrapperProps {
  redirectEnabled: boolean;
  children: ReactNode
}

const LogoWrapper = ({ redirectEnabled, children }: LogoWrapperProps) => (
  redirectEnabled ? (
    <a href="https://drumroom.io/">{children}</a>
  ) : (
    <>{children}</>
  )
);

const Header = ({ readOnly, marketing }: Props) => {
  const { pathname } = useLocation();

  return (marketing ? (
    <div>
      <LogoWrapper redirectEnabled={pathname !== "/"}>
        <img
          alt="DrumRoom Logo"
          className="drumRoomLogo"
          src={logo}
        />
      </LogoWrapper>

      <a href="mailto: info@drumroom.io">
        <Icon className="MailLink" size="big" name="mail outline" />
      </a>
      <a
        href="https://instagram.com/drumroomeducation?igshid=MmIzYWVlNDQ5Yg=="
        rel="noopener noreferrer"
        target="_blank"
      >
        <Icon className="IgLink" size="big" name="instagram" />
      </a>

      {pathname !== "/validate" && (
        <Link className="LoginButton" to="/login">
          Login
        </Link>
      )}
    </div>
  ) : (
    <>
      <div className={styles.container}>
        <LogoWrapper redirectEnabled={pathname !== "/"}>
          <img
            alt="DrumRoom Logo"
            className="drumRoomLogo"
            src={logo}
          />
        </LogoWrapper>
        {!readOnly && isAuthenticated() && <NavBar />}
      </div>
    </>
  ));
};

export default Header;

Header.defaultProps = {
  marketing: false,
  readOnly: false,
};
