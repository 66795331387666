/* eslint-disable max-len */

interface Props {
  transform: string;
  opacity: number;
  dotted?: boolean;
}

const Eighth = ({
  transform, opacity, dotted,
}: Props) => (
  <g
    id="g128"
    strokeMiterlimit="10"
    strokeLinejoin="round"
    strokeWidth="0"
    stroke="#000000"
    fill="#000000"
    fillOpacity={opacity}
  >
    <path
      transform={transform}
      d="m97.70848,62c-0.52,0.098 -0.918,0.457 -1.098,0.953c-0.039,0.16 -0.039,0.199 -0.039,0.418c0,0.301 0.019,0.461 0.16,0.699c0.199,0.399 0.617,0.719 1.094,0.836c0.5,0.141 1.336,0.02 2.293,-0.297l0.238,-0.082l-1.176,3.25l-1.156,3.246c0,0 0.039,0.02 0.102,0.063c0.117,0.078 0.316,0.137 0.457,0.137c0.238,0 0.539,-0.137 0.578,-0.258c0,-0.039 0.558,-1.934 1.234,-4.184l1.195,-4.125l-0.039,-0.058c-0.097,-0.121 -0.296,-0.16 -0.418,-0.063c-0.039,0.039 -0.101,0.121 -0.14,0.18c-0.18,0.301 -0.637,0.836 -0.875,1.035c-0.219,0.18 -0.34,0.199 -0.539,0.121c-0.18,-0.098 -0.239,-0.199 -0.36,-0.738c-0.117,-0.535 -0.257,-0.778 -0.558,-0.977c-0.278,-0.179 -0.637,-0.238 -0.953,-0.156z"
      stroke="#000000"
    />
    {dotted && (
      <circle
        transform={transform}
        cx="104.22"
        cy="67.04"
        r="1.2"
        fillOpacity={opacity}
      />
    )}
  </g>
);

export default Eighth;

Eighth.defaultProps = {
  dotted: false,
};
