import gql from "graphql-tag";

const CREATE = gql`
  mutation($stock: Boolean!, $exercise: Boolean, $song: Boolean) {
    createFolder(stock: $stock, exercise: $exercise, song: $song) {
      ok
      folder {
        id
        name
        order
        exercise
        song
      }
    }
  }
`;

export default CREATE;
