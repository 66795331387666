import { forOwn, cloneDeep } from "lodash";
import { Measure } from "store/beat/slice";

const removeOddNotesIfApplies = (measure: Measure, subdivision: boolean) => {
  if (subdivision) return measure;

  const strippedMeasure = cloneDeep(measure);

  forOwn(strippedMeasure, (notes, instrument) => {
    strippedMeasure[instrument] = notes.filter((el: any, j: number) => j % 2 === 0);
  });

  return strippedMeasure;
};

export default removeOddNotesIfApplies;
