import { Icon } from "semantic-ui-react";
import Grid from "@material-ui/core/Grid";
import CreateFolder from "screens/Main/BeatLibrary/CreateFolder";
import styles from "./styles.module.scss";

interface Props {
  setShown: (shown: boolean) => void;
  shown: boolean;
  title: string;
}

const Section = ({
  setShown,
  shown,
  title,
}: Props) => (
  <div className={styles.container}>
    <Grid container justify="space-between">
      <Grid item>
        {title}
      </Grid>
      <Grid item>
        <CreateFolder
          stock={false}
          exercise={title === "Exercises"}
          song={title === "Songs"}
        />
        <Icon
          link
          name={shown ? "minus" : "plus"}
          onClick={() => setShown(!shown)}
        />
      </Grid>
    </Grid>
  </div>
);

export default Section;
