import { FullProps } from "svg/notes/baseProps";
import Snare from "svg/notes/Snare";
import HiHat from "svg/notes/HiHat";
import HiTom from "svg/notes/HiTom";
import LoTom from "svg/notes/LoTom";
import Bass from "svg/notes/Bass";
import MidTom from "svg/notes/MidTom";

interface Props extends FullProps {
  instrumentType: "hiHat" | "hiTom" | "midTom" | "snare" | "loTom" | "bass";
}

const instruments = {
  hiHat: HiHat,
  hiTom: HiTom,
  midTom: MidTom,
  loTom: LoTom,
  snare: Snare,
  bass: Bass,
} as const;

const Note = ({ instrumentType, ...props }: Props) => {
  const Component = instruments[instrumentType];

  return <Component {...props} />;
};

export default Note;
