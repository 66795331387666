/* eslint-disable max-len */

import { BaseProps } from "./baseProps";

const Bass = ({
  colored, index, onClick, opacity,
}: BaseProps) => (
  <path
    d="m67.22146,66c0.36,-0.03 1.2,0 1.53,0.06c1.17,0.24 1.89,0.84 2.16,1.83c0.06,0.18 0.06,0.3 0.06,0.66c0,0.45 0,0.63 -0.15,1.08c-0.66,2.04 -3.06,3.93 -5.52,4.38c-0.54,0.09 -1.44,0.09 -1.83,0.03c-1.23,-0.27 -1.98,-0.87 -2.25,-1.86c-0.06,-0.18 -0.06,-0.3 -0.06,-0.66c0,-0.45 0,-0.63 0.15,-1.08c0.24,-0.78 0.75,-1.53 1.44,-2.22c1.2,-1.2 2.85,-2.01 4.47,-2.22zm3.72,-23.075l0,26.35l-0.6,0l0,-57l0.6,0z"
    fill={colored ? "#2b79e5" : "#00000"}
    fillOpacity={opacity}
    onClick={onClick}
    transform={`translate(${30 * index}, 10)`}
  />
);

export default Bass;
