import { useState } from "react";
import {
  Button,
  Form,
  Icon,
  Input,
  Message,
  Modal,
} from "semantic-ui-react";
import { useApolloClient } from "@apollo/client";
import { useAppSelector, useAppDispatch } from "store/hooks";
import SEND_INVITE from "graphql/student/queries/sendInvite";
import Header from "components/Header";
import Alerts from "components/Alerts";
import { sendAlert } from "store/alert/slice";
import ShareableLink from "components/ShareableLink";
import useRequireActiveSubscription from "hooks/useRequireActiveSubscription";
import AddStudent from "./AddStudent";
import StudentsTable from "./StudentsTable";

import styles from "./styles.module.scss";

const MyStudents = () => {
  useRequireActiveSubscription();
  const typeId = useAppSelector((store) => store.user.typeId);

  const fullUrl = window.location.href;
  const baseUrl = fullUrl.split("my-students");
  const teacherLink = `${baseUrl[0]}register/?${typeId}`;

  const [open, setOpen] = useState(false);
  const [email, setEmail] = useState();
  const [error, setError] = useState("");
  const dispatch = useAppDispatch();
  const client = useApolloClient();

  const sendEmail = async () => {
    const { data: { sendInviteEmail: { ok, error: err } } } = await client.query({
      query: SEND_INVITE,
      variables: { email, teacherLink },
    });

    if (ok) {
      setOpen(false);
      dispatch(
        sendAlert({
          message: "Email sent!",
          duration: 10000,
          open: true,
        }),
      );
    } else {
      setError(err);
    }
  };

  return (
    <div>
      <Alerts />

      <Header />

      <div className={styles.container}>
        <div className={styles.registerLinkContainer}>
          <ShareableLink
            title="Unique Teacher Link"
            description="The following link can be distributed to your students. Anyone who registers using
              this link will create a student account that is automatically linked to your teacher account."
            link={teacherLink}
          />
          <Button onClick={() => setOpen(true)}>
            Send Link
            {" "}
            <Icon name="mail" />
          </Button>
        </div>
        <AddStudent typeId={typeId} />
        <StudentsTable teacherId={typeId} />
        <Modal size="small" open={open} onClose={() => setOpen(false)}>
          <Modal.Header>Send Link</Modal.Header>
          <Modal.Content>
            <Form>
              <Form.Field error={!!error}>
                <Input
                  type="text"
                  placeholder="Email"
                  onChange={(e: any) => setEmail(e.target.value)}
                  value={email}
                />
              </Form.Field>
            </Form>
            {error && (
              <Message
                className="ui warning visible message"
                error
                content={error}
              />
            )}
          </Modal.Content>
          <Modal.Actions>
            <Button onClick={() => setOpen(false)}>Cancel</Button>
            <Button positive onClick={sendEmail}>
              Send
            </Button>
          </Modal.Actions>
        </Modal>
      </div>
    </div>
  );
};

export default MyStudents;
