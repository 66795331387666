import { RootState } from "store";

export const getColor = (state: RootState) => state.beat.present.color;

export const getComments = (state: RootState) => state.beat.present.comments;

export const getCounting = (state: RootState) => state.beat.present.counting;

export const getGrid = (state: RootState) => state.beat.present.grid;

export const getMetronome = (state: RootState) => state.beat.present.metronome;

export const getSticking = (state: RootState) => state.beat.present.sticking;

export const getSubdivision = (state: RootState) => state.beat.present.subdivision;

export const getNotes = (state: RootState) => state.beat.present.notes;

export const getMeasureByIndex = (index: number) => (state: RootState) => state.beat.present.notes[index];

export const getActiveMeasureIndex = (state: RootState) => state.beat.present.activeMeasureIndex;

export const getToms = (state: RootState) => state.beat.present.toms;

export const getUUID = (state: RootState) => state.beat.present.uuid;
