import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface Folder {
  folderId: number;
  open: boolean;
}

export type State = Folder[]

const initialState: State = [
  {
    folderId: -1,
    open: false,
  },
];

export const folderSlice = createSlice({
  name: "folder",
  initialState,
  reducers: {
    toggleFolder: (state, action: PayloadAction<Folder["folderId"]>) => {
      const payloadExists = state.map((entry, i) => {
        if (entry.folderId === action.payload) {
          return i + 1;
        }
        return 0;
      });

      const payloadIndex = payloadExists.reduce((a, b) => a + b);

      if (payloadIndex) {
        state[payloadIndex - 1].open = !state[payloadIndex - 1].open;
        return state;
      }
      state.push({ folderId: action.payload, open: true });
      return state;
    },
  },
});

export const { toggleFolder } = folderSlice.actions;

export default folderSlice.reducer;
