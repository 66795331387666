import gql from "graphql-tag";

const STOCK_BEATS = gql`
  {
    stockBeats {
      id
      name
      folderId
      shared
    }
  }
`;

export default STOCK_BEATS;
