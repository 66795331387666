import {
  DropTarget, DropTargetConnector, DropTargetMonitor, ConnectDropTarget,
} from "react-dnd";

function collect(connect: DropTargetConnector, monitor: DropTargetMonitor) {
  return {
    connectDropTarget: connect.dropTarget(),
    hovered: monitor.isOver(),
    beat: monitor.getItem(),
    folder: monitor.getItem(),
    dropped: monitor.didDrop(),
    isOver: monitor.isOver(),
  };
}

interface Props {
  connectDropTarget: ConnectDropTarget;
  hovered: boolean;
  beat: any;
}

const Target = ({ connectDropTarget, hovered, beat }: Props) => {
  // this component renders a drop target above the CreateAssignment component so beats can be dragged into
  // an assignment.
  // upon drag, redux state is updated and values of dropdown are populated in createassignmentform
  const borderStyle = hovered ? "3px solid grey" : "";

  let beatHoveredStyle = {};
  /* eslint-disable no-underscore-dangle */
  if (beat && beat.__typename === "Beat") {
    beatHoveredStyle = {
      zIndex: 10,
      height: "100%",
      width: "100%",
      borderRadius: "0px 0px 4px 4px",
      opacity: 1,
      position: "absolute",
      border: borderStyle,
      margin: -14,
      padding: -10,
      background: "rgba(80, 80, 80, 0.3)",
    };
  }

  return connectDropTarget(
    <div>
      <div style={beatHoveredStyle} />
    </div>,
  );
};

export default DropTarget(
  "beat",
  {
    drop() {
      return {
        target: "ASSIGNMENT",
      };
    },
  },

  collect,
)(Target);
