import { ComponentType, useEffect } from "react";
import { useAppDispatch } from "store/hooks";
import { setReadOnly } from "store/drumroom/slice";
import { useQuery } from "@apollo/client";
import GET_WITH_UUID from "graphql/beat/queries/get_with_uuid";
import { loadBeat } from "store/beat/slice";

const withReadOnly = <P extends object, >(WrappedComponent: ComponentType<P>) => {
  const ReadOnlyApp = (props: P) => {
    const dispatch = useAppDispatch();
    const uuid = window.location.search.substring(1);

    const { data } = useQuery(GET_WITH_UUID, {
      variables: { uuid },
    });

    useEffect(() => {
      dispatch(setReadOnly(true));

      if (data) {
        const { beat } = data.getBeatWithUUID;
        dispatch(loadBeat({ ...beat, notes: JSON.parse(beat.notes) }));
      }

      return () => {
        dispatch(setReadOnly(false));
      };
    }, [data]);

    return <WrappedComponent {...props} />;
  };

  return ReadOnlyApp;
};

export default withReadOnly;
