import { Measure, instrumentKeys } from "store/beat/slice";

const subdivisionHasNote = (beatIndex: number, measure: Measure) => instrumentKeys
  .some((key) => !!measure[key][beatIndex]);

// here is where beat and beat have conflicting names. Beat here refers to a quarter note. but we call the whole thing
// a beat as well. We might consider renaming Beats (the whole thing) to Grooves.
const beatHasPattern = (
  matcher: boolean[], beatIndex: number, measure: Measure,
) => {
  const matches = matcher.map((e, i) => subdivisionHasNote(beatIndex + i, measure) === e);
  const result = matches.reduce((res, match) => res && match);
  return result;
};

const isBeatPattern = (
  pattern: string, beatIndex: number, measure: Measure, subdivision: boolean = true,
) => {
  switch (pattern) {
    case "1e+a": return beatHasPattern([true, true, true, true], beatIndex, measure);
    case "1ea": return beatHasPattern([true, true, false, true], beatIndex, measure);
    case "1+a": return beatHasPattern([true, false, true, true], beatIndex, measure);
    case "1e+": return beatHasPattern([true, true, true, false], beatIndex, measure);
    case "e+a": return beatHasPattern([false, true, true, true], beatIndex, measure);
    case "+a": return beatHasPattern([false, false, true, true], beatIndex, measure);
    case "1e": return beatHasPattern([true, true, false, false], beatIndex, measure);
    case "e+": return beatHasPattern([false, true, true, false], beatIndex, measure);
    case "1a": return beatHasPattern([true, false, false, true], beatIndex, measure);
    case "ea": return beatHasPattern([false, true, false, true], beatIndex, measure);
    case "a": return beatHasPattern([false, false, false, true], beatIndex, measure);
    case "e": return beatHasPattern([false, true, false, false], beatIndex, measure);
    case "1+": return beatHasPattern(subdivision ? [true, false, true, false] : [true, true], beatIndex, measure);
    case "+": return beatHasPattern(subdivision ? [false, false, true, false] : [false, true], beatIndex, measure);
    case "": return beatHasPattern(subdivision ? [false, false, false, false] : [false, false], beatIndex, measure);

    default: return false;
  }
};

export default isBeatPattern;
