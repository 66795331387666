import { Icon } from "semantic-ui-react";
import { CSSProperties } from "react";
import { useAppSelector } from "store/hooks";
import { getStep } from "store/drumroom/selectors";
import getMeasureScaleFactor from "utils/getMeasureScaleFactor";
import styles from "./styles.module.scss";

const Playhead = () => {
  const step = useAppSelector(getStep);
  const playing = useAppSelector((store) => store.drumroom.playing);
  const subdivision = useAppSelector((store) => store.beat.present.subdivision);
  const scale = getMeasureScaleFactor();

  const movingStyle: CSSProperties = {
    position: "absolute",
    right: (subdivision ? 477 : 239) * scale - 30 * step * scale,
    width: 30 * scale,
    height: "100%",
    background: "rgba(190, 190, 190, 0.3)",
    pointerEvents: "none",
    zIndex: 10,
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-start",
  };

  return (
    <>
      {playing && (
        <div style={movingStyle}>
          <Icon name="caret down" color="grey" className={styles.playbackStepCarat} />
        </div>
      )}
    </>
  );
};

export default Playhead;
