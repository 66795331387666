import { Accordion, Icon } from "semantic-ui-react";
import { useState, ReactNode } from "react";
import { useAppSelector, useAppDispatch } from "store/hooks";
import { toggleFolder } from "store/folder/slice";
import TargetNoFolder from "screens/Main/BeatLibrary/TargetNoFolder";
import styles from "./styles.module.scss";

interface Props {
  beatsLength: number;
  content: ReactNode;
  folder: Folder;
}

interface Folder {
  name: string;
  id: number;
}

const MyBeatsFolder = ({
  folder,
  content,
  beatsLength,
}: Props) => {
  const [open, setOpen] = useState(false);
  const dispatch = useAppDispatch();

  const foldersOpen = useAppSelector((store) => store.folder);
  const folderState = foldersOpen.filter((entry) => entry.folderId === folder.id);

  if (folderState && folderState[0] && open !== folderState[0].open) {
    setOpen(folderState[0].open);
  }

  const handleClick = () => {
    dispatch(toggleFolder(folder.id));

    const newfolderState = foldersOpen.filter((entry) => entry.folderId === folder.id);

    if (newfolderState && newfolderState[0]) {
      setOpen(newfolderState[0].open);
    }
  };

  const folderTitle = (
    <button type="button" className={styles.titleContainer} onClick={handleClick}>
      <Icon color="blue" name="folder" className={styles.titleIcon} />
      <p className={styles.titleText}>{`${folder.name} (${beatsLength})`}</p>
    </button>
  );

  const folderPanel = [
    {
      active: open,
      title: {
        content: folderTitle,
      },
      content: {
        content: open ? <div>{content}</div> : content,
      }, // wrapping in div causes folder to not be openable. doing so removes content padding though.
      key: folder.id,
    },
  ];

  const AccordionExampleNested = () => (
    <div>
      <div className="folderDivMask">
        <div style={{ position: "relative" }}>
          <TargetNoFolder numberOfBeats={beatsLength} active={open} />
          <Accordion panels={folderPanel} styled />
        </div>
      </div>
    </div>
  );
  return <AccordionExampleNested />;
};

export default MyBeatsFolder;
