import gql from "graphql-tag";

const GET = gql`
  query($teacherId: Int!) {
    getTeacher(teacherId: $teacherId) {
      ok
      teacher {
        firstName
        lastName
        email
        userId
        teacherId
      }
    }
  }
`;

export default GET;
