import gql from "graphql-tag";

const UPDATE_ORDER = gql`
  mutation($dragged: Int!, $droppedOn: Int!) {
    updateFolderOrder(dragged: $dragged, droppedOn: $droppedOn) {
      ok
      folders {
        id
        name
        order
      }
    }
  }
`;

export default UPDATE_ORDER;
