import { useAppSelector } from "store/hooks";
import { Popup, Icon } from "semantic-ui-react";
import ShareableLink from "components/ShareableLink";
import { getUUID } from "store/beat/selectors";

const Share = () => {
  const uuid = useAppSelector(getUUID);
  const link = `${window.location.origin}/beat/?${uuid}`;

  return (
    <Popup
      flowing
      hideOnScroll
      hoverable
      on={["click", "hover"]}
      position="bottom left"
      trigger={
        <Icon name="share square" link circular />
      }
    >
      {uuid ? (
        <ShareableLink
          description="Share your beat with anyone using the following link."
          link={link}
        />
      ) : (
        <>Save your beat to share it with others.</>
      )}
    </Popup>
  );
};

export default Share;
