import gql from "graphql-tag";

const CONNECT_SANS_TEACHER = gql`
  mutation($email: String, $teacherId: Int!) {
    connectToStudentSansTeacher(email: $email, teacherId: $teacherId) {
      ok
      student {
        id
        signupEmail
        studentId
        teacherId
      }
      message
      error
    }
  }
`;

export default CONNECT_SANS_TEACHER;
