import { Ref, Message, Grid } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { useAppSelector, useAppDispatch } from "store/hooks";
import Alerts from "components/Alerts";
import Feedback from "components/Feedback";
import Header from "components/Header";
import Sheet from "screens/Main/NoteGrid/Sheet";
import Tabs from "screens/Main/Tabs";
import ControlPanel from "screens/Main/ControlPanel";
import BeatHeaderBar from "screens/Main/BeatHeaderBar";
import Toolbox from "screens/Main/Toolbox";
import { setBeatLibraryOpen } from "store/drumroom/slice";
import styles from "./styles.module.scss";
import "screens/Main/App.scss";

interface Props {
  beatLibraryOpen: boolean;
  createAssignmentOpen: boolean;
  handleShowClick: () => void;
  isAdmin: boolean;
  readOnly: boolean;
  segmentRef: any;
  type: number;
}

const Editor = ({
  beatLibraryOpen,
  createAssignmentOpen,
  handleShowClick,
  isAdmin,
  readOnly,
  segmentRef,
  type,
}: Props) => {
  const dispatch = useAppDispatch();
  const handleMainWindowClick = () => {
    // hack to make Create Assignment clickable when beat library is open
    if (createAssignmentOpen && beatLibraryOpen) dispatch(setBeatLibraryOpen(true));
  };

  const {
    grid,
    subdivision,
  } = useAppSelector((store) => store.beat.present);

  const subdivisionActive = subdivision ? "subdivision-active" : "";
  const gridActive = grid ? "grid-active" : "";
  return (
    <>
      {/* eslint-disable-next-line */}
      <div className={`MainWindowContent ${subdivisionActive} ${gridActive}`} onClick={handleMainWindowClick}>
        <Ref innerRef={segmentRef}>
          <div className="BeatLibraryClosableArea">
            <Header readOnly={readOnly} />
            <ControlPanel beatLibraryOpen={beatLibraryOpen} handleShowClick={handleShowClick} readOnly={readOnly} />
            {!readOnly && <Toolbox />}
            <BeatHeaderBar readOnly={readOnly} />

            {readOnly && (
              <div className={styles.readOnlyMessage}>
                <Grid centered>
                  <Grid.Row>
                    <Message compact>
                      Drumroom is in read-only mode.&nbsp;
                      <Link to="/login">Log in</Link>
                      &nbsp;or&nbsp;
                      <Link to="/register">register</Link>
                      &nbsp;to create and save beats.
                    </Message>
                  </Grid.Row>
                </Grid>
              </div>
            )}

            <Alerts />
            <Sheet readOnly={readOnly} />
          </div>
        </Ref>

        <Tabs readOnly={readOnly} type={type} isAdmin={isAdmin} />
        <Feedback />
      </div>
    </>
  );
};

export default Editor;
