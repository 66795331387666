import gql from "graphql-tag";

const ALL_EXERCISE_SONG_BEATS = gql`
  {
    allExerciseSongBeats {
      id
      name
      folderId
      shared
    }
  }
`;

export default ALL_EXERCISE_SONG_BEATS;
