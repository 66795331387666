import {
  useState, useEffect, ChangeEvent,
} from "react";
import { useMutation, useLazyQuery } from "@apollo/client";
import { useHistory, useLocation } from "react-router-dom";
import LOGIN from "graphql/user/mutations/login";
import background from "images/loginBg.png";
import { RootState } from "store";
import GET_USER from "graphql/user/queries/getUser";
import VALIDATE from "graphql/user/mutations/validate";
import { setUser } from "store/user/slice";
import { useAppDispatch } from "store/hooks";
import Header from "components/Header";
import NotValidatedForm from "./NotValidatedForm";
import LoginForm from "./Form";

import styles from "./styles.module.scss";

interface Error {
  path: "email" | "password";
  message: string;
}

interface FormErrors {
  passwordError: string;
  emailError: string;
}

interface GetUserData {
  getUser: RootState["user"];
}

const Login = () => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const [login] = useMutation(LOGIN);
  const [validateUser] = useMutation(VALIDATE);
  const [getUser, { data }] = useLazyQuery<GetUserData>(GET_USER, { fetchPolicy: "network-only" });
  const [state, setState] = useState({
    email: "",
    password: "",
    errors: {
      emailError: "",
      passwordError: "",
    },
  });

  const {
    email,
    password,
    errors: { emailError, passwordError },
  } = state;

  const signupToken = location.search.slice(1);
  const isNotValidated = emailError === "not validated";
  const errorList = [];

  if (emailError) errorList.push(emailError);
  if (passwordError) errorList.push(passwordError);

  if (data?.getUser) {
    dispatch(setUser(data?.getUser));
    history.push("/");
  }

  useEffect(() => {
    validateUser({ variables: { token: signupToken } }).then((res) => {
      const { ok } = res.data.validateRegister;

      if (ok) history.push("/");
    });
  }, [signupToken]);

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    setState({
      ...state,
      [name]: value,
    });
  };

  const onSubmit = async () => {
    const response = await login({
      variables: { email, password },
    });

    const {
      ok, token, refreshToken, errors, subscriptionActive,
    } = response.data.login;

    if (ok && !subscriptionActive) {
      history.push("/inactive-subscription");
    } else if (ok) {
      localStorage.setItem("token", token);
      localStorage.setItem("refreshToken", refreshToken);

      getUser();
    } else {
      const err: { [char: string]: string } & FormErrors = {
        emailError: "",
        passwordError: "",
      };

      errors.forEach(({ path, message }: Error) => {
        err[`${path}Error`] = message;
      });

      setState({
        ...state,
        errors: err,
      });
    }
  };

  return (
    <>
      <img src={background} className={styles.background} alt="LoginBg" />
      <Header marketing />

      <div className={styles.panel}>
        {isNotValidated ? (
          <NotValidatedForm email={email} />
        ) : (
          <LoginForm
            emailError={emailError}
            onChange={onChange}
            email={email}
            passwordError={passwordError}
            password={password}
            onSubmit={onSubmit}
            errorList={errorList}
          />
        )}
      </div>
    </>
  );
};

export default Login;
