import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface State {
  alternate: boolean;
  beatLibraryOpen: boolean;
  countIn: boolean;
  countingIn: boolean;
  loop: boolean;
  openTabs: {
    createAssignmentOpen: boolean;
    createAssignmentShown: boolean;
    saveBeatOpen: boolean;
    saveBeatShown: boolean;
    saveStockBeatOpen: boolean;
    saveStockBeatShown: boolean;
  };
  playing: boolean;
  readOnly: boolean;
  step: number;
}

const initialState: State = {
  alternate: false,
  beatLibraryOpen: false,
  countIn: false,
  countingIn: false,
  loop: true,
  openTabs: {
    createAssignmentOpen: false,
    createAssignmentShown: true,
    saveBeatOpen: false,
    saveBeatShown: true,
    saveStockBeatOpen: false,
    saveStockBeatShown: true,
  },
  playing: false,
  readOnly: false,
  step: 1,
};

export const drumroomSlice = createSlice({
  name: "drumroom",
  initialState,
  reducers: {
    setAlternate: (state, action: PayloadAction<boolean>) => ({ ...state, alternate: action.payload }),
    setBeatLibraryOpen: (state, action: PayloadAction<boolean>) => ({ ...state, beatLibraryOpen: action.payload }),
    setCountIn: (state, action: PayloadAction<boolean>) => ({ ...state, countIn: action.payload }),
    setCountingIn: (state, action: PayloadAction<boolean>) => ({ ...state, countingIn: action.payload }),
    setPlaying: (state, action: PayloadAction<boolean>) => ({ ...state, playing: action.payload }),
    setReadOnly: (state, action: PayloadAction<boolean>) => ({ ...state, readOnly: action.payload }),
    toggleLoop: (state) => ({ ...state, loop: !state.loop }),
    setCreateAssignmentOpen: (state, action: PayloadAction<boolean>) => ({
      ...state,
      openTabs: { ...state.openTabs, createAssignmentOpen: action.payload },
    }),
    setSaveBeatOpen: (state, action: PayloadAction<boolean>) => ({
      ...state,
      openTabs: { ...state.openTabs, saveBeatOpen: action.payload },
    }),
    setSaveStockBeatOpen: (state, action: PayloadAction<boolean>) => ({
      ...state,
      openTabs: { ...state.openTabs, saveStockBeatOpen: action.payload },
    }),
    setCreateAssignmentShown: (state, action: PayloadAction<boolean>) => ({
      ...state,
      openTabs: { ...state.openTabs, createAssignmentShown: action.payload },
    }),
    setSaveBeatShown: (state, action: PayloadAction<boolean>) => ({
      ...state,
      openTabs: { ...state.openTabs, saveBeatShown: action.payload },
    }),
    setSaveStockBeatShown: (state, action: PayloadAction<boolean>) => ({
      ...state,
      openTabs: { ...state.openTabs, saveStockBeatShown: action.payload },
    }),
    setStep: (state, action: PayloadAction<number>) => ({ ...state, step: action.payload }),
  },
});

export const {
  setAlternate,
  setBeatLibraryOpen,
  setCountIn,
  setCountingIn,
  setCreateAssignmentOpen,
  setCreateAssignmentShown,
  setPlaying,
  setReadOnly,
  setSaveBeatOpen,
  setSaveBeatShown,
  setSaveStockBeatOpen,
  setSaveStockBeatShown,
  setStep,
  toggleLoop,
} = drumroomSlice.actions;

export default drumroomSlice.reducer;
