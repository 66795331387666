import { beatType } from "types/beat";
import { folderType } from "types/folder";
import { studentType } from "types/student";

export const searchBeatsFolders = (search: string, beatsToRender: beatType[], foldersToRender: folderType[]) => {
  if (search !== "") {
    beatsToRender = beatsToRender?.filter((beat: beatType) => {
      if (beat?.name?.toLowerCase()?.includes(search.toLowerCase())) {
        return true;
      }

      return false;
    });

    foldersToRender = foldersToRender.filter((folder: folderType) => {
      const containsSearchedBeat = beatsToRender
        .map((beat: beatType) => {
          if (beat.folderId === folder.id) {
            return 1;
          }

          return 0;
        })
        .reduce((a: number, b: number) => a + b, 0);

      if (folder.name.toLowerCase().includes(search.toLowerCase()) || containsSearchedBeat) {
        return true;
      }

      return false;
    });
  }

  return [beatsToRender, foldersToRender];
};

export const searchStudents = (search: string, students: studentType[]) => {
  if (search !== "") {
    students = students.filter((student: studentType) => {
      const studentName = `${student.firstName} ${student.lastName}`;
      if (studentName.toLowerCase().includes(search.toLowerCase())) {
        return true;
      }

      return false;
    });
  }

  return students;
};
