/* eslint-disable max-len */

import { FullProps } from "./baseProps";

const HiHat = ({
  colored, index, onClick, opacity, note,
}: FullProps) => {
  const fill = colored ? "#2fa839" : "#00000";

  return (
    <g>
      {note.includes("a") && (
      <g stroke="null" id="layer1" transform={`translate(${30 * index}, 0)`} fill={fill}>
        <path
          fill="#000000"
          id={`accent_${index}`}
          d="m72.2258,15l-7.80645,3.28693l0,-1.47912l6.16299,-2.54737l-6.16299,-2.54737l0,-1.47912l7.80645,3.28693l0,1.47912z"
        />
      </g>
      )}

      {note.includes("n") && (
      <path
        transform={`translate(${30 * index}, 0)`}
        onClick={onClick}
        id={`hat_${index}`}
        fill={fill}
        fillOpacity={opacity}
        d="m61.91146,45.075c0.12,-0.03 0.24,-0.03 0.36,0.03c0.03,0.03 0.93,0.72 1.95,1.56l1.86,1.5l1.86,-1.5c1.02,-0.84 1.92,-1.53 1.95,-1.56c0.21,-0.12 0.33,-0.09 0.75,0.24c0.3,0.27 0.36,0.36 0.36,0.54c0,0.03 -0.03,0.12 -0.06,0.18c-0.03,0.06 -0.9,0.75 -1.89,1.56l-1.8,1.47c0,0.03 0.81,0.69 1.8,1.5c0.99,0.81 1.86,1.5 1.89,1.56c0.03,0.06 0.06,0.15 0.06,0.18c0,0.18 -0.06,0.27 -0.36,0.54c-0.42,0.33 -0.54,0.36 -0.75,0.24c-0.03,-0.03 -0.93,-0.72 -1.95,-1.56l-1.86,-1.5l-1.86,1.5c-1.02,0.84 -1.92,1.53 -1.95,1.56c-0.21,0.12 -0.33,0.09 -0.75,-0.24c-0.3,-0.27 -0.36,-0.36 -0.36,-0.54c0,-0.03 0.03,-0.12 0.06,-0.18c0.03,-0.06 0.9,-0.75 1.89,-1.56l1.8,-1.47c0,-0.03 -0.81,-0.69 -1.8,-1.5c-0.99,-0.81 -1.86,-1.5 -1.89,-1.56c-0.06,-0.12 -0.09,-0.21 -0.03,-0.36c0.03,-0.09 0.57,-0.57 0.72,-0.63zm9.063,-23.075l0,26.35l-0.6,0l0,-26.35l0.6,0z"
      />
      )}
      {note.includes("o") && (
      <g stroke="null" id="layer1" transform={`translate(${30 * index - 30}, -9)`} fill={fill}>
        <text
          fill={fill}
          xmlSpace="preserve"
          x="232.71184"
          y="85.69479"
          id="text3037"
          fontFamily="Linux Libertine O"
          fontWeight="bold"
          fontStyle="normal"
          fontSize="72px"
          transform="matrix(0.07720145959027297,0,0,0.07720145959027297,92.70247502509862,7.247037891156367) "
        />
        <text
          fill={fill}
          xmlSpace="preserve"
          x="68.65921"
          y="145.53121"
          id="text3097"
          fontFamily="Linux Libertine O"
          fontWeight="bold"
          fontStyle="normal"
          fontSize="72px"
          transform="matrix(0.07720145959027297,0,0,0.07720145959027297,92.70247502509862,7.247037891156367) "
        >
          <tspan fill={fill} id="tspan3099" x="73.65921" y="145.53121">
            O
          </tspan>
        </text>
      </g>
      )}
    </g>
  );
};

export default HiHat;
