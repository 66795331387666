import gql from "graphql-tag";

const ALL_STRUCTURED_FOR_TEACHER = gql`
  query($teacherId: Int!) {
    allStudentsBeatsAssignments(teacherId: $teacherId) {
      ok
      students {
        firstName
        lastName
        studentId
        beats {
          id
          name
          folderId
          shared
        }
        assignments {
          id
          content
          beats {
            id
            name
            folderId
            shared
          }
          title
          createdAt
        }
      }
    }
  }
`;

export default ALL_STRUCTURED_FOR_TEACHER;
