import gql from "graphql-tag";

const SHARE = gql`
  mutation($beatId: Int!) {
    shareBeat(beatId: $beatId) {
      ok
    }
  }
`;

export default SHARE;
