import { MouseEvent, memo, useState } from "react";
import { useAppSelector, useAppDispatch } from "store/hooks";
import { setActiveMeasure } from "store/beat/slice";
import { Icon } from "semantic-ui-react";
import triggerResize from "utils/triggerResize";
import { getMeasureByIndex, getActiveMeasureIndex } from "store/beat/selectors";
import NoteGrid from "screens/Main/NoteGrid";

import styles from "./styles.module.scss";

interface Props {
  availableSheetWidth: number;
  measureIndex: number;
  onDeleteMeasure: (measureIndex: number) => void;
  onDuplicateMeasure: (measureIndex: number) => void;
  readOnly: boolean;
}

const MeasureWrapper = ({
  availableSheetWidth,
  measureIndex,
  onDeleteMeasure,
  onDuplicateMeasure,
  readOnly,
}: Props) => {
  const dispatch = useAppDispatch();
  const measure = useAppSelector(getMeasureByIndex(measureIndex));
  const activeMeasureIndex = useAppSelector(getActiveMeasureIndex);
  const isActive = activeMeasureIndex === measureIndex;
  const [isHovered, setIsHovered] = useState(false);

  const handleClick = () => {
    if (activeMeasureIndex !== measureIndex) dispatch(setActiveMeasure(measureIndex));
  };

  return (
    <div
      className={`${styles.measureWrapper} ${isActive ? styles.highlighted : ""} measure-wrapper`}
      onMouseOver={() => setIsHovered(true)}
      onFocus={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div className={`${styles.measureButtons}`}>
        {(isActive || isHovered) && (
          <>
            <Icon
              name="copy outline"
              className={styles.icon}
              disabled={readOnly}
              onClick={(e: MouseEvent) => {
                e.stopPropagation();
                onDuplicateMeasure(measureIndex);
                triggerResize();
              }}
            />
            <Icon
              name="x"
              className={styles.icon}
              disabled={readOnly}
              onClick={(e: MouseEvent) => {
                e.stopPropagation();
                onDeleteMeasure(measureIndex);
                triggerResize();
              }}
            />
          </>
        )}
      </div>
      {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
      <div className={`${styles.noteGridContainer} note-grid-container`} onMouseDown={handleClick}>
        <NoteGrid
          availableSheetWidth={availableSheetWidth}
          isActive={isActive}
          measure={measure}
          measureIndex={measureIndex}
          readOnly={readOnly}
        />
      </div>
    </div>
  );
};

export default memo(MeasureWrapper);
