import { useState } from "react";
import Slider from "react-input-slider";
import { useAppSelector, useAppDispatch } from "store/hooks";
import { setSwing } from "store/beat/slice";
import { setPlaying } from "store/drumroom/slice";
import BpmPicker from "./BpmPicker";

import styles from "./styles.module.scss";

const PlaybackControls = () => {
  const swing = useAppSelector((state) => state.beat.present.swing);
  const playing = useAppSelector((state) => state.drumroom.playing);
  const [shown, setShown] = useState(false);
  const [swingInput, setInput] = useState(swing.toString());
  const dispatch = useAppDispatch();

  const handleInput = (e: any) => {
    const re = /^[0-9\b]+$/;
    if (e.target.value === "" || re.test(e.target.value)) {
      let swingValue = parseInt(e.target.value, 10);
      if (swingValue > 100) {
        swingValue = 100;
      } else if (!swingValue) {
        swingValue = 0;
      }
      setInput(swingValue.toString());
    }
  };

  const handleSwingInput = () => {
    // this will round input down to nearest 10, then convert scale from 0-100 to 0-1
    dispatch(setSwing(Math.floor(parseInt(swingInput, 10) / 10) / 10));
    setShown(false);
  };

  document.body.onkeyup = (e: any) => {
    // if event target contains document.body then toggle playback.
    // target contains the source element textArea, input etc.
    // this prevents playback from being started when typing.

    // will be false if space hit when beat library open
    // also had to extract source of the space bar clicks within body target to prevent playback within beat library.
    // this seems like a real hacky way to do it, but it currently works.

    if (e.keyCode === 32 && e.target === document.body && e.target.innerText.substring(0, 4) !== "Beat") {
      dispatch(setPlaying(!playing));
    }
  };

  return (
    <div className={styles.container}>
      <p className={styles.playbackSwingLabel}>Swing</p>
      {shown && (
        <input
          className={styles.input}
          // eslint-disable-next-line
          autoFocus
          placeholder={(swing * 100).toString()}
          max="100"
          min="0"
          value={swingInput}
          onChange={handleInput}
          onBlur={handleSwingInput}
        />
      )}

      <button type="button" className={styles.playbackSwingNumber} onClick={() => setShown(!shown)}>
        {Math.floor(swing * 100)}
        %
      </button>

      <Slider
        axis="x"
        x={swing}
        xstep={0.1}
        xmin={0}
        xmax={1}
        onChange={({ x }) => {
          dispatch(setSwing(Math.floor(x * 10) / 10));
          setInput((Math.floor(x * 10) * 10).toString());
        }}
        styles={{
          track: {
            width: 80,
          },
        }}
      />
      <BpmPicker />
    </div>
  );
};

export default PlaybackControls;
