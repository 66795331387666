import gql from "graphql-tag";

const REMOVE_SHARE = gql`
  mutation($beatId: Int!) {
    removeSharedBeat(beatId: $beatId) {
      ok
      beat {
        id
        shared
      }
    }
  }
`;

export default REMOVE_SHARE;
