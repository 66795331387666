import { combineReducers, Action, CombinedState } from "redux";
import undoable from "redux-undo";
import beatReducer from "./beat/slice";
import userReducer from "./user/slice";
import folderReducer from "./folder/slice";
import drumroomReducer from "./drumroom/slice";
import assignmentReducer from "./assignment/slice";
import alertReducer from "./alert/slice";

const appReducer = combineReducers({
  alert: alertReducer,
  assignment: assignmentReducer,
  beat: undoable(beatReducer),
  drumroom: drumroomReducer,
  folder: folderReducer,
  user: userReducer,
});

const rootReducer = (state: CombinedState<ReturnType<typeof appReducer>> | undefined, action: Action) => {
  if (action.type === "USER_LOGOUT") {
    state = undefined;
  }

  return appReducer(state, action);
};

export default rootReducer;
