import {
  DropTarget, DropTargetConnector, DropTargetMonitor, ConnectDropTarget,
} from "react-dnd";

function collect(connect: DropTargetConnector, monitor: DropTargetMonitor) {
  return {
    connectDropTarget: connect.dropTarget(),
    hovered: monitor.isOver(),
    beat: monitor.getItem(),
    folder: monitor.getItem(),
    dropped: monitor.didDrop(),
    isOver: monitor.isOver(),
  };
}

interface Props {
  active: boolean,
  connectDropTarget: ConnectDropTarget,
  beat: any,
  hovered: boolean,
  numberOfBeats: number,
}

const Target = ({
  active, beat, connectDropTarget, hovered, numberOfBeats,
}: Props) => {
  const borderHeight = active && hovered ? 40 + numberOfBeats * 30 : 34;
  const borderStyle = hovered ? "3px solid grey" : "";

  let beatHoveredStyle = {};
  // eslint-disable-next-line
  if (beat && beat.__typename === "Beat") {
    beatHoveredStyle = {
      zIndex: 10,
      height: `${borderHeight}px`,
      width: "298px",
      borderRadius: "4px",
      opacity: 1,
      position: "absolute",
      border: borderStyle,
    };
  }

  return connectDropTarget(
    <div>
      <div style={beatHoveredStyle} />
    </div>,
  );
};

export default DropTarget(
  "beat",
  {
    drop() {
      // pushes folder that it is dropped into back out to endDrop() method of DragSource (Beat component)
      // You can also do nothing and return a drop result,
      // which will be available as monitor.getDropResult()
      // in the drag source's endDrag() method
      return {
        target: "FOLDER",
        payload: null,
      };
    },
  },

  collect,
)(Target);
