import { useEffect, useState, useReducer } from "react";
import { useQuery, useMutation } from "@apollo/client";
import {
  Table, Input, Icon, Confirm,
} from "semantic-ui-react";
import _ from "lodash";
import ALL_FOR_TEACHER from "graphql/student/queries/allForTeacher";
import ALL_STUDENTS_STRUCTURED_FOR_TEACHER from "graphql/student/queries/allStructuredForTeacher";
import REMOVE_STUDENT from "graphql/student/mutations/remove";

import styles from "./styles.module.scss";

interface studentType {
  email: string;
  studentId: number;
  userId: number;
  signupEmail: string;
  firstName: string;
  lastName: string;
}

interface Props {
  teacherId: number;
}

const exampleReducer = (state: any, action: any) => {
  switch (action.type) {
    case "CHANGE_SORT":
      if (state.column === action.column) {
        return {
          ...state,
          data: state.data.reverse(),
          direction: state.direction === "ascending" ? "descending" : "ascending",
        };
      }

      return {
        column: action.column,
        data: _.sortBy(state.data, [action.column]),
        direction: "ascending",
      };
    case "SET_DATA":
      return {
        ...state,
        data: action.payload,
      };
    default:
      throw new Error();
  }
};

const StudentsTable = ({ teacherId }: Props) => {
  const { data: tableData } = useQuery(ALL_FOR_TEACHER, {
    fetchPolicy: "network-only",
    variables: { teacherId },
  });

  const [removeStudent] = useMutation(REMOVE_STUDENT);
  const [open, setOpen] = useState(false);
  const [studentToRemove, setStudentToRemove] = useState(-1);

  const handleRemove = (studentId: number) => {
    removeStudent({
      variables: {
        studentId,
      },
      update: (client, { data: { removeStudent: removeStudentResponse } }) => {
        const { ok, student } = removeStudentResponse;

        if (!ok) {
          return;
        }

        const data: any = client.readQuery({
          query: ALL_FOR_TEACHER,
          variables: {
            teacherId,
          },
        });

        let filteredData = null;
        if (data?.allStudents?.students) {
          filteredData = data.allStudents.students.filter((s: studentType) => s.studentId !== student.id);
        }

        client.writeQuery({
          query: ALL_FOR_TEACHER,
          variables: {
            teacherId,
          },
          data: {
            allStudents: {
              ok: true,
              __typename: "allStudentsResponse",
              students: filteredData,
            },
          },
        });

        const structuredStudents: any = _.cloneDeep(
          client.readQuery({
            query: ALL_STUDENTS_STRUCTURED_FOR_TEACHER,
            variables: { teacherId },
          }),
        );

        if (structuredStudents) {
          const newStructuredStudents = structuredStudents.allStudentsBeatsAssignments.students.filter(
            (s: studentType) => s.studentId !== student.id,
          );

          client.writeQuery({
            query: ALL_STUDENTS_STRUCTURED_FOR_TEACHER,
            variables: { teacherId },
            data: { allStudentsBeatsAssignments: { ok: true, students: newStructuredStudents } },
          });
        }
      },
    });
    setOpen(false);
  };

  const [state, dispatch] = useReducer(exampleReducer, {
    column: null,
    data: null,
    direction: null,
  });

  useEffect(() => {
    if (tableData?.allStudents?.students) {
      dispatch({ type: "SET_DATA", payload: tableData.allStudents.students });
    }
  }, [tableData]);

  const [active, setActive] = useState([-1]);
  const { column, data, direction } = state;

  const editStudent = (e: any, student: studentType) => {
    setActive([student.studentId]);
  };

  return (
    <div className={styles.container}>
      <h1>My Students</h1>
      <Table celled selectable sortable>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell width={1}>
              <Input type="checkbox" />
            </Table.HeaderCell>
            <Table.HeaderCell
              sorted={column === "first" ? direction : null}
              onClick={() => dispatch({ type: "CHANGE_SORT", column: "first" })}
            >
              First Name
            </Table.HeaderCell>
            <Table.HeaderCell
              sorted={column === "last" ? direction : null}
              onClick={() => dispatch({ type: "CHANGE_SORT", column: "last" })}
            >
              Last Name
            </Table.HeaderCell>
            <Table.HeaderCell
              sorted={column === "email" ? direction : null}
              onClick={() => dispatch({ type: "CHANGE_SORT", column: "email" })}
            >
              Email
            </Table.HeaderCell>
            <Table.HeaderCell
              sorted={column === "connected" ? direction : null}
              onClick={() => dispatch({ type: "CHANGE_SORT", column: "connected" })}
            >
              Connected
            </Table.HeaderCell>
            <Table.HeaderCell width={1}>Remove</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {data?.map((student: studentType) => (
            <Table.Row
              key={student.studentId}
              onClick={(e: any) => editStudent(e, student)}
              active={active.includes(student.studentId)}
            >
              <Table.Cell>
                <Input type="checkbox" checked={active.includes(student.studentId)} disabled />
              </Table.Cell>
              <Table.Cell>{student.firstName}</Table.Cell>
              <Table.Cell>{student.lastName}</Table.Cell>
              <Table.Cell>
                <a href={`mailto: ${student.userId ? student.email : student.signupEmail}`}>
                  {student.userId ? student.email : student.signupEmail}
                </a>
              </Table.Cell>
              <Table.Cell>{student.userId ? "Yes" : "No"}</Table.Cell>
              <Table.Cell
                onClick={() => {
                  setOpen(true);
                  setStudentToRemove(student.studentId);
                }}
              >
                <Icon name="remove user" />
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
        <Confirm
          open={open}
          content={(
            <div style={{ padding: "15px" }}>
              <p>
                Are you sure you want to remove this Student? Their data will remain, but they will no longer be linked
                to your Teacher account.
                <br />
              </p>
            </div>
          )}
          confirmButton="Confirm"
          onCancel={() => setOpen(false)}
          onConfirm={() => handleRemove(studentToRemove)}
          style={{ width: "700px" }}
        />
      </Table>
    </div>
  );
};

export default StudentsTable;
