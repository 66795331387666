import gql from "graphql-tag";

const UPDATE_FOLDER = gql`
  mutation($id: Int!, $folderId: Int, $name: String!) {
    updateBeatFolder(id: $id, name: $name, folderId: $folderId) {
      ok
      beat {
        id
        folderId
        name
      }
      errors {
        path
        message
      }
    }
  }
`;

export default UPDATE_FOLDER;
