import { Button, Divider } from "semantic-ui-react";
import { useHistory } from "react-router-dom";
import { Box } from "@material-ui/core";
import useClearAppState from "hooks/useClearAppState";
import Header from "components/Header";
import background from "images/loginBg.png";
import styles from "../styles.module.scss";

const InactiveSubscription = () => {
  const history = useHistory();
  const clearAppState = useClearAppState();

  const handleLogout = () => {
    clearAppState();

    history.push("/login");
  };

  return (
    <>
      <img src={background} className={styles.background} alt="LoginBg" />
      <Header marketing />
      <div className={styles.panel}>
        <Box p={4}>
          <Box pb={2}>
            We&apos;re sorry, your account is not associated with an active subscription.
          </Box>
          <Box pb={2}>
            For teachers, please review your subscription status in Stripe. Students, please reach out to your teacher
            to assist you.
          </Box>
          <Box pb={2}>
            <Button onClick={handleLogout}>Return to Login</Button>
          </Box>
          <Box pb={2}>
            <Divider />
          </Box>

          For any further issues logging in, please contact
          {" "}
          <a href={`mailto: ${process.env.REACT_APP_SUPPORT_EMAIL}`}>{process.env.REACT_APP_SUPPORT_EMAIL}</a>
          {" "}
          and we will be happy to assist you.
        </Box>
      </div>
    </>
  );
};

export default InactiveSubscription;
