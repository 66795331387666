import { EIGHTH_BAR_X, EIGHTH_BAR_Y, SIXTEENTH_BAR_Y } from ".";

interface Props {
  n: number;
  noteDecorationFill: string[];
  opacity: number[];
}

const EAndA = ({
  n, noteDecorationFill, opacity,
}: Props) => (
  <>
    <path
      transform={`translate(${30 * n + 30}, 0)`}
      d={`m${EIGHTH_BAR_X}, ${EIGHTH_BAR_Y}l30.5,0l0,3.875l-30.5,0l0,-3.875z`}
      fill={noteDecorationFill[n + 1]}
      fillOpacity={opacity[n + 2]}
    />
    <path
      transform={`translate(${30 * n + 100.4}, 0)`}
      d={`m0, ${SIXTEENTH_BAR_Y}l4.70412,0l0,3.875l-4.70412,0l0,-3.875z`}
      fill={noteDecorationFill[n + 1]}
      fillOpacity={opacity[n + 1]}
    />
  </>
);

export default EAndA;
