import { useState, useEffect } from "react";
import {
  Form, Dropdown, Button, TextArea, Message,
} from "semantic-ui-react";
import { useQuery, useMutation } from "@apollo/client";
import { useAppSelector, useAppDispatch } from "store/hooks";
import _ from "lodash";
import ALL_STUDENTS_STRUCTURED_FOR_TEACHER from "graphql/student/queries/allStructuredForTeacher";
import ALL_STUDENTS_FOR_TEACHER from "graphql/student/queries/allForTeacher";
import ALL_BEATS from "graphql/beat/queries/allBeats";
import CREATE_ASSIGNMENT from "graphql/assignment/mutations/create";

import { setBeats } from "store/assignment/slice";
import { sendAlert } from "store/alert/slice";
import CREATE_NOTIFICATION from "graphql/notification/mutations/create";
import Target from "screens/Main/Tabs/CreateAssignment/Target";
import styles from "./styles.module.scss";

interface studentType {
  email: string;
  studentId: number;
  userId: number;
  firstName: string;
  lastName: string;
}

interface beatType {
  beat: [[boolean], [boolean], [boolean], [boolean], [boolean]];
  name: string;
  bpm: number;
  folderId: number;
  counting: boolean;
  metronome: boolean;
  subdivision: boolean;
  sticking: boolean;
  color: boolean;
  grid: boolean;
  id: number;
}

interface formStateType {
  assignment: string;
  studentId: string | number;
  studentError: string | undefined;
}

const CreateAssignment = () => {
  const dispatch = useAppDispatch();
  const {
    typeId: teacherId, id: userId, firstName, lastName,
  } = useAppSelector((store) => store.user);
  const beatIds = useAppSelector((store) => store.assignment.beatIds);

  const { loading: loadingStudents, data: dataStudents } = useQuery(ALL_STUDENTS_FOR_TEACHER, {
    fetchPolicy: "network-only",
    variables: { teacherId, linked: true },
  });
  const { loading: loadingBeats, data: dataBeats } = useQuery(ALL_BEATS, {
    fetchPolicy: "cache-first",
    variables: { includeStock: true },
  });
  const [createNotification] = useMutation(CREATE_NOTIFICATION);

  const [state, setState] = useState<formStateType>({
    assignment: "",
    studentId: "",
    studentError: undefined,
  });

  useEffect(() => () => {
    dispatch(setBeats([]));
  }, []);

  const [createAssignment] = useMutation(CREATE_ASSIGNMENT);

  const setStateFromEvent = (e: any, data: any) => {
    if (data.name === "beatIds") {
      dispatch(setBeats(data.value));
    }

    setState({
      ...state,
      [data.name]: data.value,
    });
  };

  const dropdownStudents: {
    key: number;
    value: number;
    text: string;
  }[] = [];

  const dropdownBeats: {
    key: number;
    value: number;
    text: string;
  }[] = [];

  const onCreate = async () => {
    if (state.studentId === "") {
      setState({
        ...state,
        studentError: "Please select a student.",
      });
      return;
    }
    const response = await createAssignment({
      variables: {
        assignment: state.assignment,
        studentId: state.studentId,
        teacherId,
        beatIds,
      },
      update: async (store, { data: { createAssignment: createAssignmentResponse } }) => {
        const { ok, assignment } = createAssignmentResponse;

        if (!ok) {
          return;
        }

        const data: any = _.cloneDeep(
          await store.readQuery({
            query: ALL_STUDENTS_STRUCTURED_FOR_TEACHER,
            variables: {
              teacherId,
              linked: true,
            },
          }),
        );

        // should really add a type for the allStudentsBeatsAssignments object
        const studentIndex = data.allStudentsBeatsAssignments.students.findIndex(
          (student: studentType) => student.studentId === state.studentId,
        );
        data.allStudentsBeatsAssignments.students[studentIndex].assignments.unshift(assignment);

        store.writeQuery({
          query: ALL_STUDENTS_STRUCTURED_FOR_TEACHER,
          variables: {
            teacherId,
            linked: true,
          },
          data,
        });
      },
    });

    if (response?.data?.createAssignment?.assignment?.id) {
      createNotification({
        variables: {
          messageType: "NEW_ASSIGNMENT",
          type: "student", // indicates the type of target account
          senderName: `${firstName} ${lastName}`,
          senderId: userId,
          targetId: state.studentId, // not necessary for students sending to teacher
          payloadType: "Assignment",
          payloadName: "Assignment",
          payloadId: response.data.createAssignment.assignment.id,
        },
      });
    }

    dispatch(setBeats([]));
    setState({
      assignment: "",
      studentId: "",
      studentError: undefined,
    });

    dispatch(sendAlert({
      message: "Assignment sent to your student successfully.",
      duration: 10000,
      open: true,
    }));
  };

  if (dataStudents && dataStudents.allStudents && dataStudents.allStudents.students) {
    dataStudents.allStudents.students.forEach((student: studentType, index: number) => {
      dropdownStudents[index] = {
        key: index,
        value: student.studentId,
        text: `${student.firstName} ${student.lastName}`,
      };
    });
  }

  if (dataBeats && dataBeats.allBeats) {
    dataBeats.allBeats.forEach((beat: beatType, index: number) => {
      dropdownBeats[index] = {
        key: index,
        value: beat.id,
        text: beat.name,
      };
    });
  }

  return (
    <div>
      <Target />
      <Form className={styles.container}>
        <div>
          <Form.Field>
            {loadingStudents && (
              <Dropdown placeholder="Select Student" search selection clearable />
            )}
            {dataStudents && (
              <Dropdown
                placeholder="Select Student"
                search
                selection
                clearable
                error={!!state.studentError}
                options={dropdownStudents}
                name="studentId"
                onChange={setStateFromEvent}
                value={state.studentId}
              />
            )}
          </Form.Field>

          <Form.Field>
            {loadingBeats && (
              <Dropdown
                placeholder="Select or Drag Beat(s) Here"
                search
                selection
                multiple
                clearable
              />
            )}

            {dataBeats && (
              <Dropdown
                placeholder="Select or Drag Beat(s) Here"
                search
                selection
                multiple
                clearable
                options={dropdownBeats}
                name="beatIds"
                onChange={setStateFromEvent}
                value={beatIds}
              />
            )}
          </Form.Field>

          <Form.Field>
            <TextArea
              placeholder="This week's assignment is..."
              name="assignment"
              value={state.assignment}
              onChange={setStateFromEvent}
            />
          </Form.Field>

          <Form.Field>
            <Button onClick={onCreate}>
              Send
            </Button>
          </Form.Field>
        </div>
      </Form>
      {state.studentError && (
        <div className={styles.error}>
          <Message error size="tiny" list={[state.studentError]} style={{ height: "37px" }} />
        </div>
      )}
    </div>
  );
};

export default CreateAssignment;
