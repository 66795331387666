import { times } from "lodash";
import { useAppSelector, useAppDispatch } from "store/hooks";
import Note from "screens/Main/NoteGrid/Note";
import Measure from "svg/measure";
import EighthRest from "svg/rests/Eighth";
import EighthFlag from "svg/notes/EighthFlag";
import SixteenthFlag from "svg/notes/SixteenthFlag";
import SixteenthRest from "svg/rests/Sixteenth";
import QuarterRest from "svg/rests/Quarter";
import { ReactComponent as PercussionClef } from "svg/percussion-clef.svg";
import { ReactComponent as FourFour } from "svg/four-four.svg";
import isBeatPattern from "utils/isBeatPattern";
import calculateNoteOpacity from "utils/calculateNoteOpacity";
import calculateNoteDecorationFill from "utils/calculateNoteDecorationFill";
import OneEAndA from "svg/bars/OneEAndA";
import OneEAnd from "svg/bars/OneEAnd";
import OneAndA from "svg/bars/OneAndA";
import OneAnd from "svg/bars/OneAnd";
import OneEA from "svg/bars/OneEA";
import OneE from "svg/bars/OneE";
import OneA from "svg/bars/OneA";
import EAndA from "svg/bars/EAndA";
import EAnd from "svg/bars/EAnd";
import EA from "svg/bars/EA";
import AndA from "svg/bars/AndA";
import Dot from "svg/notes/Dot";
import getMeasureScaleFactor from "utils/getMeasureScaleFactor";
import { setActiveNote, Measure as IMeasure } from "store/beat/slice";
import { INSTRUMENT_KEYS } from "utils/constants";

import styles from "./styles.module.scss";

interface Props {
  measure: IMeasure;
  colored: boolean;
  showClefs: boolean;
}

const StaffSvg16 = ({
  measure, colored, showClefs,
}: Props) => {
  const dispatch = useAppDispatch();
  const countingIn = useAppSelector((state) => state.drumroom.countingIn);
  const opacity = calculateNoteOpacity(countingIn, 16, measure.isolate);
  const { noteDecorationFill, topOpacity, topNotes } = calculateNoteDecorationFill(true, measure, colored, opacity);

  const scale = getMeasureScaleFactor();

  const isEmpty = Object.keys(measure).every((key) => measure[key].every((e: string | number) => e === ""));

  const getFlipped = (instrumentIndex: number, noteIndex: number): boolean => {
    switch (instrumentIndex) {
      case 3:
        return !!measure[INSTRUMENT_KEYS[instrumentIndex - 1]][noteIndex];
      case 4:
        return (
          !!measure[INSTRUMENT_KEYS[instrumentIndex - 1]][noteIndex]
          && !measure[INSTRUMENT_KEYS[instrumentIndex - 2]][noteIndex]
        );
      default:
        return false;
    }
  };

  return (
    <div className={styles.staffSvg16Container}>
      <svg
        height={scale * 85}
        viewBox={`0 0 ${(showClefs ? 540 : 480)} 85`}
        width={scale * (showClefs ? 540 : 480)}
        xmlns="http://www.w3.org/2000/svg"
      >
        <Measure showClefs={showClefs} subdivision />
        {showClefs && <PercussionClef />}
        {showClefs && <FourFour />}

        <g transform={`translate(${showClefs ? 10 : -50}, 0)`}>
          {/* eslint-disable react/jsx-key */}
          {INSTRUMENT_KEYS.flatMap(
            (instrumentType, instrumentIndex) => measure[instrumentType].map((note, index) => note && (
              <Note
                flipped={getFlipped(instrumentIndex, index)}
                note={note}
                instrumentType={instrumentType}
                colored={colored}
                index={index}
                onClick={
                  () => dispatch(
                    setActiveNote({
                      index,
                      inst: INSTRUMENT_KEYS.length - instrumentIndex - 1,
                    }),
                  )
                }
                opacity={opacity[index]}
              />
            )),
          )}
          {/* eslint-enable react/jsx-key */}

          {/* Render bars and note decorations */}
          {times(4, (n) => {
            n *= 4;

            return (
              <>
                {
                  isBeatPattern("1e+a", n, measure) && (
                    <OneEAndA
                      n={n}
                      noteDecorationFill={noteDecorationFill}
                      opacity={opacity}
                      topOpacity={topOpacity}
                    />
                  )
                }

                {
                  isBeatPattern("1ea", n, measure) && (
                    <OneEA
                      n={n}
                      noteDecorationFill={noteDecorationFill}
                      opacity={opacity}
                      topOpacity={topOpacity}
                    />
                  )
                }

                {
                  isBeatPattern("1+a", n, measure) && (
                    <OneAndA
                      n={n}
                      noteDecorationFill={noteDecorationFill}
                      opacity={opacity}
                      topOpacity={topOpacity}
                    />
                  )
                }

                {
                  isBeatPattern("1e+", n, measure) && (
                    <OneEAnd
                      n={n}
                      noteDecorationFill={noteDecorationFill}
                      opacity={opacity}
                      topOpacity={topOpacity}
                    />
                  )
                }

                {
                  isBeatPattern("e+a", n, measure) && (
                    <>
                      <EAndA
                        n={n}
                        noteDecorationFill={noteDecorationFill}
                        opacity={opacity}
                        topOpacity={topOpacity}
                      />
                      <SixteenthRest opacity={opacity[n]} transform={`translate(${30 * n}, 0)`} />
                    </>
                  )
                }

                {
                  isBeatPattern("+a", n, measure) && (
                    <>
                      <AndA
                        n={n}
                        noteDecorationFill={noteDecorationFill}
                        opacity={opacity}
                      />
                      <EighthRest opacity={opacity[n]} transform={`translate(${30 * n}, 0)`} />
                    </>
                  )
                }

                {
                  isBeatPattern("1e", n, measure) && (
                    <>
                      <Dot
                        fill={noteDecorationFill[n + 1]}
                        opacity={opacity[n + 1]}
                        index={n + 1}
                        topNoteIndex={topNotes[n + 1]}
                      />
                      <OneE
                        n={n}
                        noteDecorationFill={noteDecorationFill}
                        opacity={opacity}
                      />
                    </>
                  )
                }

                {
                  isBeatPattern("e+", n, measure) && (
                    <>
                      <EAnd
                        n={n}
                        noteDecorationFill={noteDecorationFill}
                        opacity={opacity}
                      />
                      <SixteenthRest opacity={opacity[n]} transform={`translate(${30 * n}, 0)`} />
                    </>
                  )
                }

                {
                  isBeatPattern("1a", n, measure) && (
                    <>
                      <OneA
                        n={n}
                        noteDecorationFill={noteDecorationFill}
                        opacity={opacity}
                      />
                      <Dot
                        fill={noteDecorationFill[n]}
                        opacity={opacity[n + 1]}
                        index={n}
                        topNoteIndex={topNotes[n]}
                      />
                    </>
                  )
                }

                {
                  isBeatPattern("ea", n, measure) && (
                    <>
                      <EA
                        n={n}
                        noteDecorationFill={noteDecorationFill}
                        opacity={opacity}
                      />
                      <SixteenthRest opacity={opacity[n]} transform={`translate(${30 * n}, 0)`} />
                    </>
                  )
                }

                {
                  isBeatPattern("1+", n, measure) && (
                    <OneAnd
                      n={n}
                      noteDecorationFill={noteDecorationFill}
                      opacity={opacity}
                      subdivision
                    />
                  )
                }

                {
                  isBeatPattern("a", n, measure) && (
                    <>
                      <SixteenthFlag
                        opacity={opacity[n + 3]}
                        transformX={30 * n + 90.4}
                        transformY={13}
                        fill={noteDecorationFill[n + 3]}
                      />
                      <EighthRest opacity={opacity[n]} transform={`translate(${30 * n}, 0)`} dotted />
                    </>
                  )
                }

                {
                  isBeatPattern("+", n, measure) && (
                    <>
                      <EighthRest opacity={opacity[n]} transform={`translate(${30 * n}, 0)`} />
                      <EighthFlag
                        opacity={opacity[n + 2]}
                        transformX={30 * n + 60.4}
                        transformY={13}
                        fill={noteDecorationFill[n + 2]}
                      />
                    </>
                  )
                }

                {
                  isBeatPattern("e", n, measure) && (
                    <>
                      <EighthFlag
                        opacity={opacity[n + 1]}
                        transformX={30 * n + 30.4}
                        transformY={13}
                        fill={noteDecorationFill[n + 1]}
                      />
                      <Dot
                        fill={noteDecorationFill[n + 1]}
                        opacity={opacity[n + 1]}
                        index={n + 1}
                        topNoteIndex={topNotes[n + 1]}
                      />
                      <SixteenthRest opacity={opacity[n]} transform={`translate(${30 * n}, 0)`} />
                    </>
                  )
                }

                {
                  isBeatPattern("", n, measure) && !isEmpty && (
                    <QuarterRest
                      opacity={opacity[0] || opacity[1]}
                      transform={`translate(${30 * n - 418.34646}, -75.77905)`}
                    />
                  )
                }
              </>
            );
          })}
        </g>
      </svg>
    </div>
  );
};

export default StaffSvg16;
