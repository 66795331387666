import gql from "graphql-tag";

const GET_LINKED_TO_FOLDER = gql`
  query($folderId: Int!) {
    allStudentsLinkedToFolder(folderId: $folderId) {
      ok
      ids
    }
  }
`;

export default GET_LINKED_TO_FOLDER;
