import { useState } from "react";
import { Accordion, Icon } from "semantic-ui-react";
import { useQuery } from "@apollo/client";
import { useAppSelector } from "store/hooks";
import { Anchorme } from "react-anchorme";
import ALL_STUDENTS_STRUCTURED_FOR_TEACHER from "graphql/student/queries/allStructuredForTeacher";
import { searchStudents } from "utils/search";
import { beatType } from "types/beat";
import Beat from "screens/Main/BeatLibrary/Beat";
import Header from "screens/Main/BeatLibrary/Header";
import styles from "./styles.module.scss";

interface studentType {
  userId: number;
  email: string;
  studentId: number;
  signupEmail: string;
  beats: beatArrType;
  assignments: assignmentType[];
  firstName: string;
  lastName: string;
}

interface assignmentType {
  id: number;
  content: string;
  title: string;
  beats: beatArrType;
  createdAt: string;
}

type beatArrType = beatType[];

const generateFolderTitle = (title: string) => (
  <div style={{ position: "relative" }}>
    <div className={styles.folderTitleContainer}>
      <Icon color="blue" name="folder" className={styles.folderTitleIcon} />
      <p className={styles.folderTitleText}>{title}</p>
    </div>
  </div>
);

const MyStudents = () => {
  const teacherId = useAppSelector((store) => store.user.typeId);
  const { loading: loadingStudents, data: dataStudents } = useQuery(ALL_STUDENTS_STRUCTURED_FOR_TEACHER, {
    variables: {
      teacherId,
      linked: true,
    },
  });

  const [shown, setShown] = useState(true);
  const [search, setSearch] = useState("");

  if (loadingStudents) return null;

  // rootPanels is the root structure of semantic ui react accordion. it looks like:
  // - student
  //   - assignments
  //     - assignment
  //       - assigned beats
  //   - shared beats
  //     - shared beats

  let students: studentType[] = [];

  if (dataStudents && dataStudents.allStudentsBeatsAssignments && dataStudents.allStudentsBeatsAssignments.students) {
    students = searchStudents(search, dataStudents.allStudentsBeatsAssignments.students);
  }

  // alphabetize displayed students
  // slice is required because students array is frozen.
  // Interesting it can be filtered fine in searchStudents. filter can op on frozen arrs, sort cannot?
  if (students.length) {
    students = students.slice().sort((a: studentType, b: studentType) => {
      const student1 = a.firstName + a.lastName;
      const student2 = b.firstName + b.lastName;
      if (student1 < student2) return -1;
      if (student1 > student2) return 1;
      return 0;
    });
  }
  const buildBeats = (assignment: any) => assignment.beats.map((beat: any) => (
    <Beat key={beat.name} beat={beat} hideDropdown />
  ));

  const rootPanels = students.map((student: studentType) => {
    const AssignmentPanels = student.assignments.map((assignment) => {
      const AssignmentContent = (
        <div key={assignment.id}>
          <div className="StudentAssignmentsContent">
            <p className="StudentAssignmentsAssignmentsTextP">
              <pre className="StudentAssignmentsAssignmentsText">
                <Anchorme target="_blank" rel="noreferrer noopener">
                  {assignment.content}
                </Anchorme>
              </pre>
            </p>
          </div>
          {buildBeats(assignment)}
        </div>
      );

      const assignmentFolderTitle = generateFolderTitle(new Date(parseInt(assignment.createdAt, 10)).toDateString());

      return {
        key: `assignment-${assignment.id}`,
        title: { content: assignmentFolderTitle },
        content: { content: AssignmentContent },
      };
    });

    const Assignments = (
      <Accordion.Accordion panels={AssignmentPanels} />
    );

    const SharedBeats = student.beats.map((beat) => <Beat beat={beat} key={beat.name} />);
    const assignmentsFolderTitle = generateFolderTitle("Assignments");
    const sharedFolderTitle = generateFolderTitle("Shared With Me");
    const studentFolderTitle = generateFolderTitle(`${student.firstName} ${student.lastName}`);

    const studentPanels = [
      {
        key: `assignments-${student.userId}`,
        title: { content: assignmentsFolderTitle },
        content: { content: Assignments },
      },
      {
        key: `shared-${student.userId}`,
        title: { content: sharedFolderTitle },
        content: { content: SharedBeats },
      },
    ];

    const Content = (
      <div>
        <Accordion.Accordion panels={studentPanels} />
      </div>
    );

    return {
      key: `student-${student.userId}`,
      title: {
        content: (
          <div className={styles.titleContainer}>
            {studentFolderTitle}
          </div>
        ),
      },
      content: { content: Content },
    };
  });

  return (
    <div>
      <Header
        search={search}
        setSearch={setSearch}
        setShown={setShown}
        shown={shown}
        title="My Students"
      />

      {shown && (
        <div>
          <div className={styles.accordion}>
            <Accordion panels={rootPanels} styled />
          </div>
        </div>
      )}
    </div>
  );
};

export default MyStudents;
